import React from "react";
import { connect } from 'react-redux';

import HighChart from '../shared_components/HighChart';
import { trackerNoteSpinner } from '../spinners';
import TrackerNote from '../shared_components/TrackerNote';
import PatientRecentStats from '../shared_components/PatientRecentStats';
import PatientCustomStats from '../shared_components/PatientCustomStats';
import AlertsHistory from '../shared_components/alerts_history/AlertsHistory';
import "./Biomertics.css";

const Biometrics = React.memo(function Biometrics(props) {
  const { currentPatient, trackerNoteData, customStatsSelected, view } = props;
  const aletrsHistoryFlex = (view === 'halfScreen') ? '2' : '1';
  const notesAndStatsFlex = view === 'halfScreen' ? '3' : '2';
  const tnFlex = view === 'halfScreen' ? '5' : '5';
  const statsFlex = view === 'halfScreen' ? '2' : '2';
  const tnHeight = view === "halfScreen" ? "41vh" : "25vh";
  return (
    <div className="biometrics-wrapper" >
      <div className="highchart-component-wrapper">
        <HighChart currentPatient={currentPatient} view={view} />
      </div>
      <div className="alerts-and-t-notes pt">
        {/* <div className="alerts-history-container-pt" style={{  flex: aletrsHistoryFlex}}>
          <AlertsHistory
            currentPatient={currentPatient}
            view="fullScreen"
          />
        </div> */}
        <div className="t-notes-and-stats pt" style={{ flex: notesAndStatsFlex }}>
          <div className="tracker-note-container-pt" style={{ flex: tnFlex, height: tnHeight }}>
            {trackerNoteData.isFetching ? (
              trackerNoteSpinner()
            ) : (
                <TrackerNote
                  currentPatient={currentPatient}
                  view="ptScreen"
                />
              )}
          </div>
          <div className="stats-component-container-pt" style={{ flex: statsFlex }}>
            {!customStatsSelected ? (
              <PatientRecentStats
                currentPatient={currentPatient}
                view="ptScreen"
              />
            ) : (
                <PatientCustomStats view="ptScreen" />
              )}
          </div>
        </div>
      </div>
    </div>
  );
});

const mapStateToProps = state => {
  const { trackerNoteData, customStatsData: { customStatsSelected } } = state;
  return {
    trackerNoteData,
    customStatsSelected
  };
};

export default connect(
  mapStateToProps,
  null
)(Biometrics);

