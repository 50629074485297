import React, { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { baseURL } from "../../config";
import applyCaseMiddleware from 'axios-case-converter';
import { Dropdown as DropdownSearch } from "semantic-ui-react";

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import "./AlertsRow.css";
import { updateClaimedFlag as updateClaimedFlagAlerts } from "../../redux/alerts/actions";
import { setShowNotification, setNotificationMessage } from "../../redux/notifications/actions";


export const reasonOption = [
  {
    text: "Clinical Question",
    value: "clinical_question",
    key: "clinical_question"
  },
  {
    text: "Tech Issue",
    value: "tech_issue",
    key: "tech_issue"
  },
  {
    text: "Review",
    value: "review",
    key: "review"
  },
  {
    text: "Billing",
    value: "billing",
    key: "billing"
  },
  {
    text: "Customer Satisfaction",
    value: "customer_satisfaction",
    key: "customer_satisfaction"
  },
  {
    text: "Language Assistance",
    value: "language_assistance",
    key: "language_assistance"
  }
];

const DelegationModal = React.memo(function DelegationModal(props) {
  const {
    alertId,
    patientId,
    fullName,
    isFor,
    // updateClaimedFlagAlerts,
    // updateClaimedByMembers,
    setShowNotification,
    setNotificationMessage,
    users,
    delegationModalOpen,
    toggleDelegationModal,
    escalationModalOpen,
    toggleEscalationModal,
    userName
  } = props;

  const [assignedToId, setAssignedToId] = useState('');
  const [assignedToName, setAssignedToName] = useState('');
  const [escalatedToId, setEscalatedToId] = useState("");
  const [escalatedToName, setEscalatedToName] = useState("");
  // reason - task type: claimed_alert, claimed_patient, incomplete
  const [escalationReason, setEscalationReason] = useState('clinical_question');

  const handleDelegateSubmit = async () => {
    const client = applyCaseMiddleware(axios.create());
    let response;
    let taskType = isFor === 'alerts' ? 'claimed_alert' : 'claimed_patient'
    let url =
      isFor === "alerts"
        ? `/delegate_patient?alert_id=${alertId}&patient_id=${patientId}&patient_name=${fullName}&assigned_to=${assignedToId}&task_type=${taskType}`
        : `/delegate_patient?patient_id=${patientId}&patient_name=${fullName}&assigned_to=${assignedToId}&task_type=${taskType}`;
    try {
      response = await client({
        method: "POST",
        url,
        baseURL,
        withCredentials: true
      });
      console.log(response)
      if (response.status == 200) {
        // if (isFor === 'alerts') {
        //   updateClaimedFlagAlerts(alertId, 1);
        // } else {
        //   updateClaimedByMembers(patientId, userName);
        // }

        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: `Patient has been assigned to ${assignedToName}`, type: 'success', isFor });
      }
    } catch (err) {
      console.log(err);
      if (response.status == 409) {
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: `Patient has been claimed by ${err.response.data.error.details.claimedBy}`, type: 'danger', isFor });
      } else if (response.status == 401) {
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: 'You are not authorized to delegate tasks', type: 'danger', isFor });
      } else {
        console.log("Patient could not be delegated");
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: "Patient could not be delegated", type: 'danger', isFor });
      }
    }
  }

  const handleEscalateSubmit = async () => {
    const client = applyCaseMiddleware(axios.create());
    let response;
    try {
      response = await client({
        method: "POST",
        url: `/escalate?patient_id=${patientId}&escalated_to=${escalatedToId}&escalation_reason=${escalationReason}`,
        baseURL,
        withCredentials: true
      });
      if (response.status === 200) {
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
          setNotificationMessage({});
        }, 2000);
        setNotificationMessage({
          text: `The issue has been escalated to ${escalatedToName}`,
          type: "success",
          isFor
        });
      }
    } catch (err) {
      console.log(err.response);
      if (err.response.status == 404) {
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
          setNotificationMessage({});
        }, 2000);
        setNotificationMessage({
          text: `Patient has to be claimed first`,
          type: "danger",
          isFor
        });
      } else if (err.response.status == 405) {
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
          setNotificationMessage({});
        }, 2000);
        setNotificationMessage({
          text: "This patient is not claimed by you",
          type: "danger",
          isFor
        });
      } else {
        console.log("The issue can't be escalated");
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
          setNotificationMessage({});
        }, 2000);
        setNotificationMessage({
          text: "The issue can't be escalated",
          type: "danger",
          isFor
        });
      }
    }
  };

  return (
    <>
        <Modal isOpen={delegationModalOpen} toggle={toggleDelegationModal}>
          <ModalHeader toggle={toggleDelegationModal}>
            Delegate Patient
          </ModalHeader>
          <ModalBody>
            <div className="delegation-dropdowns">
              <div className="delegation-dropdown">
                <div>Assign to:</div>
                <DropdownSearch
                  placeholder="Select a WC"
                  search
                  selection
                  options={users}
                  onChange={(e, params) => {
                    setAssignedToId(params.value);
                    let optionsArr = params.options;
                    let foundName = "";
                    optionsArr.forEach(option => {
                      if (option.value === params.value) {
                        foundName = option.text;
                      }
                    });
                    setAssignedToName(foundName);
                  }}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                toggleDelegationModal();
                handleDelegateSubmit()
              }}
              style={{ lineHeight: "1" }}
            >
              Submit
            </Button>
            <Button
              color="secondary"
              onClick={toggleDelegationModal}
              style={{ lineHeight: "1" }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={escalationModalOpen} toggle={toggleEscalationModal}>
          <ModalHeader toggle={toggleEscalationModal}>
            Escalate Issue
          </ModalHeader>
          <ModalBody>
            <div className="delegation-dropdowns">
              <div className="delegation-dropdown">
                <div>Escalate to:</div>
                <DropdownSearch
                  placeholder="Select a WC"
                  search
                  selection
                  options={users}
                  onChange={(e, params) => {
                    setEscalatedToId(params.value);
                    let optionsArr = params.options;
                    let foundName = "";
                    optionsArr.forEach(option => {
                      if (option.value === params.value) {
                        foundName = option.text;
                      }
                    });
                    setEscalatedToName(foundName);
                  }}
                />
              </div>
              <div className="delegation-dropdown">
                <div>Reason:</div>
                <DropdownSearch
                  placeholder="Select a reason"
                  search
                  selection
                  options={reasonOption}
                  onChange={(e, { value }) => setEscalationReason(value)}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                toggleEscalationModal();
                handleEscalateSubmit()
              }}
              style={{ lineHeight: "1" }}
            >
              Submit
            </Button>
            <Button
              color="secondary"
            onClick={toggleEscalationModal}
              style={{ lineHeight: "1" }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
    </>
  );
});

const mapStateToProps = state => {
  const { usersData: { users }, userData: { fullName } } = state;
  return { users, userName: fullName };
};

const mapActionToProps = {
  updateClaimedFlagAlerts,
  setShowNotification,
  setNotificationMessage
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(DelegationModal);
