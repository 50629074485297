import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import classnames from "classnames";
import ScaleLoader from "react-spinners/ScaleLoader";

import { fetchTrackerNote } from "../../redux/tracker_note/actions";
import { fetchChartData, setIsChartHidden } from "../../redux/highchart/actions";
import { fetchAlertsHistory } from "../../redux/alerts_history/actions";
import { fetchPatientCustomStats, setCustomStatsSelected } from '../../redux/patient_custom_stats/actions'
import { setCurrentMetricAlertsPage, setCurrentMetricPatientsPage} from "../../redux/patients_metadata/actions"
import './HighChart.css'

const HighChart = React.memo(function HighChart(props) {
  const { currentPatient, fetchTrackerNote, fetchChartData, charts, metricFromStore, metricFromParent, isFetching, setCustomStatsSelected, view, minTimeFromParent, maxTimeFromParent, trackerNotes, fetchAlertsHistory, setCurrentMetricAlertsPage, setCurrentMetricPatientsPage, isChartHidden, setIsChartHidden } = props;
  const [currentView, setCurrentView] = useState(view)
  const currentMetric = metricFromParent || metricFromStore;
  const showButtons = metricFromParent ? false : true;
  const [series, setSeries ] = useState([]);

  const getHighchartHeight = () => {
    switch (view) {
      case "preview":
        return "35%";
      case "halfScreen":
        return "45%";
      case "fullScreen":
        return "40.5%";
      case "docScreen":
        return "45%";
    }
  }

  const timeNow = Date.now()
  const sixMonth = 15552000000;
  const minTime = timeNow - sixMonth;

  const spinner = () => {
    return (
      <div className="loader-highchart">
        <ScaleLoader
          height={35}
          width={4}
          color={"gray"}
          loading={true}
        />
      </div>
    )
  }

  useEffect(()=> {
    if (view != currentView) {
      setCurrentView(view);
      if (Highcharts && Highcharts['charts'].length > 0){
        Highcharts.charts.forEach(chart => {
          if (chart != undefined) {
            chart.reflow()
          }
        });
      }
    }
    if (
      charts !== {} &&
      currentMetric &&
      charts[currentPatient] &&
      charts[currentPatient][currentMetric]
    ) {
      setSeries(charts[currentPatient][currentMetric]);
      if (series && series.timeseries) {
        let points = series.timeseries;
        let i = points.length - 1;
        if (points[i] && points[i].data) {
          while (points[i].data.length === 0) {
            i--;
          }
          const lastNoteObj = points[i];
          const lastNoteTime = lastNoteObj.data.slice(-1).pop()[0];
          if (!trackerNotes[currentPatient] ) {
            props.fetchTrackerNote(currentPatient, lastNoteTime);
          }
        }
      }
    }
  },
  [charts, currentMetric, series, currentPatient, view]
  )

  if (!series) return null;

    const options = {
      title: {
        text: ""
      },
      chart: {
        type: "scatter",
        zoomType: "x",
        height: getHighchartHeight(),
        spacingBottom: 5,
        spacingLeft: 2
      },
      credits: {
        enabled: false
      },
      xAxis: {
        events: {
          setExtremes: function (e) {
            if (typeof e.min == 'undefined' && typeof e.max == 'undefined') {
              setCustomStatsSelected(false)
            } else {
              props.fetchPatientCustomStats(currentPatient, currentMetric, e.min, e.max);
              setCustomStatsSelected(true)
            }
          },
        },
        type: "datetime",
        plotBands: series.bands,
        min: minTimeFromParent || minTime,
        max: maxTimeFromParent || timeNow,
        scrollbar: {
          enabled: true,
          height: 8
        },
        labels:{
          style: {
            color: "#525151",
            font: "9px Helvetica",
            fontWeight: "bold",
          }
        }
      },
      yAxis: [
        {
          labels: {
            spacing: [2, 2, 2, 2],
            style: {
              color: "#525151",
              font: "9px Helvetica",
              fontWeight: "bold",
            }
          },
          title: {
            text: null
          }
        }
      ],
      series: series.timeseries,
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          states: {
            inactive: {
              opacity: 1
            }
          },
          marker: {
            radius: 2
          },
          point: {
            events: {
              click: function () {
                fetchTrackerNote(currentPatient, this.x)
              }
            }
          }
        }
      },
      tooltip: {
        formatter: function() {
          return `<b>${moment(this.x)
            .format("L")
            .replace(/\b0+/g, "")}</b>
          <br/>
          <br/>${this.series.name}: ${this.y}`;
        },
        shared: false
      }
    };

    const handleButtonClick = (id, metric) => {
      fetchChartData(id, metric);
      setCustomStatsSelected(false);
      fetchAlertsHistory(id, metric)
      if (view == "preview") {
        if (metric == "pulse") { // alerts history includes HR data
          setCurrentMetricAlertsPage("blood_pressure"); // Possibly eliminate pulse as a separate metric for alerts history, keep it separate for highchart
        } else {
          setCurrentMetricAlertsPage(metric)
        }
      } else {
        if (metric == "pulse") {
          setCurrentMetricPatientsPage("blood_pressure");
        } else {
          setCurrentMetricPatientsPage(metric)
        }
      }
    }

    const metricButtons = () => {
      return (
        <div className="highchart-buttons">
          <div className="metrics-buttons">
            <div
              className={classnames({ active: currentMetric === "glucose" }, "metric")}
              onClick={() => { handleButtonClick(currentPatient, "glucose");
              }}
            >
              <span className="metric-button-text">Glucose</span>
            </div>
            <div
              className={classnames({ active: currentMetric === "blood_pressure" }, "metric")}
              onClick={() => handleButtonClick(currentPatient, "blood_pressure")}
            >
              <span className="metric-button-text">BP</span>
            </div>
            <div
              className={classnames({ active: currentMetric === "pulse" }, "metric")}
              onClick={() => handleButtonClick(currentPatient, "pulse")}
            >
              <span className="metric-button-text">Heart Rate</span>
            </div>
              <div
                className={classnames({ active: currentMetric === "weight" }, "metric")}
                onClick={() => handleButtonClick(currentPatient, "weight")}
              >
                <span className="metric-button-text">Weight</span>
              </div>
          </div>
          <div
            className= "metric hide-chart"
            onClick={() => {
              setIsChartHidden(!isChartHidden)
            }}
          >
            <span className="metric-button-text">{isChartHidden ? "Show Graph" : "Hide Graph"}</span>
          </div>
        </div>
      )
    }

    const getChartOrHide = () => {
      if (isFetching) {
        return spinner()
      } if (!isChartHidden) {
        return <HighchartsReact highcharts={Highcharts} options={options} />;
      } else {
        return null;
      }
    }

    return (
      <React.Fragment>
        {showButtons ?  metricButtons() : null}
        <div className="highchart-wrapper">
          {getChartOrHide()}
        </div>
      </React.Fragment>
    );
})

const mapActionToProps = { fetchTrackerNote, fetchChartData, fetchPatientCustomStats, setCustomStatsSelected, fetchAlertsHistory, setCurrentMetricAlertsPage, setCurrentMetricPatientsPage, setIsChartHidden }

const mapStateToProps = state => {
  const { chartData: {charts ,isFetching, currentMetric, isChartHidden}, trackerNoteData: { trackerNotes } } = state;
  return {
    charts,
    isFetching,
    metricFromStore: currentMetric,
    trackerNotes,
    isChartHidden
  };
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(HighChart);
