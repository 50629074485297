export const SET_SHOW_NOTIFICATION = "setShowNotification";
export const SET_NOTIFICATION_MESSAGE = "setNotificationMessage";

export const setShowNotification = bool => dispatch => {
  dispatch({
    type: SET_SHOW_NOTIFICATION,
    payload: bool
  });
};

export const setNotificationMessage = notificationObj => dispatch => {
  dispatch({
    type: SET_NOTIFICATION_MESSAGE,
    payload: notificationObj
  });
};
