import { SET_START_DATE, SET_END_DATE, SET_SHOW_PDF, SET_SOAP_DATE, SET_SELECT_DATA_FEED, SET_SELECT_BP, SET_SELECT_BG, SET_SELECT_PULSE, SET_PAGES_COUNT } from "./actions";
import moment from 'moment';

export default (
  state = {
    startDate: (new Date(moment(new Date()).subtract(30, "days"))).getTime() ,
    endDate: new Date().getTime(),
    showPDF: false,
    showBP: true,
    showBG: true,
    showPulse: true,
    showDataFeed: true,
    soapDate: false,
    pagesCount: 0
  },
  action
) => {
  switch (action.type) {
    case SET_START_DATE:
      return { ...state, startDate: action.payload };
    case SET_END_DATE:
      return { ...state, endDate: action.payload };
    case SET_SHOW_PDF:
      return { ...state, showPDF: action.payload };
    // select specific metrics
    case SET_SELECT_BP:
      return { ...state, showBP: action.payload };
    case SET_SELECT_BG:
      return { ...state, showBG: action.payload };
    case SET_SELECT_PULSE:
      return { ...state, showPulse: action.payload };
    //
    case SET_SELECT_DATA_FEED:
      return { ...state, showDataFeed: action.payload };
    case SET_SOAP_DATE:
      return { ...state, soapDate: action.payload };
    case SET_PAGES_COUNT:
      return { ...state, pagesCount: action.payload };
    default:
      return state;
  }
};
