import React from "react";
import { connect } from "react-redux";
import { TabContent, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTachometerAlt } from "@fortawesome/free-solid-svg-icons";

import { setCurrentPatientAlertsPage, updatePatientsAlertsPage } from "../../redux/patients_metadata/actions";
import { setCustomStatsSelected } from "../../redux/patient_custom_stats/actions";
import PatientBio from "./PatientBio";
import AlertsHistory from "../shared_components/alerts_history/AlertsHistory";
import HighChart from '../shared_components/HighChart';
import TrackerNote from '../shared_components/TrackerNote';
import PatientRecentStats from '../shared_components/PatientRecentStats';
import PatientCustomStats from "../shared_components/PatientCustomStats";
import { trackerNoteSpinner, Spinner } from '../spinners';
import Notification from "../shared_components/Notification";
import './TabContainer.css'


const TabsContainer = React.memo(function TabsContainer(props) {
  const {setCurrentPatientAlertsPage, updatePatientsAlertsPage, currentPatient, openTabs, trackerNoteData, patientBioData, customStatsSelected, setCustomStatsSelected, isChartHidden, showNotification } = props;

  const getUniqueIds = (arr) => {
    var flags = {};
    var newArr = arr.filter(function (entry) {
      if (flags[entry.id]) {
        return false;
      }
      flags[entry.id] = true;
      return true;
    });
    return newArr
  }

  const createTabs = () => {
    const allTabs = getUniqueIds(openTabs).map(tab=>{

      return (
        <NavItem key={tab.id}>
          <NavLink
            className={classnames(
              { active: currentPatient === tab.id },
              { inactive: currentPatient !== tab.id },
              "tab-header"
            )}
            onClick={() => handleSelectTab(tab)}
          >
            <p className="tab-name">
              {tab.name}
            </p>
            <div
              className="close-button"
              onClick={event => {
                event.stopPropagation();
                handleDeleteTab(tab);
              }}
            >
              X
            </div>
          </NavLink>
        </NavItem>
      );
    })
    return <Nav tabs className="preview-tabs">{allTabs}</Nav>;
  }

  const handleSelectTab = tab => {
    setCurrentPatientAlertsPage(tab.id);
    setCustomStatsSelected(false);

  };

  const handleDeleteTab = tabToDelete => {
    const tabToDeleteIndex = openTabs.findIndex(tab => tab.id === tabToDelete.id);

    const updatedTabs = openTabs.filter((_tab, index) => {
      return index !== tabToDeleteIndex;
    });

    const previousTab = openTabs[tabToDeleteIndex - 1] || openTabs[tabToDeleteIndex + 1] || null;

    updatePatientsAlertsPage(updatedTabs);
    if (updatedTabs.length > 0) {
      setCurrentPatientAlertsPage(previousTab.id)
    } else {
      setCurrentPatientAlertsPage(null)
    }
  };

  if (openTabs.length === 0) {
    return (
        <>
        <div className="tabs-container">Select Patient</div>
        <div className="notification-wrapper">
          {showNotification && <Notification />}
        </div>
        </>
    )
  } else {
    return (
      <div className="tabs-container">
        {createTabs()}
        <TabContent activeTab={currentPatient} className="tab-preview">
          {patientBioData.isFetching  ? (
            <Spinner />
          ) : (
            <div tabid={currentPatient}>
              <PatientBio />
              <div className="high-chart-container">
                <HighChart currentPatient={currentPatient} view='preview' />
              </div>
              <div className="extra-data">
                <div className="stats-and-notes-container">
                  <div className="tracker-note-container">
                    {trackerNoteData.isFetching ? (
                      trackerNoteSpinner()
                    ) : (
                      <TrackerNote currentPatient={currentPatient} />
                    )}
                  </div>
                  <div className="stats-component-container">
                    {!customStatsSelected ? (
                      <PatientRecentStats currentPatient={currentPatient} />
                    ) : (
                      <PatientCustomStats />
                    )}
                  </div>
                </div>

                <div className="alerts-history-container" style={{height: isChartHidden ? "64vh": "34vh"}}>
                  <AlertsHistory currentPatient={currentPatient} view="preview"/>
                </div>
              </div>
            </div>
          )}
        </TabContent>
      </div>
    );
  }
});

const mapActionToProps = { setCurrentPatientAlertsPage, updatePatientsAlertsPage, setCustomStatsSelected };

const mapStateToProps = state => {
  const { patientMetadata: { alertsPage: {currentPatient, openTabs}},
  trackerNoteData,
  patientBioData,
  chartData: { isChartHidden },
  alertsHistoryData,
  customStatsData: {customStats, customStatsSelected},
  recentStatsData: {stats},
  notificationsData: {showNotification}} = state;
  return {
    currentPatient,
    openTabs,
    trackerNoteData,
    patientBioData,
    isChartHidden,
    alertsHistoryData,
    customStats,
    customStatsSelected,
    recentStats: stats,
    showNotification
  };
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(TabsContainer);
