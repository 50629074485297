import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import applyCaseMiddleware from 'axios-case-converter';
import { baseURL } from "../../config";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCheckCircle, faCircle } from "@fortawesome/free-solid-svg-icons";
import { faAddressCard } from "@fortawesome/free-regular-svg-icons";
import { ReactComponent as TaskIcon } from "../../images/task_icon_grey.svg";
import { ReactComponent as DownloadIcon } from "../../images/download_icon.svg";
import { Nav, NavItem } from "reactstrap";
import { PopoverBody, Popover } from "reactstrap";
import { useHistory } from "react-router-dom";

import { postEncounterNote, postSoapNote } from '../../redux/tracker_note_input/util';
import { setNoteText, setTnId, setIsTnUpdate, setSoapNoteText, setIsSoapNoteUpdate, setSoapNoteId } from "../../redux/tracker_note_input/actions";
import PDFoptions from '../sign_screen/PDFoptions';
import ContactInfoCard from './ContactInfoCard';
import Notification from '../shared_components/Notification';
import { setShowNotification, setNotificationMessage } from '../../redux/notifications/actions';
import { addPtToCallQRedux } from "../../redux/members/actions";
import { updateClaimedFlag } from "../../redux/alerts/actions"
import { soapTemplate } from './soapTemplate';

import "./PersistentInfoTop.css";


const PersistentInfoTop = React.memo(function PersistentInfoTop(props) {
  const { patient, nextScreen, encounterNoteText, minutes, callStatus, patientId, setEncounterNoteText, setSoapNoteText, tnId, soapId, setTnId, isTnUpdate, setIsTnUpdate, trackerNoteInput, showNotification, notificationMessage, setShowNotification, setNotificationMessage, soapNoteText, setIsSoapNoteUpdate, setSoapNoteId, isSoapNoteUpdate, addPtToCallQRedux, alerts, updateClaimedFlag } = props;
  const history = useHistory();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [pdfPopoverOpen, setPdfPopoverOpen] = useState(false);
  const [iconNameOpen, setIconNameOpen] = useState({ contact: false, downloadPDF: false, saveForLater: false })

  const fullName = patient['firstName'] ? `${patient.firstName} ${patient.lastName}` : '-';

  useEffect(()=> {
      // if tnId info is not fetched yet it means that text is also being fetched for the first time
    if ( ( trackerNoteInput[patientId] && !trackerNoteInput[patientId].hasOwnProperty("isTnUpdate"))) {
      getTnIdToUpdate().then(result => {
          setEncounterNoteText({ patientId, text: result.data.tnText });
          setTnId({ patientId, tnId: result.data.tnId, duration: result.data.duration});
          let updateToPass = result.data.tnId !== "" ? 1 : 0
          setIsTnUpdate({ patientId, isTnUpdate: updateToPass});
        });
      }

    if ((trackerNoteInput[patientId] && !trackerNoteInput[patientId].hasOwnProperty("soapNoteId"))) {
      getSoapNoteIdToUpdate().then(result => {
        if (result.data.soapNoteText != '') {
          setSoapNoteText({ patientId, text: result.data.soapNoteText });
        } else {
          setSoapNoteText({
            patientId,
            text: `${soapTemplate()}\nPCP: ${patient.doctorInfo && patient.doctorInfo.doctorName && patient.doctorInfo.doctorName}\n`
          });
        }
        setSoapNoteId({ patientId, soapNoteId: result.data.soapNoteId });
        let isUpdate = result.data.soapNoteId !== "" ? 1 : 0;
        setIsTnUpdate({ patientId, isSoapNoteUpdate: isUpdate });
      });
    }
  }, [])

  const getTnIdToUpdate = async () => {
    let client = applyCaseMiddleware(axios.create());
    return client({
      method: "GET",
      url: `/tn_to_update`,
      baseURL,
      params: {
        id: patientId
      },
      withCredentials: true,
    });
  }

  const getSoapNoteIdToUpdate = async () => {
    let client = applyCaseMiddleware(axios.create());
    return client({
      method: "GET",
      url: `/soap_note_to_update`,
      baseURL,
      params: {
        id: patientId
      },
      withCredentials: true
    });
  };

  const  rerouteToHome = () => {
    history.push("/");
  }


  const arrowIcon = () => {
    return (
      <FontAwesomeIcon
        icon={faArrowRight}
        style={{
          marginLeft: "10px",
          color: "white",
          fontSize: "14px"
        }}
      />
    )
  }

  const getAlertId = () => {
    for (let alert of alerts) {
      if (alert.patient_id == patientId) {
        return alert._id
      }
    }
    return "";
  }

  const saveTrackerNote = ({ isComplete, isTnUpdate, tnToUpdate, minutes, completeTask }) => {
    if (!callStatus && isComplete){
      setShowNotification(true);
      setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
      setNotificationMessage({ text: 'Select a call log status', type: 'danger', isFor: 'topRightPlan' });
      return;
    } else {
      postEncounterNote({ text: encounterNoteText, minutes, callStatus, patientId, isComplete, isTnUpdate, tnToUpdate, careType: patient.careType, completeTask }).then((response) => {
        if (response.status === 200) {
          setShowNotification(true);
          setTimeout(() => { setShowNotification(false) }, 3000)
          setNotificationMessage({ text: "Your data has been saved!", type: "success", isFor: 'topRightPlan' });
          if (isComplete) {
            let alertId = getAlertId();
            if (alertId) {
              updateClaimedFlag(alertId, 2)
            }
            addPtToCallQRedux({ patientId, isInCallQueue: 0 }) // this could be in else block
            setEncounterNoteText({ patientId, text: "" });
            setTnId({ patientId, tnId: "", duration: "" });
            setIsTnUpdate({ patientId, isTnUpdate: 0 });
          } else {
            getTnIdToUpdate().then(result => {
              setTnId({ patientId, tnId: result.data.tnId, duration: result.data.duration });
              let updateToPass = result.data.tnId !== "" ? 1 : 0
              setIsTnUpdate({ patientId, isTnUpdate: updateToPass });
            });
          }
          setTimeout(() => { rerouteToHome() }, 2000)
        }
      }).catch((err) => {
        console.log(err)
        if (err["response"] && err.response["status"] && err.response.status == 409) {
          setShowNotification(true);
          setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
          setNotificationMessage({ text: 'You have to claim the patient first', type: 'danger', isFor: 'topRightPlan' });
        } else {
          setShowNotification(true);
          setTimeout(() => { setShowNotification(false) }, 2000)
          setNotificationMessage({ text: "Could not be saved at this time", type: "danger", isFor: 'topRightPlan' });
        }
      })
    }

  }

  const saveSoapNote = ({ isComplete, isUpdate, completeTask }) => {
    postSoapNote({ text: soapNoteText, patientId, isComplete, isUpdate, soapId, completeTask }).then((response) => {
      if (response.status === 200) {
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false) }, 3000)
        setNotificationMessage({ text: "Your data has been saved!", type: "success", isFor: 'topRightPlan' });
        if (isComplete) {
          setSoapNoteText({ patientId, text: "" });
          setSoapNoteId({ patientId, tnId: "" });
          setIsSoapNoteUpdate({ patientId, isSoapNoteUpdate: 0 });
        } else {
          getSoapNoteIdToUpdate().then(result => {
            setSoapNoteId({ patientId, tnId: result.data.soapNoteId });
            let isUpdate = result.data.soapNoteId !== "" ? 1 : 0;
            setIsSoapNoteUpdate({ patientId, isSoapNoteUpdate: isUpdate });
          });
        }
        setTimeout(() => { rerouteToHome() }, 2000)
      }
    }).catch((err) => {
      console.log(err)
      if (err.response.status == 409) {
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: 'You have to claim the patient first', type: 'danger', isFor: 'topRightPlan' });
      } else {
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false) }, 2000)
        setNotificationMessage({ text: "Could not be saved at this time", type: "danger", isFor: 'topRightPlan' });
      }
    })
  }

  const nextScreenLink = (next) => {
    if (next === 'document') {
        return(
          <NavLink to="/document">
            <div className="nav-to-patient-button" onClick={() => { }}>
              Document{' '} {arrowIcon()}
              </div>
          </NavLink>
        )
    } else if (next === 'sign') {
        return (
          <NavLink to="/sign">
            <div className="nav-to-patient-button" onClick={() => { }}>
              Sign{' '}{arrowIcon()}
            </div>
          </NavLink>
        )
    } else if (next === 'complete') {
      return (
          <div className="nav-to-patient-button blue"
            onClick={(e) => {
            e.preventDefault();
              if (encounterNoteText.length > 0) {
                saveTrackerNote({ isComplete: 1, isTnUpdate: isTnUpdate, tnToUpdate: tnId, minutes, completeTask: 1 })
              }
              if (
                soapNoteText !=
                `${soapTemplate()}\nPCP: ${patient.doctorInfo &&
                  patient.doctorInfo.doctorName &&
                  patient.doctorInfo.doctorName}\n`
              ) {
                saveSoapNote({
                  isComplete: 1,
                  isUpdate: isSoapNoteUpdate,
                  completeTask: 1
                });
              }
          }}>
            Complete
        </div>
      )
    }
  }

  const breadcrumbs = (next) => {
    if (next === 'document') {
      return (
        <Nav>
          <NavItem>
            <NavLink className="breadcrumb active current" to="/patients">
              <div>Plan</div>
              <div className="breadcrumb-icon-wrapper">
                <FontAwesomeIcon icon={faCircle} style={{ color: "black"}} />
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="breadcrumb inactive" disabled to="#">
              <div>Document</div>
              <div className="breadcrumb-icon-wrapper">
                <FontAwesomeIcon icon={faCircle} style={{ color: "rgba(196, 196, 196, 1)"}} />
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="breadcrumb inactive" disabled to="#">
              <div>Sign</div>
              <div className="breadcrumb-icon-wrapper">
                <FontAwesomeIcon icon={faCircle} style={{ color: "rgba(196, 196, 196, 1)"}} />
              </div>
            </NavLink>
          </NavItem>
        </Nav>
      )
    } else if (next === 'sign') {
      return (
        <Nav>
          <NavItem>
            <NavLink className="breadcrumb active" to="/patients">
            <div>Plan</div>
            <div className="breadcrumb-icon-wrapper">
              <FontAwesomeIcon icon={faCheckCircle} style={{ color: "rgba(37, 149, 242, 1)"}} />
            </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="breadcrumb active current" to="/document">
              <div>Document</div>
              <div className="breadcrumb-icon-wrapper">
                <FontAwesomeIcon icon={faCircle} style={{ color: "black"}} />
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="breadcrumb inactive" disabled to="#">
              <div>Sign</div>
              <div className="breadcrumb-icon-wrapper">
              <FontAwesomeIcon icon={faCircle} style={{ color: "rgba(196, 196, 196, 1)"}} />
              </div>
            </NavLink>
          </NavItem>
        </Nav>
      );
    } else if (next === 'complete') {
      return (
        <Nav>
          <NavItem>
            <NavLink className="breadcrumb  active" to="/patients">
              <div>Plan</div>
              <div className="breadcrumb-icon-wrapper">
                <FontAwesomeIcon icon={faCheckCircle} style={{ color: "rgba(37, 149, 242, 1)"}} />
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="breadcrumb  active" to="/document">
              <div>Document</div>
              <div className="breadcrumb-icon-wrapper">
                <FontAwesomeIcon icon={faCheckCircle} style={{ color: "rgba(37, 149, 242, 1)"}} />
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="breadcrumb  active current" to="/sign">
              <div>Sign</div>
              <div className="breadcrumb-icon-wrapper">
                <FontAwesomeIcon icon={faCircle} style={{ color: "black"}} />
              </div>
            </NavLink>
          </NavItem>
        </Nav>
      );
    }
  }

  const iconName = ({ isOpen, name, target }) => {
    return (
      <Popover
        isOpen={isOpen}
        placement="bottom"
        target={target}
        hideArrow={true}
        popperClassName="icon-name"
      >
        <PopoverBody className="tab-name-popover">{name}</PopoverBody>
      </Popover>
    );
  };

  if (!patient) {
    return <React.Fragment>Select a patient</React.Fragment>;
  } else {
    const initials = patient['firstName'] ? patient.firstName[0] + patient.lastName[0] : '-';
    return (
      <React.Fragment>
        <div className="notification-wrapper">
        </div>
        <div className="patient-header">
          <div className="avatar-and-main-info">
            <div className="avatar-container">
              <div
                className="d-none d-xl-flex avatar-preview"
                data-initials-preview={initials}
              ></div>
            </div>
            <div className="main-info-container">
              <div className="name-and-download-icon">
                <div className="patient-name">{fullName}</div>
                <div
                  className="clickable-icon"
                  id="patient-contact-popover"
                  onMouseEnter={() =>
                    setIconNameOpen({
                      contact: true,
                      downloadPDF: false,
                      saveForLater: false,
                    })
                  }
                  onMouseLeave={() =>
                    setIconNameOpen({ ...iconNameOpen, contact: false })
                  }
                >
                  <FontAwesomeIcon
                    icon={faAddressCard}
                    style={{ fontSize: "22px", color: "#7A8EAB" }}
                  />
                </div>
                {iconName({
                  isOpen: iconNameOpen.contact,
                  name: "Contact Info",
                  target: "patient-contact-popover"
                })}
                <Popover
                  isOpen={popoverOpen}
                  placement="bottom"
                  target="patient-contact-popover"
                  hideArrow={true}
                  toggle={() => setPopoverOpen(!popoverOpen)}
                  trigger="legacy"
                  popperClassName="wide-popover pt-contact-popper"
                >
                  <PopoverBody className="filter-popover-body">
                    <ContactInfoCard
                      patientObj={patient}
                      setPopoverOpen={val => setPopoverOpen(val)}
                    />
                  </PopoverBody>
                </Popover>
                <div
                  className="clickable-icon"
                  id="download-pdf-popover"
                  type="button"
                  onMouseEnter={() =>
                    setIconNameOpen({
                      downloadPDF: true,
                      contact: false,
                      saveForLater: false
                    })
                  }
                  onMouseLeave={() =>
                    setIconNameOpen({ ...iconNameOpen, downloadPDF: false })
                  }
                >
                  <DownloadIcon />
                </div>
                {iconName({
                  isOpen: iconNameOpen.downloadPDF,
                  name: "Download PDF",
                  target: "download-pdf-popover"
                })}
                <Popover
                  placement="bottom"
                  isOpen={pdfPopoverOpen}
                  target="download-pdf-popover"
                  hideArrow={true}
                  toggle={() => setPdfPopoverOpen(!pdfPopoverOpen)}
                  trigger="legacy"
                >
                  <PopoverBody>
                    <PDFoptions
                      setPdfPopoverOpen={val => setPdfPopoverOpen(val)}
                    />
                  </PopoverBody>
                </Popover>
                <div
                  id="save-for-later"
                  className="clickable-icon"
                  onClick={e => {
                    e.preventDefault();
                    if (encounterNoteText.length > 0) {
                      saveTrackerNote({
                        isComplete: 0,
                        isTnUpdate: isTnUpdate,
                        tnToUpdate: tnId,
                        minutes,
                        completeTask: 0
                      });
                    }
                    if (soapNoteText != `${soapTemplate()}\nPCP: ${patient.doctorInfo &&
                      patient.doctorInfo.doctorName &&
                      patient.doctorInfo.doctorName}\n`) {
                      saveSoapNote({
                        isComplete: 0,
                        isUpdate: isSoapNoteUpdate,
                        completeTask: 0
                      });
                    }
                  }}
                  onMouseEnter={() =>
                    setIconNameOpen({
                      saveForLater: true,
                      contact: false,
                      downloadPDF: false
                    })
                  }
                  onMouseLeave={() =>
                    setIconNameOpen({ ...iconNameOpen, saveForLater: false })
                  }
                >
                  <TaskIcon height="22px" width="22px" />
                </div>
                {iconName({
                  isOpen: iconNameOpen.saveForLater,
                  name: "Save for later",
                  target: "save-for-later"
                })}
                {/* removed sticky notes pop over */}
              </div>
              <div className="main-info-row">
                <div className="main-info-row-item">
                  <span className="normal">Age:</span>{" "}
                  <span className="bold">
                    {patient["age"] ? patient.age : "-"}
                  </span>
                </div>
                <div className="main-info-row-item">
                  <span className="normal">Gender:</span>{" "}
                  <span className="bold">
                    {patient["gender"] ? patient.gender : "-"}
                  </span>
                </div>
                <div className="main-info-row-item">
                  <span className="normal">DOB:</span>{" "}
                  <span className="bold">
                    {patient["birthday"]
                      ? moment(patient.birthday).format("MM/DD/YYYY")
                      : "-"}
                  </span>
                </div>
                <div className="main-info-row-item">
                  <span className="normal">MID:</span>{" "}
                  <span className="bold">
                    {patient["patientId"] ? patient.patientId : "-"}
                  </span>
                </div>
              </div>
              <div className="main-info-row">
                <div className="main-info-row-item">
                  <span className="bold">
                    {patient["careType"] ? patient.careType : "-"}
                  </span>
                </div>
                <div className="main-info-row-item">
                  <span className="normal">WC:</span>{" "}
                  <span className="bold">
                    {patient["primaryWc"] ? patient.primaryWc : "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="breadcrumbs-wrapper">{breadcrumbs(nextScreen)}</div>
          <div className="buttons-to-patient pt-screen">
            {nextScreenLink(nextScreen)}
          </div>
        </div>
        <div className="save-for-later-notification">
          {/* possibly the best place for all notifications */}
          {showNotification && <Notification />}
        </div>
      </React.Fragment>
    );
  }
});

const mapStateToProps = state => {
  const {
    patientBioData: { bios },
    patientMetadata: { patientPage: { currentPatient } },
    trackerNoteInput,
    tasksData: { tasks },
    notificationsData: { showNotification, notificationMessage },
    alertsData: {alerts}
  } = state;
  const patient = bios[currentPatient];
  return {
    patient,
    alerts,
    encounterNoteText: (trackerNoteInput[currentPatient] && trackerNoteInput[currentPatient]['text']) ? trackerNoteInput[currentPatient]['text'] : "",
    soapNoteText: (trackerNoteInput[currentPatient] && trackerNoteInput[currentPatient]['soapNoteText']) ? trackerNoteInput[currentPatient]['soapNoteText'] : "",
    tasks,
    patientId: currentPatient,
    trackerNoteInput,
    tnId: (trackerNoteInput[currentPatient] && trackerNoteInput[currentPatient]['tnId']) ? trackerNoteInput[currentPatient]['tnId'] : "",
    soapId: (trackerNoteInput[currentPatient] && trackerNoteInput[currentPatient]['soapNoteId']) ? trackerNoteInput[currentPatient]['soapNoteId'] : "",
    isTnUpdate: (trackerNoteInput[currentPatient] && trackerNoteInput[currentPatient]['isTnUpdate']) ? trackerNoteInput[currentPatient]['isTnUpdate'] : 0,
    isSoapNoteUpdate: (trackerNoteInput[currentPatient] && trackerNoteInput[currentPatient]['isSoapNoteUpdate']) ? trackerNoteInput[currentPatient]['isSoapNoteUpdate'] : 0,
    showNotification,
    notificationMessage
  };
};

const mapActionToProps = {
  setEncounterNoteText: setNoteText,
  setSoapNoteText,
  setTnId,
  setIsTnUpdate,
  setShowNotification,
  setNotificationMessage,
  setIsSoapNoteUpdate,
  setSoapNoteId,
  addPtToCallQRedux,
  updateClaimedFlag
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(PersistentInfoTop);
