import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import moment from 'moment';
import styled from "styled-components";
import parse from "html-react-parser";

import HighChart from '../shared_components/HighChart';
import { fetchChartData } from "../../redux/highchart/actions";
import { ReactComponent as Logo } from "../../images/mynurse_logo_blue.svg";
import { fetchAlertsHistory } from "../../redux/alerts_history/actions";
import { setPagesCount } from "../../redux/pdf_options/actions";
import AlertsHistory from "../shared_components/alerts_history/AlertsHistory";

import "./PDFtoSend.css";

const PurpleMark = styled.div`
  background-color: rgb(194, 110, 234);
  width: 10px;
  height: 10px;
  margin-right: 6px;
`;

const GreenMark = styled.div`
  background-color: rgb(110, 234, 211);
  width: 10px;
  height: 10px;
  margin-right: 6px;
`;

const LegendWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 15px;
  justify-content: center;
`;

const LegenItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const PDFtoSend = React.memo(function PDFtoSend(props) {
  const { currentPatient, startDate, endDate, chartsByPatient, fetchChartData, statsToShow, chartsFetching, showPDF, selectedSoap, selectedPatient, fetchAlertsHistory, alertsHistoryRedux, showDataFeed, refs, showBP, showBG, showPulse, setPagesCount } = props
  const [alertsHistoryFetched, setAlertsHistoryFetched] = useState(false)
  const name = selectedPatient.firstName + ' ' + selectedPatient.lastName;
  const dob = moment(selectedPatient.birthday).format("MM/DD/YYYY");

  const [showBPGraph, setShowBPGraph] = useState(false);
  const [showBGGraph, setShowBGGraph] = useState(false);
  const [showPulseGraph, setShowPulseGraph] = useState(false);

  const [metricsArr, setMetricsArr] = useState([]);

  let startDateMoment = moment(startDate).format("l").split('/');
  let endDateMoment = moment(endDate).format("l").split('/');

  let startMonth = Number(startDateMoment[0]);
  let endMonth = Number(endDateMoment[0]);

  let startYear = Number(startDateMoment[2]);
  let endYear = Number(endDateMoment[2]);

  while (startYear < endYear) {
    endMonth += 12;
    startYear++;
  }

  const isCompact = () => {
    if (alertsHistoryRedux["blood_pressure"]) {
      if (alertsHistoryRedux["blood_pressure"].length > 90) {
        return false
      }
    }
    if (alertsHistoryRedux["glucose"]) {
      if (alertsHistoryRedux["glucose"].length > 90) {
        return false;
      }
    }
    if (alertsHistoryRedux["pulse"]) {
      if (alertsHistoryRedux["pulse"].length > 90) {
        return false;
      }
    }
    return true
  }

  const getDataFeedPagesCount = () => {
    let count = 1;
    let breakPoint = 90;
    for (let i = 0; i < alertsHistoryRedux["blood_pressure"].length - 1; i++) {
      if (i > breakPoint) {
        breakPoint += 90;
        count ++;
      }
    }
    console.log(count)
    return count;
  }

  const monthsSelected = (endMonth === startMonth || isCompact()) ? 1 : (endMonth - startMonth + 1);

  const getChartsObj = () => {
    let result = {};
    if (showBPGraph) {
      result["blood_pressure"] = true;
    }
    if (showBGGraph) {
      result["glucose"] = true;
    }
    if (showPulseGraph) {
      result["pulse"] = true;
    }
    let biometricsCount = Object.keys(result).length;
    if (monthsSelected <= 1 && isCompact()) {
      setPagesCount(biometricsCount + 1)
    } else if (monthsSelected == 1 && !isCompact() && showDataFeed) {
      setPagesCount(biometricsCount + biometricsCount * getDataFeedPagesCount() + 1) // break one month data into blocks, one block per page
    } else if ( monthsSelected > 1 && showDataFeed) {
      setPagesCount(biometricsCount + biometricsCount * Math.ceil(monthsSelected / 2) + 1) //place alerts history on a new page, 2 month per page
    } else {
      setPagesCount(biometricsCount + 1)
    }
    return result;
  }

  const getMetricsArr = () => {
    let chartsObj = getChartsObj()
    let result = []
    Object.keys(chartsObj).forEach(metric => {
      if (!chartsObj[metric]) {
        return null;
      } else {
        result.push(metric)
      }
    })
    return result;
  }

  useEffect(() => {
    if (!alertsHistoryFetched) {
      fetchAlertsHistory(currentPatient, "blood_pressure", startDate, endDate);
      fetchAlertsHistory(currentPatient, "glucose", startDate, endDate);
      fetchAlertsHistory(currentPatient, "pulse", startDate, endDate);
      setAlertsHistoryFetched(true);
    }

    if (showBP && !chartsByPatient["blood_pressure"]) {
      fetchChartData(currentPatient, "blood_pressure");
    }
    if (showBG && !chartsByPatient["glucose"]) {
      fetchChartData(currentPatient, "glucose");
    }
    if (showPulse && !chartsByPatient["pulse"]) {
      fetchChartData(currentPatient, "pulse");
    }
    if (
      chartsByPatient["blood_pressure"] &&
      chartsByPatient.blood_pressure["timeseries"] &&
      chartsByPatient.blood_pressure.timeseries[0].name !== "encounter" &&
      showBP
    ) {
      setShowBPGraph(true);
    }
    if (
      chartsByPatient["glucose"] &&
      chartsByPatient.glucose["timeseries"] &&
      chartsByPatient.glucose.timeseries[0].name !== "encounter" &&
      showBG
    ) {
      setShowBGGraph(true);
    }
    if (
      chartsByPatient["pulse"] &&
      chartsByPatient.pulse["timeseries"] &&
      chartsByPatient.pulse.timeseries[0].name !== "encounter" &&
      showPulse
    ) {
      setShowPulseGraph(true);
    }
    setMetricsArr(getMetricsArr());
  }, [
      chartsFetching,
      showPDF,
      alertsHistoryRedux,
    ]);

  const getTitle = (metric) => {
    switch (metric) {
      case 'blood_pressure':
        return 'Blood Pressure'
      case 'glucose':
        return 'Glucose'
      case 'pulse':
        return 'Heart Rate'
      default:
        return '';
    }
  }

  const getParagraphs = (text) => {
    return text.split('\n').map((textPart, key) => {
      let regex;
      let find = ["Subjective:", "Objective:", "Assessment:", "Plan:", "Situation:", "Background:", "Recommendation"]
      for (let i = 0; i < find.length; i++) {
        regex = new RegExp(find[i], "g");
        textPart = textPart.replace(find[i], `<b>${find[i]}</b>`);
      }
      return <span key={key}><br />{parse(textPart)}</span>
    })
  }

  const getHighCharts = () => {
    let j = -1
    return metricsArr.map((metric, i) => {
      j += 1
      if (monthsSelected > 1 && j > 0) {
        j += Math.ceil(monthsSelected / 2)
      }

      if (monthsSelected == 1 && !isCompact() && j > 0) {
        j += getDataFeedPagesCount()
      }

      if (monthsSelected == 1 && isCompact()) {
        return (
          <div key={`${i}-${Date.now()}`} >
            <div id={`chart-wrapper-${i}`}
              ref={refs[j + 1]}
              className="main-page"
            >
              <DateWrapper>
                Date Range: {moment(startDate).format("MM/DD/YYYY")} -{" "}
                {moment(endDate).format("MM/DD/YYYY")}
              </DateWrapper>
              <div className="chart-title">{getTitle(metric)}</div>
              <HighChart
                metricFromParent={metric}
                currentPatient={currentPatient}
                minTimeFromParent={startDate}
                maxTimeFromParent={endDate}
                view={"signScreen"}
              />
              {metric === "blood_pressure" ? (
                <LegendWrapper>
                  <LegenItem>
                    <GreenMark />
                    Systolic
                </LegenItem>
                  <LegenItem>
                    <PurpleMark />
                    Diastolic
                </LegenItem>
                  <LegenItem>
                    7-day avarage: {statsToShow["7_day_average_bp"]}
                  </LegenItem>
                  <LegenItem>
                    30-day avarage: {statsToShow["30_day_average_bp"]}
                  </LegenItem>
                  <LegenItem>Baseline: {statsToShow["baseline_bp"]}</LegenItem>
                </LegendWrapper>
              ) : null}
              {metric === "glucose" ? (
                <LegendWrapper>
                  <LegenItem>
                    <PurpleMark />
                    Glucose
                </LegenItem>
                  <LegenItem>
                    7-day avarage: {statsToShow["7_day_average_bg"]}
                  </LegenItem>
                  <LegenItem>
                    30-day avarage: {statsToShow["30_day_average_bg"]}
                  </LegenItem>
                  <LegenItem>Baseline: {statsToShow["baseline_bg"]}</LegenItem>
                </LegendWrapper>
              ) : null}
              {metric === "pulse" ? (
                <LegendWrapper>
                  <LegenItem>
                    <PurpleMark />
                    Heart Rate
                </LegenItem>
                </LegendWrapper>
              ) : null}
              {showDataFeed && (
                <AlertsHistory
                  metricForPdf={metric}
                  listForPdf={alertsHistoryRedux[metric]}
                  key={`${metric}-${i}`}
                />
              )}
            </div>
          </div>
        )
      } else {
        return (
          <div key={`${i}-${Date.now()}`}>
            <div
              id={`chart-wrapper-${i}`}
              ref={refs[j + 1]}
              className="main-page"
            >
              <DateWrapper>
                Date Range: {moment(startDate).format("MM/DD/YYYY")} -{" "}
                {moment(endDate).format("MM/DD/YYYY")}
              </DateWrapper>
              <div className="chart-title">{getTitle(metric)}</div>
              <HighChart
                metricFromParent={metric}
                currentPatient={currentPatient}
                minTimeFromParent={startDate}
                maxTimeFromParent={endDate}
                view={"signScreen"}
              />
              {metric === "blood_pressure" ? (
                <LegendWrapper>
                  <LegenItem>
                    <GreenMark />
                    Systolic
                  </LegenItem>
                  <LegenItem>
                    <PurpleMark />
                    Diastolic
                  </LegenItem>
                  <LegenItem>
                    7-day avarage: {statsToShow["7_day_average_bp"]}
                  </LegenItem>
                  <LegenItem>
                    30-day avarage: {statsToShow["30_day_average_bp"]}
                  </LegenItem>
                  <LegenItem>Baseline: {statsToShow["baseline_bp"]}</LegenItem>
                </LegendWrapper>
              ) : null}
              {metric === "glucose" ? (
                <LegendWrapper>
                  <LegenItem>
                    <PurpleMark />
                    Glucose
                  </LegenItem>
                  <LegenItem>
                    7-day avarage: {statsToShow["7_day_average_bg"]}
                  </LegenItem>
                  <LegenItem>
                    30-day avarage: {statsToShow["30_day_average_bg"]}
                  </LegenItem>
                  <LegenItem>Baseline: {statsToShow["baseline_bg"]}</LegenItem>
                </LegendWrapper>
              ) : null}
              {metric === "pulse" ? (
                <LegendWrapper>
                  <LegenItem>
                    <PurpleMark />
                    Heart Rate
                  </LegenItem>
                </LegendWrapper>
              ) : null}
            </div>
            {/* pass refs to the alerts history pages only if they are separate pages */}
            {showDataFeed && (
              <AlertsHistory
                metricForPdf={metric}
                listForPdf={alertsHistoryRedux[metric]}
                key={`${metric}-${i}`}
                refsArr={
                  isCompact()
                    ? refs.slice(j + 2, j + 2 + Math.ceil(monthsSelected / 2))
                    : refs.slice(j + 2, j + 2 + getDataFeedPagesCount())
                } // 2 month per page
              />
            )}
          </div>
        );
      }
    });
  }

  if (chartsFetching) {
    return<>Loading</>
  } else {
    return (
      <div id="pdf-summary">
        <div id="page-1" ref={refs[0]} className="main-page">
          <div id="text-pdf">
            <div id="pdf-header">
              <Logo style={{ height: "28px", width: "180px" }} />
              <div id="pdf-date-and-phone">
                <div className="pdf-header-right">{moment(Date.now()).format('L')}</div>
                <div className="pdf-header-right">Clinical Questions: 530.429.2427</div>
              </div>
            </div>
            <div id="soap-meta">
              <div id="soap-meta-left">
                <div id="pdf-summary-header">PROVIDER ATTENTION REQUIRED</div>
                <p>{name}</p>
                <p>{dob}</p>
              </div>
              {selectedSoap && selectedSoap["wellnessCoach"] && (
                <div id="soap-meta-right">
                  <p>Note prepared by:</p>
                  <p>{selectedSoap.wcName}</p>
                  <p>{selectedSoap.wellnessCoach}</p>
                </div>
              )}
            </div>
            <div className="soap-container">
              {selectedSoap && selectedSoap["wellnessCoach"]
                ? getParagraphs(selectedSoap.text)
                : null}
            </div>
          </div>
        </div>
        {/* if no biometric is selected, then no alerts history is displayed either */}
        {/* {((!showBP || !showBG || !showPulse) && showDataFeed) && getAlertsHistory()} */}
        {(showBP || showBG || showPulse) && getHighCharts()}
      </div>
    );
  }
});

const mapStateToProps = state => {
  const {
    patientMetadata: { patientPage: { currentPatient } },
    chartData: { charts, isFetching },
    pdfOptions: { startDate, endDate, showPDF, soapDate, showDataFeed, showBP, showBG, showPulse },
    recentStatsData: { stats },
    soapNotesData: { soapNotes },
    patientBioData: { bios },
    alertsHistoryData: { alertsHistory }
  } = state;
  const chartsByPatient = charts[currentPatient];
  const statsToShow = stats[currentPatient]
  const selectedSoap = soapNotes[currentPatient][soapDate]
  const selectedPatient = bios[currentPatient]
  const alertsHistoryRedux = alertsHistory[currentPatient]
  return {
    currentPatient,
    chartsByPatient,
    startDate,
    endDate,
    statsToShow,
    showBP,
    showBG,
    showPulse,
    showDataFeed,
    chartsFetching: isFetching,
    showPDF,
    selectedSoap,
    selectedPatient,
    alertsHistoryRedux,
    // refs: refsArr
  };
};

const mapActionsToProps = { fetchChartData, fetchAlertsHistory, setPagesCount };


export default connect(
  mapStateToProps,
  mapActionsToProps
)(PDFtoSend);