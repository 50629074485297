import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import { baseURL } from "../../config";
import applyCaseMiddleware from "axios-case-converter";
import {Input, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlusCircle } from "@fortawesome/free-solid-svg-icons";

import { setShowNotification, setNotificationMessage } from "../../redux/notifications/actions";

import { updateMedications as updateMedicationsRedux } from "../../redux/patient_bios/actions";
var qs = require("qs");

const MedicationsEditModal = React.memo(function MedicationsEditModal(props) {
  const { medsArr, toggleMedsEditMode, isModalOpen, patientId, setShowNotification, setNotificationMessage, updateMedicationsRedux } = props

  const [medsTextState, setMedsTextState] = useState({})

  useEffect(()=> {
    for (let i = 0; i < medsArr.length; i++) {
      setMedsTextState(medsTextState => ({ ...medsTextState, [i]: medsArr[i] }));
    }
  }, [])

  const handleChange = (i, val) => {
    setMedsTextState(medsTextState => ({ ...medsTextState, [i]: val }));
  }

  const updateMedications = async () => {
    const client = applyCaseMiddleware(axios.create());
    let response = await client({
      method: "POST",
      url: `/update_medications`,
      baseURL,
      params: {
        medications: Object.values(medsTextState),
        patientId
      },
      withCredentials: true,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      }
    });
    if (response.status === 200) {
      setShowNotification(true);
      setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 3000)
      setNotificationMessage({ text: "Medications have been updated!", type: "success", isFor: 'topRightPlan' });
      updateMedicationsRedux({ patientId, medications: Object.values(medsTextState) });
    } else {
      console.log(response.data)
      setShowNotification(true);
      setTimeout(() => { setShowNotification(false); setNotificationMessage({}); }, 2000)
      setNotificationMessage({ text: "Could not update at this time", type: "danger", isFor: 'topRightPlan' });
    }
  }

  const addInputField = () => {
    setMedsTextState(medsTextState => ({ ...medsTextState, [Object.keys(medsTextState).length]: '' }));
  }

  const deleteInputField = (index) => {
    let tempState = {};
    for (let i in medsTextState) {
      if (index !== i) {
        tempState[i] = medsTextState[i]
      }
    }
    setMedsTextState(medsTextState => (tempState));
  }

  const medsInputFields = () => {
    return Object.keys(medsTextState).map((i) => {
      return (
        <div className="checkbox" key={i}>
          <FormGroup style={{display: "flex"}}>
            <Input
              type="text"
              value={medsTextState[i]}
              onChange={e => handleChange(i, e.target.value)}
            />
            <div className="trash-icon-wrapper">
            <FontAwesomeIcon icon={faTrash} onClick={()=> {deleteInputField(i)}} />
            </div>
          </FormGroup>
        </div>
      );
    });
  };

  return (
    <>
      <Modal isOpen={isModalOpen} toggle={toggleMedsEditMode}>
        <ModalHeader toggle={toggleMedsEditMode}>
          Update Medications
        </ModalHeader>
        <ModalBody className="meds-modal-body">
          {medsInputFields()}
          <div className="add-icon-wrapper">
            <FontAwesomeIcon icon={faPlusCircle} onClick={() => { addInputField() }} />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              updateMedications();
              // updatePrimaryConditionsRedux({ patientId, medsArr });
              toggleMedsEditMode();
            }}
            style={{ lineHeight: "1" }}
          >
            Submit
            </Button>
          <Button
            color="secondary"
            onClick={()=> toggleMedsEditMode()}
            style={{ lineHeight: "1" }}
          >
            Cancel
            </Button>
        </ModalFooter>
      </Modal>
    </>
  );
});


const mapActionToProps = {
  setShowNotification, setNotificationMessage, updateMedicationsRedux
};

export default connect(
  null,
  mapActionToProps
)(MedicationsEditModal);
