import getAlertsData from "./util";

export const FETCH_ALERTS_REQUEST = 'fetchAlertsRequest';
export const FETCH_ALERTS_SUCCESS = 'fetchAlertsSucess';
export const FETCH_ALERTS_ERROR = 'fetchAlertsError';
export const UPDATE_CLAIMED_FLAG_ALERT = 'updateClaimedFlagAlert';

const fetchRequest = () => {
  return {
    type: FETCH_ALERTS_REQUEST
  };
}

const fetchSuccess = (alertsData) => {
  return {
    type: FETCH_ALERTS_SUCCESS,
    payload: alertsData
  }
}

const fetchError = (error) => {
  return {
    type: FETCH_ALERTS_ERROR,
    payload: error
  }
}

export const fetchAlertsData = (params) => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    const alertsData = await getAlertsData(params);
    let alerts = alertsData.data;
    const { offset, search_term: searchTerm } = params;
    let paramsFiltered = {};
    for (let key in params) {
      if (key != "offset") {
        paramsFiltered[key] = params[key]
      }
    }
    dispatch(fetchSuccess({alerts, offset, searchTerm, filterParams: paramsFiltered}));
  } catch (error) {
    console.log(error);
    dispatch(fetchError(error));
  }
}

export const updateClaimedFlag = (alert_id, bool) => dispatch => {
  dispatch({
    type: UPDATE_CLAIMED_FLAG_ALERT,
    payload: {_id: alert_id, claimed_flag: bool}
  });
};
