import React, { useEffect } from 'react';
import AlertsContainer from "./new_alerts/AlertsContainer";
import TabsContainer from "./patient_preview/TabsContainer";
import SidebarDashboard from './SidebarDashboard';

import './App.css'

const App = React.memo(function App(props) {
  // const setupBeforeUnloadListener = () => {
  //   window.addEventListener("beforeunload", (e) => {
  //     e.preventDefault();
  //     return (e.returnValue = "Leave site?");
  //   });
  // };

  // useEffect(()=> {
  //   setupBeforeUnloadListener()
  // }, [])


  return (
    <div className="container-main">
      <SidebarDashboard />
      <div className="row-main dashboard">
        <div className="col-main menu">
          <AlertsContainer />
        </div>
        <div className="col-main patient-data">
          <TabsContainer />
        </div>
      </div>
    </div>
  );
});

export default App;

