import getCurrentUser from "./util";

export const FETCH_USER_REQUEST = "fetchUserRequest";
export const FETCH_USER_SUCCESS = "fetchSucess";
export const FETCH_USER_ERROR = "fetchUserError";

const fetchUserRequest = () => {
  return {
    type: FETCH_USER_REQUEST
  };
};

const fetchUserSuccess = user => {
  return {
    type: FETCH_USER_SUCCESS,
    payload: user
  };
};

const fetchUserError = error => {
  return {
    type: FETCH_USER_ERROR,
    payload: error
  };
};

export const fetchUser = () => async dispatch => {
  dispatch(fetchUserRequest());
  try {
    const response = await getCurrentUser();
    let user = response.data;
    dispatch(fetchUserSuccess(user));
  } catch (error) {
    console.log(error);
    dispatch(fetchUserError(error));
  }
};
