import React, { useState } from "react";
import DatePicker from "react-date-picker";

const DatePickerComponent = React.memo(function DatePickerComponent({selectedDate, onDateSelect}) {
  const [value, setValue] = useState(new Date (selectedDate));

  return (
    <div className="datepicker-wrapper">
      <DatePicker onChange={val=> {setValue((val)); onDateSelect(val)}} value={value} />
    </div>
  );
});

export default DatePickerComponent;