import {
  FETCH_CUSTOM_STATS_REQUEST,
  FETCH_CUSTOM_STATS_SUCCESS,
  FETCH_CUSTOM_STATS_ERROR,
  SET_CUSTOM_STATS_SELECTED
} from "./actions";

export default (
  state = {
    customStats: {},
    isFetching: false,
    customStatsSelected: false,
    error: null
  },
  action
) => {
  switch (action.type) {
    case FETCH_CUSTOM_STATS_REQUEST:
      return { ...state, error: null, isFetching: true };
    case FETCH_CUSTOM_STATS_SUCCESS:
      return {
        ...state,
        currentMetric: action.payload.metric,
        customStats: action.payload.stats,
        isFetching: false
      };
    case FETCH_CUSTOM_STATS_ERROR:
      return { ...state, error: action.payload, isFetching: false };
    case SET_CUSTOM_STATS_SELECTED:
      return { ...state, customStatsSelected: action.payload };
    default:
      return state;
  }
};
