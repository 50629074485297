import React from "react";
import  moment from "moment"
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
// import axios from 'axios';
// import { baseURL } from "../../config";

const DownloadPDF = React.memo(function DownloadPDF({ label, patientObj, refs }) {
  const patientName = `${patientObj.lastName}, ${patientObj.firstName}`;
  let canvasRefs = refs
  console.log(canvasRefs)
  let canvasPromises = []
  let canvasImages = Array(refs.length)

  const generatePDF = () => {

    // A4 size: [210, 297]
    let doc = new jsPDF("p", "mm", "a4", true)
    const page_size = [210, 297]

    const html2canvasPage = (ref, index) => {
      const canvas_w = ref.current.offsetWidth
      const canvas_h = ref.current.offsetHeight
      const ratio = canvas_h / canvas_w

      const promise = html2canvas(ref.current, {
        dpi: 300,
        scale: 3
      })

      canvasPromises.push(promise)
      promise.then(canvas => {
        const img = canvas.toDataURL("image/jpeg", 1)
        const width = doc.internal.pageSize.getWidth(); // removed subtraction of 15 cm
        const height = width * ratio
        const canvasImage = {
          img,
          width: width,
          // width: width > page_size[0] ? page_size[0] - 5 : width,  // Fixing sizes if the image is too big
          height: height > page_size[1] ? page_size[1] - 15 : height,
        }

        canvasImages[index] = canvasImage
      })
        .catch(err => {
          throw (err)
        })
    }


    canvasRefs.forEach((ref, index) => {
      html2canvasPage(ref, index);
    })
    let position = 0
    Promise.all(canvasPromises).then(result => {
      canvasImages.forEach((canvasImg, index) => {
        if (index !== 0) {
          doc.addPage();
        }
        doc.addImage(canvasImg.img, 'PNG', 0, position, canvasImg.width, canvasImg.height, undefined, 'FAST');
        doc.setFontSize(10);
        doc.text(5, 290, patientName + ". DOB " + moment(patientObj.birthday).format('L'));
        doc.text(90, 290, "mynurse.ai");
        doc.text(190, 290, 'Page ' + (index + 1));
      })
      doc.save(`${patientObj.lastName}, ${patientObj.firstName}.pdf`);
    });
  }

  return (
    <div
      onClick={() => { generatePDF()
        // html2canvas(document.getElementById(id))
        // .then(function (canvas) {
        //   var imgData = canvas.toDataURL('image/png');
        //   var imgWidth = 210;
        //   var pageHeight = 297;
        //   var imgHeight = canvas.height * imgWidth / canvas.width;
        //   var heightLeft = imgHeight;
        //   var doc = new jsPDF("p", "mm");
        //   var position = 10;

        //   doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        //   heightLeft -= pageHeight;

        //   while (heightLeft >= 0) {
        //     position += heightLeft - imgHeight;
        //     doc.addPage();
        //     doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        //     heightLeft -= pageHeight;
        //   }
        //   doc.save(`${patientName}_summary.pdf`);
        // });
      }
      }>
      <button>{label}</button>
    </div>
  );
});

export default DownloadPDF;

//Later replace save function with post request
//     // var base64pdf = btoa(pdf.output());

//     // axios({
//     //   method: "POST",
//     //   url: `/post_pdf`,
//     //   baseURL,
//     //   contentType: 'application/json; charset=utf-8',
//     //   data: base64pdf,
//     //   dataType: 'json'
//     // });




