import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { Button } from 'reactstrap';
import UploadFileModal from "./UploadFileModal";

import axios from "axios";
import applyCaseMiddleware from "axios-case-converter";
import { baseURL } from "../../config";

import { setShowNotification, setNotificationMessage } from "../../redux/notifications/actions";

import "./EncounterNotes.css";
import "./Documents.css";

const Documents = React.memo(function Documents(props) {
  const { currentPatient, view } = props;
  const dispatch = useDispatch();

  const documents = useSelector(state => state.documentsData.documents);
  const [ selectedDoc, setSelectedDoc ] = useState(null);
  const [ uploadFileModalOpen, setUploadFileModalOpen ] = useState(false);
  const toggleModal = () =>  setUploadFileModalOpen(!uploadFileModalOpen);

  const [ docsToDisplay, setDocsToDisplay ] = useState([])
  // this is to rerender updated documents list
  useEffect(() => {
    if (currentPatient && documents[currentPatient]) {
      setDocsToDisplay(documents[currentPatient]);
    }
  }, [documents, documents[currentPatient]]);

  const getDocs = () => {
    return docsToDisplay.map((docObj, i) => {
      return (
        <div
          key={i}
          onClick={() => setSelectedDoc(docObj.fieldValue)}
          className={classnames(
            { active: docObj.fieldValue === selectedDoc },
            "document-list-item"
          )}
        >
          {docObj.fieldName}
        </div>
      );
    })
  }

  const deleteDoc = async () => {
    const client = applyCaseMiddleware(axios.create());
    let response;
    try {
      response = await client({
        method: "POST",
        url: `delete_file?file_name=${selectedDoc}&patient_id=${currentPatient}`,
        baseURL,
      })
      if (response.status === 200) {
        dispatch(setShowNotification(true));
        setTimeout(() => { dispatch(setShowNotification(false)); dispatch(setNotificationMessage({})) }, 2000)
        dispatch(setNotificationMessage({ text: 'The document has been deleted', type: 'success', isFor: 'topRightPlan' }));
      }
    } catch (err) {
      console.log(err)
      dispatch(setShowNotification(true));
      setTimeout(() => { dispatch(setShowNotification(false)); dispatch(setNotificationMessage({})) }, 2000)
      dispatch(setNotificationMessage({ text: 'Can\'t be deleted at this time', type: 'danger', isFor: 'topRightPlan' }));
    }
  }

  return (
    <div className="documents-wrapper">
      <div className="documents-list">
        {documents && documents[currentPatient] && documents[currentPatient].length > 0 &&
          <>
            {getDocs()}
          </>}
          <div className="upload-button-wrapper">
            {/* disactivated upload file modal until it's working */}
            {/* <Button onClick={()=> {setUploadFileModalOpen(true)}} className="upload-button">Upload</Button> */}
            <UploadFileModal isOpen={uploadFileModalOpen} toggleModal={()=> toggleModal()} patientId={currentPatient} />
          </div>
      </div>
      <div className="divider-line"></div>
      <div className="document-preview">
      {selectedDoc &&
        <>
          <div className="doc-preview-controls" href={selectedDoc} target="_blank">
              <a href={selectedDoc} target="_blank"><FontAwesomeIcon icon={faExternalLinkAlt} /></a>
              <div><FontAwesomeIcon icon={faTimes} onClick={()=> setSelectedDoc(null)} style={{marginLeft: "15px"}} /></div>
              {/* <div><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteDoc()} style={{ marginLeft: "15px" }} /></div> */}
          </div>
          <iframe src={selectedDoc} style={{width: "100%", height: "100%"}} />
          </>
      }
      </div>
    </div>
  );
});

export default Documents;

