export const SET_CURRENT_PATIENT_ALERTS_PAGE = 'setCurrentPatientAlertsPage';
export const SET_CURRENT_METRIC_ALERTS_PAGE = 'setCurrentMetricAlertsPage';
export const ADD_PATIENT_ALERTS_PAGE = 'addPatientAlertsPage';
export const UPDATE_PATIENTS_ALERTS_PAGE = "updatePatientsAlertsPage";

export const SET_CURRENT_PATIENT_PATIENTS_PAGE = 'setCurrentPatientPatientsPage';
export const SET_CURRENT_METRIC_PATIENT_PAGE = 'setCurrentMetricPatientsPage';
export const UPDATE_PATIENTS_PATIENTS_PAGE = "updatePatientsPatientsPage";

export const SET_IS_LOGGEDIN = 'setIsLoggedIn';

export const setIsLoggedIn = bool => dispatch => {
  dispatch({
    type: SET_IS_LOGGEDIN,
    payload: bool
  })
}

//alerts page actions
export const setCurrentPatientAlertsPage = id => dispatch => {
  dispatch({
    type: SET_CURRENT_PATIENT_ALERTS_PAGE,
    payload: id
  })
}

export const setCurrentMetricAlertsPage = metric => dispatch => {
  dispatch({
    type: SET_CURRENT_METRIC_ALERTS_PAGE,
    payload: metric
  })
}

export const addPatientAlertsPage = (id, name) => dispatch => {
  dispatch ({
    type: ADD_PATIENT_ALERTS_PAGE,
    payload: {id: Number(id), name}
  })
}

export const updatePatientsAlertsPage = filteredPatients => dispatch => {
  dispatch({
    type: UPDATE_PATIENTS_ALERTS_PAGE,
    payload: filteredPatients
  });
};

//patients page actions
export const setCurrentPatientPatientsPage = id => dispatch => {
  dispatch({
    type: SET_CURRENT_PATIENT_PATIENTS_PAGE,
    payload: id
  })
}

export const setCurrentMetricPatientsPage = metric => dispatch => {
  dispatch({
    type: SET_CURRENT_METRIC_PATIENT_PAGE,
    payload: metric
  })
}

export const updatePatientsPatientsPage = filteredPatients => dispatch => {
  dispatch({
    type: UPDATE_PATIENTS_PATIENTS_PAGE,
    payload: filteredPatients
  });
};
