import {
  SET_SHOW_NOTIFICATION,
  SET_NOTIFICATION_MESSAGE
} from "./actions";

export default (
  state = {
    showNotification: false,
    notificationMessage: {}
  },
  action
) => {
  switch (action.type) {
    case SET_SHOW_NOTIFICATION:
      return { ...state, showNotification: action.payload };
    case SET_NOTIFICATION_MESSAGE:
      return { ...state, notificationMessage:  action.payload };
    default:
      return state;
  }
};
