import React from "react";
import moment from "moment";

import { getColor } from '../../new_alerts/AlertsRow';
import './AlertsHistoryRow.css';

const AlertsHistoryRow = React.memo(function AlertsHistoryRow(props) {
  const { alert, hideMetric, metricForPdf } = props;
  const getBackgroundColor = () => {
    if (alert.alert_type === "HR" || alert.alert_type === "W") {
      return "white";
    } else {
      return getColor(alert.alert_severity);
    }
  }

  const getTextColor = () => {
    if (alert.alert_severity == 0) {
      return "red";

    }
    if (alert.alert_severity == 1) {
      return "orange";
    } else {
      return "black";
    }
  };

  // manually add 7 hours if it's weight - it's saved in local time, but moment expects UTC

  let marginBottom = metricForPdf ? "0px" : "4px"
  let backgroundColor = metricForPdf ? "none" : getBackgroundColor();
  let color = metricForPdf ? getTextColor() : "black";
  return (
    <div className="alert-history-row" style={{marginBottom}}>
      <div className="alert-history-col">
        <div className="alert-data">
          {alert.alert_type == "W" ? moment(alert.alert_time).add(7, "hours").format("L").replace(/\b0+/g, '') : moment(alert.alert_time).format("L").replace(/\b0+/g, '')}
        </div>
      </div>
      <div className="alert-history-col">
        <div className="alert-data">
          {alert.alert_type == "W" ? moment(alert.alert_time).add(7, "hours").format("LT").toLowerCase() : moment(alert.alert_time).format("LT").toLowerCase()}
        </div>
      </div>
      <div className="alert-history-col alert-val-history">
        {!hideMetric && alert.alert_type == "BP" && <div className="alert-data alert-val-type">{alert.pulse_value}</div>}
        <div className="alert-data alert-val" style={{ backgroundColor, color}}><div>{alert.alert_value}</div></div>
      </div>
    </div>
  );
});

export default AlertsHistoryRow;
