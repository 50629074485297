import React from "react";
import classnames from "classnames";
import { Row, Col } from "reactstrap";

const DisplayContactInfo = React.memo(function DisplayContactInfo({ patientObj }) {
  const {
    patientId,
    minutes,
    phoneNumberMobile,
    phoneNumberHome,
    email,
    caretakerRelationship,
    caretakerFirstName,
    caretakerLastName,
    caretakerPhone,
    streetAddress,
    city,
    state,
    zipCode,
    primaryInsuranceType,
    primaryInsuranceMemberId,
    primaryInsuranceGroupId,
    secondaryInsuranceType,
    secondaryInsuranceMemberId,
    secondaryInsuranceGroupId,
    emergencyFirst,
    emergencyLast,
    emergencyPhone
  } = patientObj;

  let address = `${streetAddress} ${city} ${state} ${zipCode}`
  let caretakerName = caretakerFirstName + ' ' + caretakerLastName
  return (
    <>
      <div
        className={classnames(
          {
            "active-member": minutes > -1,
            "churned-member": minutes === -1
          },
          "bio-box-header"
        )}
      >
        {minutes > -1 ? "Active" : "Churned"}
      </div>
      <Row className="pt-contact-wrapper">
        <Col className="pt-contact-col">
          <div className="bio-box pt-contact">
            <div className="bio-box-title">Phone</div>
            <div className="bio-box-value">
              Home: {phoneNumberHome ? phoneNumberHome : "-"}
            </div>
            <div className="bio-box-value">
              Mobile: {phoneNumberMobile ? phoneNumberMobile : "-"}
            </div>
          </div>
          <div className="bio-box pt-contact">
            <div className="bio-box-title">Email</div>
            <div className="bio-box-value">{email ? email : "-"}</div>
          </div>
          <div className="bio-box pt-contact">
            <div className="bio-box-title">Caretaker</div>
            <div className="bio-box-value">
              {caretakerRelationship ? `${caretakerRelationship}: ` : "-"}
              {caretakerName ? caretakerName : "-"}
            </div>
            <div className="bio-box-value">
              Phone: {caretakerPhone ? caretakerPhone : "-"}
            </div>
          </div>
          <div className="bio-box pt-contact">
            <div className="bio-box-title">Emergency Contact</div>
            <div className="bio-box-value">
              {emergencyFirst ? emergencyFirst : ""}{' '}{emergencyLast ? emergencyLast : ""}
            </div>
            <div className="bio-box-value">
              Phone: {emergencyPhone ? emergencyPhone : "-"}
            </div>
          </div>
        </Col>
        <Col className="pt-contact-col">
          <div className="bio-box pt-contact">
            <div className="bio-box-title">Address</div>
            <div className="bio-box-value">{address}</div>
          </div>
          <div className="bio-box pt-contact">
            <div className="bio-box-title">Primary Insurance</div>
            <div className="bio-box-value">
              {primaryInsuranceType ? primaryInsuranceType : "-"}
            </div>
            <div className="bio-box-value">
              Member ID:{" "}
              {primaryInsuranceMemberId ? primaryInsuranceMemberId : "-"}
            </div>
            <div className="bio-box-value">
              Group ID:{" "}
              {primaryInsuranceGroupId ? primaryInsuranceGroupId : "-"}
            </div>
          </div>
          <div className="bio-box pt-contact">
            <div className="bio-box-title">Secondary Insurance</div>
            <div className="bio-box-value">
              {secondaryInsuranceType ? secondaryInsuranceType : "-"}
            </div>
            <div className="bio-box-value">
              Member ID:{" "}
              {secondaryInsuranceMemberId ? secondaryInsuranceMemberId : "-"}
            </div>
            <div className="bio-box-value">
              Group ID:{" "}
              {secondaryInsuranceGroupId ? secondaryInsuranceGroupId : "-"}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
});

export default DisplayContactInfo;
