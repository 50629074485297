import React from 'react';
import { connect } from 'react-redux'

import Chart from "react-google-charts";

import "./MLInsights.css";

const MLInsights = React.memo(function MLInsights({currentFrequencies}) {
  const labelToColorMap = { "dietTopicFrequency": "#03ebfc", "stressorTopicFrequency": "#f6cef5", "substAbuseTopicFrequency": "#fcd703", "stressMgmtTopicFrequency": "#66ff33", "fallRiskTopicFrequency": "#ffbb78" }
  const labelToDisplayNameMap = { "dietTopicFrequency": "Diet", "stressorTopicFrequency": "Stressor", "substAbuseTopicFrequency": "Substance Abuse", "stressMgmtTopicFrequency": "Stress Management", "fallRiskTopicFrequency": "Fall Risk" }

  const getPieChartData = () => {
    let data = [['Topic', 'Frequency']]
    let colors = {}
    let i = 0;
    for (let topic in currentFrequencies) {
      if (currentFrequencies[topic] > 0) {
        colors[i] = { color: labelToColorMap[topic]}
        i+=1;
        data.push([labelToDisplayNameMap[topic], currentFrequencies[topic]]);
      }
    }
    return {data, colors}
  }

  return (
    <div className="insights-component">
    <div className="bold piechart-header">Most Common Discussion Topics</div>
      <Chart
        height={"13vh"}
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={getPieChartData().data}
        options={{
          title: "Most Common Discussion Topics",
          is3D: true,
          slices: getPieChartData().colors,
          chartArea: { width: '90%', height: '100%' },
          legend: { position: 'out' },
          titlePosition: 'out', axisTitlesPosition: 'in',
          hAxis: { textPosition: 'in' }, vAxis: { textPosition: 'in' },
        }}
        rootProps={{ "data-testid": "2" }}
      />
    </div>
  );
});

const mapStateToProps = state => {
  const { topicFrequenciesData: { topicFrequencies }, patientMetadata: { patientPage: { currentPatient } } } = state;
  let currentFrequencies = topicFrequencies[currentPatient];
  return { currentFrequencies };
};

export default connect(
  mapStateToProps,
  null
)(MLInsights);

