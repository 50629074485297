import {
  FETCH_CLINICS_REQUEST,
  FETCH_CLINICS_SUCCESS,
  FETCH_CLINICS_ERROR
} from "./actions";

export default (
  state = {
    clinics: [],
    isFetching: false,
    error: null
  },
  action
) => {
  switch (action.type) {
    case FETCH_CLINICS_REQUEST:
      return { ...state, error: null, isFetching: true };
    case FETCH_CLINICS_SUCCESS:
      return {
        ...state,
        clinics: action.payload,
        isFetching: false
      };
    case FETCH_CLINICS_ERROR:
      return { ...state, error: action.payload, isFetching: false };
    default:
      return state;
  }
};
