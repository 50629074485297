import { getTopicFrequencies } from "./util";

export const FETCH_FREQUENCIES_REQUEST = "fetchTopicFrequenciesRequest";
export const FETCH_FREQUENCIES_SUCCESS = "fetchTopicFrequenciesSucess";
export const FETCH_FREQUENCIES_ERROR = "fetchTopicFrequenciesError";

const fetchRequest = () => {
  return {
    type: FETCH_FREQUENCIES_REQUEST
  };
};

const fetchSuccess = ({id, topicFrequencies}) => {
  return {
    type: FETCH_FREQUENCIES_SUCCESS,
    payload: { id, topicFrequencies }
  };
};

const fetchError = error => {
  return {
    type: FETCH_FREQUENCIES_ERROR,
    payload: error
  };
};

export const fetchTopicFrequencies = id => async dispatch => {
  dispatch(fetchRequest());
  try {
    let result = await getTopicFrequencies(id);
    let topicFrequencies = result.data;
    if (typeof topicFrequencies === "string") {
      topicFrequencies = JSON.parse(topicFrequencies);
    }
    dispatch(fetchSuccess({id, topicFrequencies}));
  } catch (e) {
    console.log(e);
    dispatch(fetchError(e));
  }
};
