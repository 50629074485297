import React from "react";

import TasksContainer from "./TasksContainer";
import TabsContainer from "../patient_preview/TabsContainer";
import SidebarDashboard from "../SidebarDashboard";

const Members = React.memo(function Members(props) {
  return (
    <div className="container-main">
      <SidebarDashboard />
      <div className="row-main members">
        <div className="col-main menu">
          <TasksContainer />
        </div>
        <div className="col-main patient-data">
          <TabsContainer />
        </div>
      </div>
    </div>
  );
});

export default Members;
