import React from "react";
import { connect } from "react-redux";
import moment from 'moment';
import { CustomInput, Form, FormGroup, Label, Input, NavLink } from "reactstrap";

import DatePickerComponent from '../shared_components/DatePickerComponent';
import { setStartDate, setEndDate, setShowPDF, setSoapDate, setSelectDataFeed, setSelectBP, setSelectBG, setSelectPulse } from '../../redux/pdf_options/actions';
import { fetchEncounters } from '../../redux/encounters_for_pdf/actions'

import "./PDFoptions.css";


const PDFoptions = React.memo(function PDFoptions(props) {
  const { setStartDate, setEndDate, setShowPDF, setSoapDate, soapDates, setPdfPopoverOpen, showDataFeed, setSelectDataFeed, showBP, showBG, showPulse, setSelectBP, setSelectBG, setSelectPulse } = props;
  let recentSoapDates = soapDates.slice(0, 3);
  return (
    <div>
      <div className="pdf-options">
        <div className="date-container">
          <div id="date-range-legend">Date Range</div>
          <div className="from-and-to-daterange">
            From:
            <DatePickerComponent
              selectedDate={moment(new Date()).subtract(30, "days")}
              onDateSelect={date => {
                setStartDate(date);
              }}
            />
            To:
            <DatePickerComponent
              selectedDate={moment(new Date())}
              onDateSelect={date => {
                setEndDate(date);
              }}
            />
          </div>
        </div>
        <Form>
          <FormGroup>
            <div>
              <CustomInput
                type="checkbox"
                id="biometricsCheckboxBP"
                label="Blood Pressure"
                checked={showBP}
                onChange={() => setSelectBP(!showBP)}
              />
              <CustomInput
                type="checkbox"
                id="biometricsCheckboxBG"
                label="Blood Glucose"
                checked={showBG}
                onChange={() => setSelectBG(!showBG)}
              />
              <CustomInput
                type="checkbox"
                id="biometricsCheckboxPulse"
                label="Heart Rate"
                checked={showPulse}
                onChange={() => setSelectPulse(!showPulse)}
              />
              <CustomInput
                type="checkbox"
                id="dataFeedCheckbox"
                label="Include data feed"
                checked={showDataFeed}
                onChange={() => setSelectDataFeed(!showDataFeed)}
              />
              <FormGroup tag="fieldset">
                <legend className="soap-legend">SOAP Notes</legend>
                {recentSoapDates.map(date => {
                  return (
                    <FormGroup check key={date}>
                      <Label check>
                        <Input
                          type="radio"
                          name="soapOptions"
                          value={date}
                          onChange={e => setSoapDate(e.target.value)}
                        />
                        {moment(Number(date))
                          .utc()
                          .format("MMM Do YYYY")}{" "}
                      </Label>
                    </FormGroup>
                  );
                })}
              </FormGroup>
            </div>
          </FormGroup>
        </Form>
      </div>
      <button
        onClick={() => {
          setShowPDF(true);
          setPdfPopoverOpen(false);
        }}
      >
        <NavLink to="/pdf">Generate PDF</NavLink>
      </button>
      <div></div>
    </div>
  );
});

const mapStateToProps = state => {
  const {
    patientBioData: { bios },
    patientMetadata: { patientPage: {currentPatient} },
    pdfOptions: { showDataFeed,  showBP, showBG, showPulse },
    soapNotesData: {soapNotes}
  } = state;
  const patientObj = bios[currentPatient];
  const soapDates = Object.keys(soapNotes[currentPatient])
  return {
    patientObj,
    currentPatient,
    showBP,
    showBG,
    showPulse,
    showDataFeed,
    soapDates
  };
};

const mapActionsToProps = { setStartDate, setEndDate, setShowPDF, fetchEncounters, setSoapDate, setSelectDataFeed, setSelectBP, setSelectBG, setSelectPulse }

export default connect(
  mapStateToProps,
  mapActionsToProps
)(PDFoptions);

