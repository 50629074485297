import React from 'react';
import ScaleLoader from "react-spinners/ScaleLoader";

export const trackerNoteSpinner = () => {
  return (
    <div className="tracker-notes-loader">
      <ScaleLoader
        height={25}
        width={2}
        radius={2}
        margin={2}
        color={"#AAB3B1"}
        loading={true}
      />
    </div>
  );
}

export const Spinner = () => {
  return (
    <div className="tabs-container">
      <div className="loading-widget">
        <ScaleLoader
          height={35}
          width={5}
          radius={2}
          margin={2}
          color={"#AAB3B1"}
          loading={true}
        />
      </div>
    </div>
  );
}

export const alertsListSpinner = () => {
  return (
    <div className="alerts-loader">
      <ScaleLoader
        height={35}
        width={5}
        radius={2}
        margin={2}
        color={"#AAB3B1"}
        loading={true}
      />
    </div>
  );
}