import React, { useState } from "react";
import classnames from "classnames";
import { Row, Col, Input, Button, FormText } from "reactstrap";
import axios from "axios";
import applyCaseMiddleware from "axios-case-converter";
import { baseURL } from "../../config";
import { connect } from 'react-redux';
import { updatePatientData } from '../../redux/patient_bios/actions';
import { setShowNotification, setNotificationMessage } from "../../redux/notifications/actions";

import "./EditContactInfo.css";

const EditContactInfo = React.memo(function EditContactInfo(props) {

  const { patientObj, updatePatientData, setPopoverOpen, setShowNotification, setNotificationMessage } = props;
  const {
    patientId,
    phoneNumberMobile,
    phoneNumberHome,
    email,
    caretakerRelationship,
    caretakerFirstName,
    caretakerLastName,
    caretakerPhone,
    streetAddress,
    city,
    state,
    zipCode,
    primaryInsuranceType,
    primaryInsuranceMemberId,
    primaryInsuranceGroupId,
    secondaryInsuranceType,
    secondaryInsuranceMemberId,
    secondaryInsuranceGroupId,
    emergencyFirst,
    emergencyLast,
    emergencyPhone
  } = patientObj;

  const [contactData, setContactData] = useState({
    phoneNumberHome,
    phoneNumberMobile,
    email,
    caretakerRelationship,
    caretakerFirstName,
    caretakerLastName,
    caretakerPhone,
    streetAddress,
    city,
    state,
    zipCode,
    primaryInsuranceType,
    primaryInsuranceMemberId,
    primaryInsuranceGroupId,
    secondaryInsuranceType,
    secondaryInsuranceMemberId,
    secondaryInsuranceGroupId,
    emergencyFirst,
    emergencyLast,
    emergencyPhone
  });

  const saveUpdate = async () => {
    const client = applyCaseMiddleware(axios.create());
    const {
      phoneNumberHome,
      phoneNumberMobile,
      email,
      caretakerRelationship,
      caretakerFirstName,
      caretakerLastName,
      caretakerPhone,
      streetAddress,
      city,
      state,
      zipCode,
      primaryInsuranceType,
      primaryInsuranceMemberId,
      primaryInsuranceGroupId,
      secondaryInsuranceType,
      secondaryInsuranceMemberId,
      secondaryInsuranceGroupId,
      emergencyFirst,
      emergencyLast,
      emergencyPhone
    } = contactData

    let response = await  client({
      method: "POST",
      url: `/update_patient_data`,
      baseURL,
      params: {
        patientId,
        phoneNumberHome,
        phoneNumberMobile,
        email,
        caretakerRelationship,
        caretakerFirstName,
        caretakerLastName,
        caretakerPhone,
        streetAddress,
        city,
        state,
        zip: zipCode,
        primaryInsuranceType,
        primaryInsuranceMemberId,
        primaryInsuranceGroupId,
        secondaryInsuranceType,
        secondaryInsuranceMemberId,
        secondaryInsuranceGroupId,
        emergencyFirst,
        emergencyLast,
        emergencyPhone
      },
      withCredentials: true
    })
    console.log(response)
    if (response.status === 200) {
      console.log('200')
      setShowNotification(true);
      setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 3000)
      setNotificationMessage({ text: "Your data has been saved!", type: "success", isFor: 'topRightPlan' });
    } else{
      console.log(response.data)
      setShowNotification(true);
      setTimeout(() => { setShowNotification(false); setNotificationMessage({}); }, 2000)
      setNotificationMessage({ text: "Could not be saved at this time", type: "danger", isFor: 'topRightPlan' });
  }
  };

  return (
    <>
      <div
        className={classnames(
          {
            "active-member": patientObj.minutes > -1,
            "churned-member": patientObj.minutes === -1
          },
          "bio-box-header"
        )}
      >
        {patientObj.minutes > -1 ? "Edit" : "Churned"}
      </div>
      <form onSubmit={() => console.log("Submitted")}>
        <Row className="pt-contact-wrapper">
          <Col className="pt-contact-col">
            <div className="bio-box pt-contact">
              <div className="contact-info-title">Phone</div>
              <div className="contact-info-value">
                Home:
                <Input
                  type="text"
                  className="edit-contact-input left-margin"
                  placeholder="home phone"
                  value={contactData.phoneNumberHome}
                  onChange={e =>
                    setContactData({
                      ...contactData,
                          phoneNumberHome: e.target.value
                    })
                  }
                />
              </div>
              <div className="contact-info-value">
                Mobile:
                <Input
                  type="text"
                  className="edit-contact-input left-margin"
                  placeholder="mobile phone"
                  value={contactData.phoneNumberMobile}
                  onChange={e =>
                    setContactData({
                      ...contactData,
                      phoneNumberMobile: e.target.value
                    })
                  }
                />
              </div>
            </div>
            <div className="bio-box pt-contact">
              <div className="contact-info-title">Email</div>
              <div className="contact-info-value">
                <Input
                  type="email"
                  className="edit-contact-input"
                  placeholder="email"
                  value={contactData.email || ""}
                  onChange={e =>
                    setContactData({ ...contactData, email: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="bio-box pt-contact">
              <div className="contact-info-title">Caretaker</div>
              <div className="contact-info-value" style={{flexDirection: 'column'}}>
                <FormText color="muted">relationship</FormText>
                <Input
                  type="text"
                  className="edit-contact-input"
                  placeholder="relationship"
                  value={contactData.caretakerRelationship}
                  onChange={e =>
                    setContactData({
                      ...contactData,
                      caretakerRelationship: e.target.value
                    })
                  }
                />

                <FormText color="muted">first name</FormText>
                <Input
                  type="text"
                  className="edit-contact-input"
                  placeholder="name"
                  value={contactData.caretakerFirstName}
                  onChange={e =>
                    setContactData({
                      ...contactData,
                      caretakerFirstName: e.target.value
                    })
                  }
                />

                <FormText color="muted">last name</FormText>
                <Input
                  type="text"
                  className="edit-contact-input"
                  placeholder="name"
                  value={contactData.caretakerLastName}
                  onChange={e =>
                    setContactData({
                      ...contactData,
                      caretakerLastName: e.target.value
                    })
                  }
                />
              </div>
              <div className="contact-info-value">
                Phone:{" "}
                <Input
                  type="text"
                  className="edit-contact-input left-margin"
                  placeholder="phone"
                  value={contactData.caretakerPhone}
                  onChange={e =>
                    setContactData({
                      ...contactData,
                      caretakerPhone: e.target.value
                    })
                  }
                />
              </div>
            </div>
            {/* emergency contact start */}
            <div className="bio-box pt-contact">
              <div className="contact-info-title">Emergency Contact</div>
              <div className="contact-info-value" style={{ flexDirection: 'column' }}>

                <FormText color="muted">first name</FormText>
                <Input
                  type="text"
                  className="edit-contact-input"
                  placeholder="name"
                  value={contactData.emergencyFirst}
                  onChange={e =>
                    setContactData({
                      ...contactData,
                      emergencyFirst: e.target.value
                    })
                  }
                />

                <FormText color="muted">last name</FormText>
                <Input
                  type="text"
                  className="edit-contact-input"
                  placeholder="name"
                  value={contactData.emergencyLast}
                  onChange={e =>
                    setContactData({
                      ...contactData,
                      emergencyLast: e.target.value
                    })
                  }
                />
              </div>
              <div className="contact-info-value">
                Phone:{" "}
                <Input
                  type="text"
                  className="edit-contact-input left-margin"
                  placeholder="phone"
                  value={contactData.emergencyPhone}
                  onChange={e =>
                    setContactData({
                      ...contactData,
                      emergencyPhone: e.target.value
                    })
                  }
                />
              </div>
            </div>
            {/* emergency contact end */}
          </Col>
          <Col className="pt-contact-col">
            <div className="bio-box pt-contact">
              <div className="contact-info-title">Address</div>
              <div
                className="contact-info-value"
                style={{ flexDirection: "column" }}
              >
                <FormText color="muted">street address</FormText>
                <Input
                  type="text"
                  className="edit-contact-input"
                  placeholder="street address"
                  value={contactData.streetAddress}
                  onChange={e =>
                    setContactData({
                      ...contactData,
                      streetAddress: e.target.value
                    })
                  }
                />

                <FormText color="muted">city</FormText>
                <Input
                  type="text"
                  className="edit-contact-input"
                  placeholder="city"
                  value={contactData.city}
                  onChange={e =>
                    setContactData({ ...contactData, city: e.target.value })
                  }
                />

                <FormText color="muted">state</FormText>
                <Input
                  type="text"
                  className="edit-contact-input"
                  placeholder="state"
                  value={contactData.state}
                  onChange={e =>
                    setContactData({ ...contactData, state: e.target.value })
                  }
                />

                <FormText color="muted">zip code</FormText>
                <Input
                  type="text"
                  className="edit-contact-input"
                  placeholder="zip code"
                  value={contactData.zipCode}
                  onChange={e =>
                    setContactData({ ...contactData, zipCode: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="bio-box pt-contact">
              <div className="contact-info-title">Primary Insurance</div>
              <div className="contact-info-value">
                <Input
                  type="text"
                  className="edit-contact-input"
                  placeholder="type"
                  value={contactData.primaryInsuranceType}
                  onChange={e =>
                    setContactData({
                      ...contactData,
                      primaryInsuranceType: e.target.value
                    })
                  }
                />
              </div>
              <div className="contact-info-value">
                Member ID:
                <Input
                  type="text"
                  className="edit-contact-input left-margin"
                  placeholder="member ID"
                  value={contactData.primaryInsuranceMemberId}
                  onChange={e =>
                    setContactData({
                      ...contactData,
                      primaryInsuranceMemberId: e.target.value
                    })
                  }
                />
              </div>
              <div className="contact-info-value">
                Group ID:
                <Input
                  type="text"
                  className="edit-contact-input left-margin"
                  placeholder="group ID"
                  value={contactData.primaryInsuranceGroupId}
                  onChange={e =>
                    setContactData({
                      ...contactData,
                      primaryInsuranceGroupId: e.target.value
                    })
                  }
                />
              </div>
            </div>
            <div className="bio-box pt-contact">
              <div className="contact-info-title">Secondary Insurance</div>
              <div className="contact-info-value">
                <Input
                  type="text"
                  className="edit-contact-input"
                  placeholder="type"
                  value={contactData.secondaryInsuranceType}
                  onChange={e =>
                    setContactData({
                      ...contactData,
                      secondaryInsuranceType: e.target.value
                    })
                  }
                />
              </div>
              <div className="contact-info-value">
                Member ID:
                <Input
                  type="text"
                  className="edit-contact-input left-margin"
                  placeholder="member ID"
                  value={contactData.secondaryInsuranceMemberId}
                  onChange={e =>
                    setContactData({
                      ...contactData,
                      secondaryInsuranceMemberId: e.target.value
                    })
                  }
                />
              </div>
              <div className="contact-info-value">
                Group ID:
                <Input
                  type="text"
                  className="edit-contact-input left-margin"
                  placeholder="group ID"
                  value={contactData.secondaryInsuranceGroupId}
                  onChange={e =>
                    setContactData({
                      ...contactData,
                      secondaryInsuranceGroupId: e.target.value
                    })
                  }
                />
              </div>
            </div>
          </Col>
        </Row>
      </form>
      <div className="save-button-wrapper">
        <Button className="save-contact-btn" onClick={() => {saveUpdate(); setPopoverOpen(false); updatePatientData(patientId, contactData)}}>Save</Button>
      </div>
    </>
  );
});

const mapActionToProps = {
  updatePatientData, setShowNotification, setNotificationMessage
};

export default connect(
  null,
  mapActionToProps
)(EditContactInfo);
