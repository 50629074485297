import {
  FETCH_TASKS_REQUEST,
  FETCH_TASKS_SUCCESS,
  FETCH_TASKS_ERROR,
  DELETE_TASK,
  DEESCALATE_TASK
} from "./actions";

export default (
  state = {
    tasks: [],
    totalCount: 0,
    isFetching: false,
    error: null
  },
  action
) => {
  let currentTasks = state.tasks
  let newTasks = []
  switch (action.type) {
    case FETCH_TASKS_REQUEST:
      return { ...state, error: null, isFetching: true };
    case FETCH_TASKS_SUCCESS:
      return {
        ...state,
        tasks: action.payload.tasks,
        totalCount:
          action.payload.total_count > 0 || (action.payload.total_count === 0 && action.payload.tasks.length === 0)
            ? action.payload.total_count
            : state.totalCount,
        isFetching: false
      };
    case FETCH_TASKS_ERROR:
      return { ...state, error: action.payload, isFetching: false };
    case DELETE_TASK:
      for (let task of currentTasks) {
        if (task.patient_id !== action.payload) {
          newTasks.push(task)
        }
      }
      return { ...state, tasks: newTasks, totalCount: state.totalCount-1 };
    case DEESCALATE_TASK:
      for (let task of currentTasks) {
        if (task.patient_id !== action.payload) {
          newTasks.push(task)
        } else {
          let newTask = {...task};
          delete newTask.escalated_to;
          newTasks.push(newTask);
        }
      }
      return { ...state, tasks: newTasks };
    default:
      return state;
  }
};
