import React from "react";

import MembersContainer from "./MembersContainer";
import TabsContainer from "../patient_preview/TabsContainer";
import SidebarDashboard from '../SidebarDashboard';

// import "./App.css";

const Members = React.memo(function Members(props) {
  return (
    <div className="container-main">
      <SidebarDashboard />
      <div className="row-main members">
        <div className="col-main menu">
          <MembersContainer />
        </div>
        <div className="col-main patient-data">
          <TabsContainer />
        </div>
      </div>
    </div>
  );
});

export default Members;
