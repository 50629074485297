import axios from "axios";
import { baseURL } from "../../config";

export const getPatientAlertHistory = async (id, metric, start, end) => {
  let url = start ? `/patient_activity_history?id=${id}&metric=${metric}&start=${start}&end=${end}` : `/patient_activity_history?id=${id}&metric=${metric}`
  return axios({
    method: "GET",
    url,
    baseURL
  });
}
