import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Popover, PopoverBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

const TasksFilter = React.memo(function TasksFilter(props) {
  const { getParams, totalTasksCount } = props

  const [type, setType] = useState([]);
  const [checkedBoxes, setCheckedboxes] = useState({})

  const [isOpen, setIsOpen] = useState(false);
  const filterIconColor = isOpen ? "#2195F3" : "#7A8EAB";

  useEffect(() => {
    getParams({ task_type: type })
  }, [type]);

  const handleChange = (state, setState, e) => {
    const item = e.target.value;
    const isChecked = e.target.checked
    setCheckedboxes({ ...checkedBoxes, [item]: isChecked })
    handleAddDeleteFromState(state, setState, isChecked, item)
  }

  const handleAddDeleteFromState = (state, setState, isActive, stateValToAddRemove) => {
    if (isActive) {
      setState([...state, stateValToAddRemove])
    } else {
      let tempState = [...state];
      let index = tempState.indexOf(stateValToAddRemove);
      if (index !== -1) {
        tempState.splice(index, 1);
        setState(tempState)
      }
    }
  }

  const clearAllCheckboxes = () => {
    setCheckedboxes({});
    setType([]);
  }


  const typeCheckBoxes = [
    {
      value: "claimed_alert",
      key: "claimed_alert",
      label: "Claimed Alert"
    },
    {
      value: "claimed_patient",
      key: "claimed_patient",
      label: "Claimed Patient"
    },
    {
      value: "incomplete",
      key: "incomplete",
      label: "Incomplete Encounter"
    },
  ];

  const typeCheckBoxesToRender = () => {
    return typeCheckBoxes.map(item => {
      return (
        <div className="checkbox" key={item.key}>
          <label>
            <input
              type="checkbox"
              value={item.value}
              checked={checkedBoxes[item.value]}
              onChange={e => handleChange(type, setType, e)}
            />
            <span className="checkbox-label">{item.label}</span>
          </label>
        </div>
      );
    });
  }

  return (
    <React.Fragment>
      <div className="alerts-header">
        <div className="header-left">
          <div className="header-title"> Tasks </div>
          <div className="alerts-count-wrapper">{totalTasksCount}</div>
        </div>
        <div id="TasksFilterPopover" className="filter-button-wrapper">
          <FontAwesomeIcon icon={faFilter} style={{ fontSize: "16px", color: filterIconColor }} />
        </div>
        <Popover trigger="legacy" placement="bottom" target="TasksFilterPopover" hideArrow={true} toggle={()=> setIsOpen(!isOpen)} isOpen={isOpen}>
          <PopoverBody className="filter-popover-body">
            <div className="card card-body">
              <div className="criteria-header-container">
                <div className="criteria-header-row">
                  <div className="criteria-header status-header">Task Type</div>
                </div>
                <div className="criteria-header-row">
                  <div
                    className="criteria-header clear-filters-header"
                    onClick={() => {
                      clearAllCheckboxes();
                    }}
                  >
                    Clear Filters
              </div>
                </div>
              </div>
              <div className="all-options-container">
                <div className="criteria-container">
                  {typeCheckBoxesToRender()}
                </div>
              </div>
            </div>
          </PopoverBody>
        </Popover>
      </div>

    </React.Fragment>
  );
});


const mapStateToProps = state => {
  const {
    tasksData: { totalCount }
  } = state;
  return {
    totalTasksCount: totalCount
  };
};

export default connect(
  mapStateToProps,
  null
)(TasksFilter);
