import { SET_NOTE_TEXT, SET_IS_TN_UPDATE, SET_TN_ID, SET_SOAP_NOTE_TEXT, SET_SOAP_NOTE_ID, SET_IS_SOAP_NOTE_UPDATE } from "./actions";


export default (
  state = {},
  action
) => {
  switch (action.type) {
    case SET_NOTE_TEXT:
      return { ...state, [action.payload.patientId]: {...state[action.payload.patientId], text: action.payload.text} };
    case SET_SOAP_NOTE_TEXT:
      return { ...state, [action.payload.patientId]: { ...state[action.payload.patientId], soapNoteText: action.payload.text } };
    case SET_TN_ID:
      return {
        ...state,
        [action.payload.patientId]: {
          ...state[action.payload.patientId],
          tnId: action.payload.tnId,
          duration: action.payload.duration
        }
      };
    case SET_SOAP_NOTE_ID:
      return { ...state, [action.payload.patientId]: { ...state[action.payload.patientId], soapNoteId: action.payload.soapNoteId } };
    case SET_IS_TN_UPDATE:
      return { ...state, [action.payload.patientId]: { ...state[action.payload.patientId], isTnUpdate: action.payload.isTnUpdate } };
    case SET_IS_SOAP_NOTE_UPDATE:
      return { ...state, [action.payload.patientId]: { ...state[action.payload.patientId], isSoapNoteUpdate: action.payload.isSoapNoteUpdate } };
  default:
    return state;
  }
};
