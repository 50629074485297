import { getChartData } from './util'

export const FETCH_CHART_REQUEST = "fetchChartRequest";
export const FETCH_CHART_DATA_SUCCESS = "fetchChartDataSuccess";
export const FETCH_CHART_ERROR = "fetchChartError";
export const SET_IS_CHART_HIDDEN = "setIsChartHidden";

const fetchRequest = () => {
  return {
    type: FETCH_CHART_REQUEST
  };
};

const fetchSuccess = chartData => {
  return {
    type: FETCH_CHART_DATA_SUCCESS,
    payload: chartData
  };
};

const fetchError = error => {
  return {
    type: FETCH_CHART_ERROR,
    payload: error
  };
};

export const fetchChartData = (id, metric) => async dispatch => {
  dispatch(fetchRequest());
  try {
    const chartData = await getChartData(id, metric);
    let data = chartData.data;
    if (typeof data === "string") {
      data = JSON.parse(data);
    }
    dispatch(fetchSuccess({id, metric, series: data}));
    return data;
  } catch (error) {
    console.log(error);
    dispatch(fetchError(error));
  }
};

export const setIsChartHidden = bool => dispatch => {
  dispatch({
    type: SET_IS_CHART_HIDDEN,
    payload: bool
  });
};

