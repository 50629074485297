import {
  SET_NOTES_TEXT,
  SET_NOTES_TEXT_BRAIN
} from './actions'

export default (
  state = {
    notesText: '',
    notesTextBrain: ''
  }, action) => {
  switch (action.type) {
    case SET_NOTES_TEXT:
      return { ...state, notesText: action.payload };
    case SET_NOTES_TEXT_BRAIN:
      return { ...state, notesTextBrain: action.payload };
    default:
      return state;
  }
};