import React, { useState, createRef } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTachometerAlt, faTh, faStar, faPencilAlt, faTimes, faPills } from "@fortawesome/free-solid-svg-icons";
import { faClipboard, faHospital, faFolderOpen } from "@fortawesome/free-regular-svg-icons";
import { Popover, PopoverBody, Table } from "reactstrap";
import Biometrics from './Biometrics';
import MLInsights from './MLInsights';
import StickyNotesCard from "./StickyNotesCard";
import EncounterNotes from './EncounterNotes';
import Documents from './Documents';
import Notepad from "./Notepad";
import { weightHeightBox, minutesBox } from "../patient_preview/PatientBio";
import PDFtoSend from '../sign_screen/PDFtoSend';
import "./OverviewContainer.css";
import DownloadPDF from '../sign_screen/DownloadPDF';
import { setShowPDF } from '../../redux/pdf_options/actions';
import AlertsHistory from "../shared_components/alerts_history/AlertsHistory";
import PrimaryConditionsEdit from './PrimaryConditionsEdit';
import EditDoctorModal from './EditDoctorModal';
import MedicationsComponent from "./MedicationsComponent";


export const doctorContactInfo = (patientObj) => {
  const { doctorInfo, providers } = patientObj
  return (
    <Table className="doc-contact borderless plan-page">
      <tbody>
        <tr>
          <td className="headers">PCP</td>
          <td className="headers">Clinic</td>
        </tr>
        <tr className="b-bottom">
          <td className="values b-left">
            {patientObj["doctorInfo"] && doctorInfo.doctorName
              ? doctorInfo.doctorName
              : "-"}
          </td>
          <td className="values">
            {patientObj["doctorInfo"] && doctorInfo.clinic
              ? doctorInfo.clinic
              : "-"}
          </td>
        </tr>
        <tr>
          <td className="headers b-left">Phone</td>
          <td className="headers">Fax</td>
        </tr>
        <tr className="b-bottom">
          <td className="values b-left">
            {patientObj["doctorInfo"] && doctorInfo.phone
              ? doctorInfo.phone
              : "-"}
          </td>
          <td className="values">
            {patientObj["doctorInfo"] && doctorInfo.fax
              ? doctorInfo.fax
              : "-"}{" "}
          </td>
        </tr>
        {patientObj["providers"] && providers.length > 0 &&
        <>
        <tr>
          <td className="headers b-left">Doctor I</td>
          <td className="headers">Clinic I</td>
        </tr>
        <tr className="b-bottom">
          <td className="values b-left">
              {providers[0].doctorName}
          </td>
          <td className="values">
              {providers[0].clinic}
          </td>
        </tr>
        <tr>
          <td className="headers b-left">Phone I</td>
          <td className="headers">Fax I</td>
        </tr>
          <tr className="b-bottom">
          <td className="values b-left">
            {providers[0].phone}
          </td>
          <td className="values">
            {providers[0].fax}
          </td>
        </tr>
        </>}
        {/* doctor II */}
        {patientObj["providers"] && providers.length > 1 &&
        <>
        <tr>
          <td className="headers b-left">Doctor II</td>
          <td className="headers">Clinic II</td>
        </tr>
        <tr className="b-bottom">
          <td className="values b-left">
            {providers[1].doctorName}
          </td>
          <td className="values">
            {providers[1].clinic}
          </td>
        </tr>
        <tr>
          <td className="headers b-left">Phone II</td>
          <td className="headers">Fax II</td>
        </tr>
        <tr>
          <td className="values">
            {providers[1].phone}
          </td>
          <td className="values">
            {providers[1].fax}
          </td>
        </tr>
        </>}
        {/* end doctor II */}
      </tbody>
    </Table>
  );
}


const OverviewContainer = React.memo(function OverviewContainer(props) {
  const { patientObj, showPDF, setShowPDF, pagesCount } = props;
  const { patientId } = patientObj;
  const [activeTab, setActiveTab] = useState('all');
  const [tabNamesOpen, setTabNamesOpen] = useState({ medhistory: false, biometrics: false, tNotes: false, documents: false });
  const [pcEditMode, setPcEditMode ] = useState(false);
  const [medsEditMode, setMedsEditMode] = useState(false);
  const [doctorModalOpen, setDoctorModalOpen] = useState(false);
  const toggleDoctorModal = () => setDoctorModalOpen(!doctorModalOpen);

  let refsArr = [];
  for (let i = 0; i < pagesCount; i++) {
    refsArr.push(createRef());
  }

  const tabNamePopover = ({ isOpen, name, target }) => {
    return (
      <Popover
        isOpen={isOpen}
        placement="right"
        target={target}
        hideArrow={true}
      >
        <PopoverBody className="tab-name-popover">{name}</PopoverBody>
      </Popover>
    );
  };

  const tabsArr = [
    { name: "basicInfo", icon: faTh, id: "med-history-popover-2", inState: "medhistory", tabName: 'Medical History' },
    { name: "biometrics", icon: faTachometerAlt, id: "biometrics-popover-2", inState: "biometrics", tabName: 'Biometrics' },
    { name: "encounters", icon: faClipboard, id: "t-notes-popover-2", inState: "tNotes", tabName: 'Encounter Notes' },
    { name: "documents", icon: faFolderOpen, id: "documents-popover-2", inState: "documents", tabName: 'Documents' }
  ];

  const pdfPreview = () => {
    return (
      <div className="pdf-and-controls">
        <div className="pdf-controls-wrapper">
          <DownloadPDF
            id={"pdf-summary"}
            label={"Download"}
            patientObj={patientObj}
            refs={refsArr}
          />
          <button
            className="close-pdf-button"
            onClick={() => setShowPDF(false)}
          >
            Close
          </button>
        </div>
        <PDFtoSend refs={refsArr}/>
      </div>
    );
  }

  const convertListToStr = (arr) => {
    if (!arr) {
      return null;
    }
    let result = '';
    for (let i = 0; i < arr.length; i++) {
      result += (i < arr.length - 1) ? arr[i] + ", " : arr[i]
    }
    return result;
  }

  return (
    <>
      {showPDF ? (
        pdfPreview()
      ) : (
        <div className="overview-container-wrapper full">
          <div className="overview-nav">
            {tabsArr.map(tab => {
              return (
                <div
                  className={classnames(
                    { active: activeTab === tab.name },
                    "custom-tab"
                  )}
                  onClick={() => setActiveTab(tab.name)}
                  key={tab.name}
                  id={tab.id}
                  style={{
                    marginBottom:
                      tab.id === "documents-popover-2" ? "0em" : "2.5em"
                  }}
                  onMouseEnter={() =>
                    setTabNamesOpen({ ...tabNamesOpen, [tab.inState]: true })
                  }
                  onMouseLeave={() =>
                    setTabNamesOpen({ ...tabNamesOpen, [tab.inState]: false })
                  }
                >
                  <div className="side-menu-pt-icon">
                    <FontAwesomeIcon icon={tab.icon} />
                  </div>
                  {tabNamePopover({
                    isOpen: tabNamesOpen[tab.inState],
                    name: tab.tabName,
                    target: tab.id
                  })}
                </div>
              );
            })}
          </div>
          {activeTab === "all" || activeTab === "basicInfo" ? (
            <div
              className={classnames({
                "overview-left": activeTab === "all",
                "full-screen-basic": activeTab === "basicInfo"
              })}
            >
              {/* moved the two bio boxed to the biometrics side */}
              <div className="icon-and-component">
                <div className="icon-styled-pt">
                  <FontAwesomeIcon icon={faStar} />
                </div>
                <MLInsights />
              </div>

                <div className="icon-and-component" style={{marginTop: "15px"}}>
                  <div className="icon-styled-pt">
                    <FontAwesomeIcon icon={faStar} />
                  </div>
                  <StickyNotesCard patientId={patientId} stickyNotes={patientObj["stickyNotes"] ? patientObj.stickyNotes : ""} otherNotes={patientObj["otherNotes"] ? patientObj.otherNotes : ""}/>
                </div>

              <div className="conditions-and-contact-row plan-page">
                <div className="icon-and-component" style={{ flex: "3" }}>
                  <div
                    className="icon-styled-pt"
                    style={{ marginRight: "15px" }}
                  >
                    <FontAwesomeIcon icon={faClipboard} />
                  </div>
                  <div className="primary-cond-container no-margin-x">
                    <div className="cond-header-and-icon">
                      <div className="conditions-header">
                        Primary Conditions
                      </div>
                      <div className="pencil-icon-wrapper">
                        {!pcEditMode ? (
                          <FontAwesomeIcon
                            icon={faPencilAlt}
                            onClick={() => setPcEditMode(true)}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faTimes}
                            onClick={() => setPcEditMode(false)}
                          />
                        )}
                      </div>
                    </div>
                    <div className="conditions-list">
                      {pcEditMode ? (
                        <PrimaryConditionsEdit
                          pcArr={
                            patientObj.primaryConditions
                              ? patientObj.primaryConditions
                              : []
                          }
                          patientId={patientId}
                          setPcEditMode={bool => setPcEditMode(bool)}
                        />
                      ) : (
                        convertListToStr(patientObj.primaryConditions)
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="icon-and-component inner-icon"
                  style={{ flex: "5" }}
                >
                  <div className="icon-styled-pt">
                    <FontAwesomeIcon icon={faHospital} />
                  </div>
                  <div className="doctor-contact-wrapper">
                    <div className="pencil-icon-wrapper doctor-info">
                      <FontAwesomeIcon
                        icon={faPencilAlt}
                        onClick={() => setDoctorModalOpen(true)}
                      />
                    </div>
                    {/* not a part of the default display */}
                    <EditDoctorModal
                      doctorModalOpen={doctorModalOpen}
                      patientId={patientId}
                      toggleDoctorModal={toggleDoctorModal}
                    />
                    {/* end doctor modal */}
                    {doctorContactInfo(patientObj)}
                  </div>
                </div>
              </div>

              <div className="conditions-and-contact-row plan-page">
                <div className="icon-and-component" style={{ flex: "3" }}></div>

                <div className="icon-and-component" style={{ flex: "5" }}>
                  <div
                    className="icon-styled-pt"
                    style={{ marginRight: "15px" }}
                  >
                    <FontAwesomeIcon icon={faPills} />
                  </div>
                  <MedicationsComponent medsEditMode={medsEditMode} setMedsEditMode={setMedsEditMode} patientObj={patientObj} />

                </div>
              </div>
            </div>
          ) : null}
          <div
            className={classnames({
              "overview-right": activeTab === "all",
              "full-screen-view":
                activeTab === "biometrics" || activeTab === "encounters" || activeTab === "documents"
            })}
          >
            {activeTab === "all" || activeTab === "biometrics" ? (
              <>
                <div className="overview-top-row">
                  {minutesBox(patientObj)}
                  {weightHeightBox(patientObj)}
                </div>
                <div className="icon-and-component margin-top-15">
                  <div className="icon-styled-pt">
                    <FontAwesomeIcon icon={faTachometerAlt} />
                  </div>
                  <Biometrics
                    currentPatient={patientId}
                    view={activeTab === "all" ? "halfScreen" : "fullScreen"}
                  />
                </div>
              </>
            ) : null}
            {/* removed the notes from the preview */}
            {activeTab === "encounters" ? (
              <EncounterNotes
                currentPatient={patientId}
                view={activeTab === "all" ? "halfScreen" : "fullScreen"}
              />
            ) : null}
            {activeTab === "documents" ? (
              <Documents currentPatient={patientId} />
            ) : null}
          </div>
        </div>
      )}
      <div className="notepad-and-stats-col">
        {/* <div className="notepad-column note-plan"> */}
        <Notepad />
        {/* </div> */}
        <div className="alerts-history-container pt">
          <AlertsHistory currentPatient={patientId} view="fullScreen" />
        </div>
      </div>
    </>
  );
});

const mapStateToProps = state => {
  const {
    trackerNoteData,
    customStatsData: { customStatsSelected },
    pdfOptions: { showPDF, pagesCount }
  } = state;
  return {
    trackerNoteData,
    customStatsSelected,
    showPDF,
    pagesCount
  };
};

const mapActionsToProps = { setShowPDF }

export default connect(
  mapStateToProps,
  mapActionsToProps
)(OverviewContainer);
