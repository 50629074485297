import { getSoapNotes } from "./util";

export const FETCH_REQUEST = "fetchRequest";
export const FETCH_SUCCESS = "fetchSuccess";
export const FETCH_ERROR = "fetchError";

const fetchRequest = () => {
  return {
    type: FETCH_REQUEST
  };
};

const fetchSuccess = soapNotes => {
  return {
    type: FETCH_SUCCESS,
    payload: soapNotes
  };
};

const fetchError = error => {
  return {
    type: FETCH_ERROR,
    payload: error
  };
};

export const fetchSoapNotes = id => async dispatch => {
  dispatch(fetchRequest());
  try {
    const soapNotes = await getSoapNotes(id);
    let data = soapNotes.data;
    if (typeof data === "string") {
      data = JSON.parse(data);
    }
    dispatch(fetchSuccess({ [id]: data }));
  } catch (error) {
    console.log(error);
    dispatch(fetchError(error));
  }
};
