import axios from "axios";
import { baseURL } from "../../config";
import applyCaseMiddleware from 'axios-case-converter';

const getUsers = async () => {
  const client = applyCaseMiddleware(axios.create());
  return client({
    method: "GET",
    url: `/users`,
    baseURL,
    withCredentials: true
  });
};

export default getUsers;
