export const dotPhraseMap = {
       rtn: `
Could connect patient (Y/N):\n
HIPPA (Y/N):\n
Change in insurance/provider:\n
Recent biometric reading:\n
How are you feeling today:\n
Current s/sx reviewed:\n
Medication updates:\n
New diagnosis:\n
Focus for today's encounter:\n
Response to previous interventions/Progress towards goal(s):\n
Progress towards RPM goal(s):\n
Barriers (SDOH):\n
Interventions:\n
Goal:\n
Plan for next encounter:\n
Future PCP/specialty appts:\n
Initiative reminders (RPM, weight, COVID, etc):\n
Teachback questions:\n
Any other questions:\n
Additional documentation:\n
Next scheduled f/u:\n
`,
       onb: `
PCP and specialist(s):\n
HIPPA (Y/N):\n
Name:\n
Language:\n
Cultural considerations:\n
Ethnicity:\n
What pronoun do you use:\n
Education level:\n
Any dietary considerations:\n
Point of contact:\n
Preferred day/time of contact:\n
Method of contact (call/text):\n
Living alone (Y/N):\n
Care taker name:\n
Care taker contact:\n
Device ordered (Y/N):\n
RPM schedule:\n
Long-term goals:\n
Home health:\n
Barriers (SDOH):
Additional documentation:\n
Next scheduled f/u:\n
`,
       alt: `
Could connect patient (Y/N):\n
HIPAA (Y/N):\n
Date of alert:\n
Time of alert:\n
Actual biometric reading:\n
Type of alert:\n
Alert response (call, SMS text, etc.):\n
How are you feeling today:\n
Current s/sx reviewed:\n
Medication updates:\n
Contributing factors:\n
New clinical diagnosis:\n
Barriers (SDOH):\n
Interventions:\n
Plan for next encounter:\n
SBAR note written/distributed/receipt confirmed:\n
Teachback questions:\n
Any other questions:\n
Additional documentation:\n
Next scheduled f/u:\n
`,
       int: `
Clinicians(s) involved (name and title NP, WC, pharmacy):\n
Nature of encounter (routine 1:1, medication reconciliation, SBAR review, rounds, consults):\n
Barriers (SDOH):\n
Interventions:\n
Changes to plan of care:\n
Additional documentation:\n
Next scheduled f/u:\n
`,
       dta: `
Data summary sent:\n
Fax number:\n
Office infomation:\n
Additional documentation:\n
`,
       rpm: `
Reminders (text/call/email):\n
Additional documentation:\n
`,
       dvc: `
Type of device (BPMonitor/scale/Glucometer):\n
Connection type (cellular/bluetooth):\n
IMEI numbers:\n
Device Status (ordered/shipped/received):\n
Device configured date:\n
Additional documentation:\n
`,
       msc: `
Additional documentation:\n
`,
       chn: `
Pre-churn date:\n
Pre-churn reason:\n
Churn date:\n
Churn reason:\n
Additional documentation:\n
`,
       dit: `
HIPAA (Y/N):\n
Recent biometric reading:\n
How are you feeling today:\n
Nutrition Consult:\n
Nutrition Status:\n
Assessment:\n
Barriers (SDOH):\n
Interventions:\n
Plan for next encounter:\n
Teachback questions:\n
Any other questions:\n
Additional documentation:\n
Next scheduled f/u:\n
`
};
