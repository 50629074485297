import React from 'react';
import { connect } from 'react-redux';

import PersistentInfoTop from './PersistentInfoTop';
import OverviewContainer from './OverviewContainer';
import './PatientContainer.css';
import Sidebar from '../Sidebar';

const PatientContainer = React.memo(function PatientContainer(props) {
  const { currentPatient, patientObj } = props;
  return (
    <div className="container-wrapper-pt-screen container-fluid">
      <div className="container-patient-screen ">
        <Sidebar />
          {!currentPatient ? null :
            <div className="persistent-info-top-and-overview">
              <div className="persistent-info-top">
                <PersistentInfoTop nextScreen='document' />
              </div>
              <div className="row-main">
              <div className="overview-fullscreen">
                <OverviewContainer patientObj={patientObj} />
              </div>
            </div>
          </div>}
      </div>
    </div>
  );
});

const mapStateToProps = state => {
  const {
    patientBioData: { bios },
    patientMetadata: { patientPage: { currentPatient } }
  } = state;
  const patientObj = bios[currentPatient];
  return {
    patientObj,
    currentPatient
  };
};

export default connect(
  mapStateToProps,
  null
)(PatientContainer);