import getClinics from "./util";

export const FETCH_CLINICS_REQUEST = "fetchClinicsRequest";
export const FETCH_CLINICS_SUCCESS = "fetchClinicsSucess";
export const FETCH_CLINICS_ERROR = "fetchClinicsError";

const fetchClinicsRequest = () => {
  return {
    type: FETCH_CLINICS_REQUEST
  };
};

const fetchClinicsSucess = clinics => {
  return {
    type: FETCH_CLINICS_SUCCESS,
    payload: clinics
  };
};

const fetchClinicsError = error => {
  return {
    type: FETCH_CLINICS_ERROR,
    payload: error
  };
};

export const fetchClinics = () => async dispatch => {
  dispatch(fetchClinicsRequest());
  try {
    const response = await getClinics();
    let clinics = response.data;
    let clinicsOptions = [];
    for (let clinic of clinics) {
      let text = clinic.name
      let clinicId = clinic.clinicId
      clinicsOptions.push({ text, value: clinicId, key: `${clinicId}-clinic` });
    }
    dispatch(fetchClinicsSucess(clinicsOptions));
  } catch (error) {
    console.log(error);
    dispatch(fetchClinicsError(error));
  }
};
