import {
  FETCH_DOCTORS_REQUEST, FETCH_DOCTORS_SUCCESS, FETCH_DOCTORS_ERROR
} from "./actions";

export default (
  state = {
    doctors: [],
    doctorInfoOnUpdate: {},
    isFetching: false,
    error: null
  },
  action
) => {
  switch (action.type) {
    case FETCH_DOCTORS_REQUEST:
      return { ...state, error: null, isFetching: true };
    case FETCH_DOCTORS_SUCCESS:
      return {
        ...state,
        doctors: action.payload.doctorOptions,
        doctorInfoOnUpdate: action.payload.doctorInfoOnUpdate,
        isFetching: false
      };
    case FETCH_DOCTORS_ERROR:
      return { ...state, error: action.payload, isFetching: false };
    default:
      return state;
  }
};
