import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import { BrowserRouter as Router, Route } from "react-router-dom";

import App from './components/App';
import PatientContainer from '../src/components/patient_screen/PatientContainer'
import { fetchUser } from "./redux/user/actions";
import { fetchClinics } from "./redux/clinics/actions";
import { fetchUsers } from "./redux/users/actions";
import { fetchDoctors } from "./redux/doctors/actions";
import { fetchMembersData } from "./redux/members/actions";
import { fetchAlertsData } from "./redux/alerts/actions";
import configureStore from './store/configureStore';
import DocumentScreen from  './components/document_screen/DocumentScreen';
import SignScreen from './components/sign_screen/SignScreen';
import Members from './components/members/Members';
import Tasks from './components/tasks/Tasks';
import OnboardingContainer from './components/onboarding/OnboardingContainer';

const store = configureStore();

const routing = (
  <Provider store={store}>
    <Router>
      <>
        {/* changed private route to route */}
        <Route exact path="/" component={App} />
        <Route path="/patients" component={PatientContainer} />
        <Route path="/members" component={Members} />
        <Route path="/tasks" component={Tasks} />
        <Route path="/document" component={DocumentScreen} />
        <Route path="/sign" component={SignScreen} />
        <Route path="/onboarding" component={OnboardingContainer} />
      </>
    </Router>
  </Provider>
);

const setup = async () => {
  // need to get user data first and based on the user type fetch members
   await store.dispatch(fetchUser());
    const state = store.getState();
    let userType = state.userData.userType;
    let clinicId = state.userData.clinicId;
    if (userType == 1) {
      store.dispatch(fetchMembersData({
        currentFilter: "my_panel",
        sortByMinutes: true,
        userType
      }));
      // make sure alerts are fetched initially only from here
      store.dispatch(fetchAlertsData({ offset: 0, userType, clinicId }));
    } else if (userType == 2 && clinicId) {
      store.dispatch(fetchMembersData({
               clinicId,
               userType,
               showAllPanels: 1,
               currentFilter: "clinic_id",
               sortByMinutes: true
             }));
      store.dispatch(fetchAlertsData({ offset: 0, userType, clinicId }));
    }
    store.dispatch(fetchUsers());
    store.dispatch(fetchClinics());
    store.dispatch(fetchDoctors());
}

const execSetup = async() => {
  await setup()
  ReactDOM.render(routing, document.querySelector("#root"));
}
execSetup();

