import axios from "axios";
import { baseURL } from "../../config";
import applyCaseMiddleware from "axios-case-converter";

export const getPatientData = async (id) => {
  const client = applyCaseMiddleware(axios.create());
  return client({
    method: 'GET',
    url: `/patient_data?id=${id}`,
    baseURL,
  });
};
