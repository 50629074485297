import getTasks from "./util";

export const FETCH_TASKS_REQUEST = 'fetchTasksRequest';
export const FETCH_TASKS_SUCCESS = 'fetchTasksSucess';
export const FETCH_TASKS_ERROR = 'fetchTasksError';
export const DELETE_TASK = 'deleteTask';
export const DEESCALATE_TASK = 'deescalateTask';
const fetchTasksRequest = () => {
  return {
    type: FETCH_TASKS_REQUEST
  }
};

const fetchTasksSuccess = (tasksData) => {
  return {
    type: FETCH_TASKS_SUCCESS,
    payload: tasksData
  }
};

const fetchTasksError = (error) => {
  return {
    type: FETCH_TASKS_ERROR,
    payload: error
  }
};

export const fetchTasksData = (params) => async (dispatch) => {
  dispatch(fetchTasksRequest());
  try {
    const tasksData = await getTasks(params);
    let tasks = tasksData.data;
    dispatch(fetchTasksSuccess(tasks));
  } catch (error) {
    console.log(error);
    dispatch(fetchTasksError(error));
  }
};

export const deleteTask = patientId => {
  return {
    type: DELETE_TASK,
    payload: patientId
  };
};

export const deescalateTask = patientId => {
  return {
    type: DEESCALATE_TASK,
    payload: patientId
  };
};