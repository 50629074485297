import React, { useState } from "react";
import { connect } from "react-redux";
import moment from 'moment';
import axios from "axios";
import { baseURL } from "../../config";
import applyCaseMiddleware from "axios-case-converter";
import { CardBody, Card, Form, FormGroup, Label, Input, CardFooter, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { Dropdown as DropdownSearch } from "semantic-ui-react";
import DatePickerComponent from "../shared_components/DatePickerComponent";
import { setShowNotification, setNotificationMessage } from '../../redux/notifications/actions';
import { updateMemberStatus as updateStatusPatientBio } from "../../redux/patient_bios/actions";
import { updateMemberStatus as updateStatusMembers } from "../../redux/members/actions";


const StatusEditCard = React.memo(function StatusEditCard(props) {
  const { patientObj, setStatusPopoverOpen, setNotificationMessage, setShowNotification, updateStatusPatientBio, updateStatusMembers } = props;
  const [status, setStatus] = useState("");

  const [churnReason, setChurnReason] = useState("");
  const [pauseReason, setPauseReason] = useState("");
  const [otherPauseReason, setOtherPauseReason] = useState("");
  const [otherChurnReason, setOtherChurnReason] = useState("");

  const [pauseStartDate, setPauseStartDate] = useState(new Date().getTime());
  const [pauseEndDate, setPauseEndDate] = useState((new Date(moment(new Date()).add(30, "days"))).getTime());
  const [churnDate, setChurnDate] = useState(new Date().getTime());

  let leftMargin = status === "" || status === "Active" ? "-31px" : "0px";

  let patientName = patientObj.firstName + ' ' + patientObj.lastName;
  let patientId = patientObj.patientId;

  let statusOptions = [
    {
      text: "Active",
      value: "active",
      key: "active"
    },
    {
      text: "Paused",
      value: "pause",
      key: "paused"
    },
    {
      text: "Churned",
      value: "churn",
      key: "churned"
    }
  ];

  let pauseReasonOptions = [
    {
      text: "Vacation",
      value: "vacation",
      key: "vacation"
    },
    {
      text: "Home Health",
      value: "homeHealth",
      key: "homeHealth"
    },
    {
      text: "Hospice",
      value: "hospice",
      key: "hospice"
    },
    {
      text: "Pre-churn",
      value: "prechurn",
      key: "prechurn"
    },
    {
      text: "Other",
      value: "other",
      key: "other"
    }
  ];

  let churnReasonOptions = [
    {
      text: "Deceased",
      value: "deceased",
      key: "deceased"
    },
    {
      text: "Device concerns/issues",
      value: "device",
      key: "device"
    },
    {
      text: "No longer needs service / Requested end of service",
      value: "endOfService",
      key: "endOfService"
    },
    {
      text: "Disengaged (No contact/data > 14 days)",
      value: "disengaged",
      key: "disengaged"
    },
    {
      text: "Moved out of service area / changed provider",
      value: "outOfServiceArea",
      key: "outOfServiceArea"
    },
    {
      text: "UTC (No phone)",
      value: "utc",
      key: "utc"
    },
    {
      text: "Dissatisfied with program / WC / customer service",
      value: "dissatisfied",
      key: "dissatisfied"
    },
    {
      text: "Language / Communication barrier",
      value: "comunicationBarrier",
      key: "comunicationBarrier"
    },
    {
      text:
        "Inappropriate referral (did not understand / no interest in the program)",
      value: "mistakeReferral",
      key: "mistakeReferral"
    },
    {
      text: "Ineligible due to insurance",
      value: "insurance",
      key: "insurance"
    },
    {
      text: "Ineligible due to condition",
      value: "condition",
      key: "condition"
    },
    {
      text: "SNF/HH",
      value: "snf/hh",
      key: "snf/hh"
    },
    {
      text: "Hospice",
      value: "hospice",
      key: "hospice"
    },
    {
      text: "Billing Issues",
      value: "billing",
      key: "billing"
    },
    {
      text: "Physician Initiated Churn",
      value: "physician",
      key: "physician"
    },
    {
      text: "Other",
      value: "other",
      key: "other"
    }
  ];

  const getCalendar = () => {
    if (status === "churn") {
      return (
        <>
          Churn date:
          <DatePickerComponent
            selectedDate={moment(new Date())}
            onDateSelect={date => {
              setChurnDate(date.getTime());
            }}
          />
        </>
      );
    } else if (status === "pause") {
      return (
        <>
          From:
          <DatePickerComponent
            selectedDate={moment(new Date())}
            onDateSelect={date => {
              setPauseStartDate(date.getTime());
            }}
          />
          To:
          <DatePickerComponent
            selectedDate={moment(new Date()).add(30, "days")}
            onDateSelect={date => {
              setPauseEndDate(date.getTime());
            }}
          />
        </>
      );
    }
  };

  const activateMember = async () => {
    const client = applyCaseMiddleware(axios.create());
    let response;
    try {
      response = await client({
        method: "POST",
        url: `/activate_member`,
        baseURL,
        params: {
          patientId
        },
        withCredentials: true
      });
      if (response.status === 200) {
        updateStatusPatientBio(patientId, status);
        updateStatusMembers(patientId, status);
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: `${patientName} has been activated`, type: 'success', isFor: 'statusEdit' })
      }
    } catch (err) {
      setShowNotification(true);
      setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
      setNotificationMessage({ text: "Member status could not be updated at this time", type: 'danger', isFor: 'statusEdit' });
    }
  }

  const pauseMember = async () => {
    const client = applyCaseMiddleware(axios.create());
    let response;
    try {
      response = await client({
        method: "POST",
        url: `/pause_member`,
        baseURL,
        params: {
          patientId,
          patientName,
          pauseStartDate,
          pauseEndDate,
          reason: pauseReason,
          otherReason: pauseReason === "other" ? otherPauseReason : null,
          status
        },
        withCredentials: true
      });
      if (response.status === 200) {
        updateStatusPatientBio(patientId, status);
        updateStatusMembers(patientId, status);
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: `${patientName} has been paused`, type: 'success', isFor: 'statusEdit' })
      }
    } catch (err) {
      setShowNotification(true);
      setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
      setNotificationMessage({ text: "Member status could not be updated at this time", type: 'danger', isFor: 'statusEdit' });
    }
  }

  const churnMember = async () => {
    const client = applyCaseMiddleware(axios.create());
    let response;
    try {
      response = await client({
        method: "POST",
        url: `/churn_member`,
        baseURL,
        params: {
          patientId,
          patientName,
          churnDate,
          reason: churnReason,
          otherReason: churnReason === "other" ? otherChurnReason : null,
          status
        },
        withCredentials: true
      });
      if (response.status === 200) {
        updateStatusPatientBio(patientId, status);
        updateStatusMembers(patientId, status);
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: `${patientName} has been churned`, type: 'success', isFor: 'statusEdit' })
      }
    } catch (err) {
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: "Member status could not be updated at this time", type: 'danger', isFor: 'statusEdit' });
      }
  }

  return (
    <Card style={{ height: "261px", width: "500px" }}>
      <CardBody style={{ display: "flex", justifyContent: "space-around" }}>
        <div id="left-col-edit-satus" style={{ marginLeft: leftMargin }}>
          <div className="custom-card-header">Select Status</div>
          <DropdownSearch
            placeholder="status"
            selection
            options={statusOptions}
            onChange={(e, params) => {
              setStatus(params.value);
            }}
            style={{ width: "347px" }}
          />
          {status === "churn" && (
            <div className="reason-wrapper">
              <div className="custom-card-header">Select Reason (required)</div>
              <DropdownSearch
                placeholder="reason"
                selection
                options={churnReasonOptions}
                onChange={(e, params) => {
                  setChurnReason(params.value);
                }}
                style={{ width: "347px" }}
              />
              {churnReason === "other" && (
                <>
                  <Form>
                    <FormGroup>
                      <Label for="otherChurnReasonInput">Other Reason</Label>
                      <Input
                        type="text"
                        id="otherChurnReasonInput"
                        value={otherChurnReason}
                        onChange={e => {
                          setOtherChurnReason(e.target.value);
                        }}
                        style={{ width: "347px" }}
                      />
                    </FormGroup>
                  </Form>
                </>
              )}
            </div>
          )}
          {status === "pause" && (
            <div className="reason-wrapper">
              <div className="custom-card-header">Select Reason (required)</div>
              <DropdownSearch
                placeholder="reason"
                selection
                options={pauseReasonOptions}
                onChange={(e, params) => {
                  setPauseReason(params.value);
                }}
                style={{ width: "347px" }}
              />
              {pauseReason === "other" && (
                <>
                  <Form>
                    <FormGroup>
                      <Label for="otherPauseReasonInput">Other Reason</Label>
                      <Input
                        type="text"
                        id="otherPauseReasonInput"
                        value={otherPauseReason}
                        onChange={e => {
                          setOtherPauseReason(e.target.value);
                        }}
                        style={{ width: "347px" }}
                      />
                    </FormGroup>
                  </Form>
                </>
              )}
            </div>
          )}
        </div>
        <div className="right-col-edit-status" style={{ marginLeft: "20px" }}>
          {getCalendar()}
        </div>
      </CardBody>
      <CardFooter style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          color="primary"
          onClick={() => {
            if (status == "churn"){
              if (!churnReason) {
                setShowNotification(true);
                setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
                setNotificationMessage({ text: `Select a churn reason`, type: 'danger', isFor: 'statusEdit' })
              } else {
                churnMember();
                setStatusPopoverOpen(false);
              }
            } else if (status == "pause") {
              if (!pauseReason) {
                setShowNotification(true);
                setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
                setNotificationMessage({ text: `Select a pause reason`, type: 'danger', isFor: 'statusEdit' })
              } else {
                pauseMember();
                setStatusPopoverOpen(false);
              }
            } else if (status == "active") {
              activateMember();
              setStatusPopoverOpen(false);
            }
          }}
          style={{ lineHeight: "1", marginRight: "15px" }}
        >
          Submit
        </Button>
        <Button
          color="secondary"
          onClick={() => setStatusPopoverOpen(false)}
          style={{ lineHeight: "1" }}
        >
          Cancel
        </Button>
      </CardFooter>
    </Card>
  );
});

const mapActionsToProps = { setNotificationMessage, setShowNotification, updateStatusPatientBio, updateStatusMembers}

export default connect(
  null,
  mapActionsToProps
)(StatusEditCard);
