import { getDocuments } from "./util";

export const FETCH_DOCUMENTS_REQUEST = "fetchDocumentsRequest";
export const FETCH_DOCUMENTS_SUCCESS = "fetchDocumentsSuccess";
export const FETCH_DOCUMENTS_ERROR = "fetchDocumentsError";

const fetchDocumentsRequest = () => {
  return {
    type: FETCH_DOCUMENTS_REQUEST
  };
};

const fetchDocumentsSuccess = docs => {
  return {
    type: FETCH_DOCUMENTS_SUCCESS,
    payload: docs
  };
};

const fetchDocumentsError = error => {
  return {
    type: FETCH_DOCUMENTS_ERROR,
    payload: error
  };
};

export const fetchDocuments = id => async dispatch => {
  dispatch(fetchDocumentsRequest());
  try {
    const docs = await getDocuments(id);
    let data = docs.data;
    dispatch(fetchDocumentsSuccess({ [id]: data }));
  } catch (error) {
    console.log(error);
    dispatch(fetchDocumentsError(error));
  }
};
