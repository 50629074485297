import {
  FETCH_ALERTS_HISTORY_REQUEST,
  FETCH_ALERTS_HISTORY_SUCCESS,
  FETCH_ALERTS_HISTORY_ERROR
} from './actions'

export default (
  state = {
    alertsHistory: {},
    isFetching: false,
    error: null
  }, action) => {
  switch (action.type) {
    case FETCH_ALERTS_HISTORY_REQUEST:
      return { ...state, error: null, isFetching: true };
    case FETCH_ALERTS_HISTORY_SUCCESS:
      return {
        ...state,
        alertsHistory: {
          ...state.alertsHistory,
          [action.payload.id]: { ...state.alertsHistory[action.payload.id], [action.payload.metric]: [...action.payload.history]}
        },
        isFetching: false
      };
    case FETCH_ALERTS_HISTORY_ERROR:
      return { ...state, error: action.payload, isFetching: false };
    default:
      return state;
  }
};
