import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { CardBody, Card, Input, Button, FormGroup } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { updateMemberStickyNotes, updateMemberOtherNotes } from "../../redux/patient_bios/actions";
import axios from "axios";
import { baseURL } from "../../config";
import applyCaseMiddleware from "axios-case-converter";
import {
  setShowNotification,
  setNotificationMessage
} from "../../redux/notifications/actions";


const StickyNotesCard = React.memo(function StickyNotesCard({ stickyNotes, otherNotes, patientId, setShowNotification, setNotificationMessage }) {
  const [editModeS, setEditModeS] = useState(false);
  const [editModeOn, setEditModeOn] = useState(false);
  const [ sNotesText, setSNotesText ] = useState(stickyNotes);
  const [ otherNotesText, setOtherNotesText] = useState(otherNotes);

  const dispatch = useDispatch();

  const saveUpdate = async (type, text) => {
    const client = applyCaseMiddleware(axios.create());
    let url = type == "stickyNotesEdit" ? `/update_sticky_notes` : `/update_other_notes`
    let response = await client({
      method: "POST",
      url,
      baseURL,
      params: {
        text,
        patientId
      },
      withCredentials: true
    })
    if (response.status === 200) {
      setShowNotification(true);
      setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 3000)
      setNotificationMessage({ text: "Your data has been saved!", type: "success", isFor: 'topRightPlan'});
    } else {
      console.log(response.data)
      setShowNotification(true);
      setTimeout(() => { setShowNotification(false); setNotificationMessage({}); }, 2000)
      setNotificationMessage({ text: "Could not be saved at this time", type: "danger", isFor: 'topRightPlan'});
    }
  };

  const displayStickyNotes = (label, text) => {
    return (
      <div className="sticky-notes-card">
        <div className="conditions-header">
          {label}
        </div>
        <div className="s-notes-body">
          {text}
        </div>
      </div>
    )
  }

  const editNotesForm = (label, inputId, notesText, setNotesText, setEditMode, updateNotesRedux ) => {
    return (
      <div>
        <FormGroup style={{ marginBottom: "5px" }}>
          <label className="conditions-header">{label}</label>
          <Input
            type="textarea"
            name="text"
            id={inputId}
            rows="3"
            value={notesText}
            onChange={e => setNotesText(e.target.value)}
          />
        </FormGroup>
        <div className="save-s-notes-button">
          <Button
            color="primary"
            className="save-contact-btn"
            onClick={() => {
              saveUpdate(inputId, notesText);
              dispatch(updateNotesRedux(patientId, notesText));
              setEditMode(false);
            }}
            style={{ marginRight: "10px" }}
          >
            Save
          </Button>
          <Button
            className="save-contact-btn"
            onClick={() => {
              setEditMode(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }

  const getCardWidth = (editMode) => {
    if (editMode) {
      return "100%"
    } else {
      return "91%"
    }
  }

  return (
    <Card style={{border: "none", width: "100%"}}>
      <CardBody style={{ width: getCardWidth(editModeS), padding: "5px"}}>
        {!editModeS ? <FontAwesomeIcon icon={faPencilAlt} onClick={() => setEditModeS(true)} style={{ position: 'absolute', right: '8px' }} /> : null}
        {editModeS ? editNotesForm("Sticky Notes", "stickyNotesEdit", sNotesText, setSNotesText, setEditModeS, updateMemberStickyNotes) : displayStickyNotes("Sticky Notes", stickyNotes)}
      </CardBody>
      <CardBody style={{ width: getCardWidth(editModeOn), padding: "5px" }}>
        {!editModeOn ? <FontAwesomeIcon icon={faPencilAlt} onClick={() => setEditModeOn(true)} style={{ position: 'absolute', right: '8px' }} /> : null}
        {editModeOn ? editNotesForm("Other Notes", "otherNotesEdit", otherNotesText, setOtherNotesText, setEditModeOn, updateMemberOtherNotes  ) : displayStickyNotes("Other Notes", otherNotes)}
      </CardBody>
    </Card>
  )

})

const mapActionsToProps = { setShowNotification, setNotificationMessage };

export default connect(
  null,
  mapActionsToProps
)(StickyNotesCard);