import axios from "axios";
import { baseURL } from "../../config";
import applyCaseMiddleware from "axios-case-converter";

const getDoctors = async () => {
  const client = applyCaseMiddleware(axios.create());
  return client({
    method: "GET",
    url: `/doctors`,
    baseURL,
    withCredentials: true
  });
};

export default getDoctors;
