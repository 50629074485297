export const SET_NOTE_TEXT = "setNoteText";
export const SET_SOAP_NOTE_TEXT = "setSoapNoteText";
export const SET_TN_ID = "setTnId";
export const SET_SOAP_NOTE_ID = "setSoapNoteId";
export const SET_IS_TN_UPDATE = "setIsTnUpdate";
export const SET_IS_SOAP_NOTE_UPDATE = "setIsSoapNoteUpdate";

export const setNoteText = params => dispatch => {
  dispatch({
    type: SET_NOTE_TEXT,
    payload: params
  });
};

export const setSoapNoteText = params => dispatch => {
  dispatch({
    type: SET_SOAP_NOTE_TEXT,
    payload: params
  });
};

export const setTnId = params => dispatch => {
  dispatch({
    type: SET_TN_ID,
    payload: params
  });
};

export const setSoapNoteId = params => dispatch => {
  dispatch({
    type: SET_SOAP_NOTE_ID,
    payload: params
  });
};

export const setIsTnUpdate = params => dispatch => {
  dispatch({
    type: SET_IS_TN_UPDATE,
    payload: params
  });
};

export const setIsSoapNoteUpdate = params => dispatch => {
  dispatch({
    type: SET_IS_SOAP_NOTE_UPDATE,
    payload: params
  });
};




