import {
  FETCH_FREQUENCIES_REQUEST,
  FETCH_FREQUENCIES_SUCCESS,
  FETCH_FREQUENCIES_ERROR,
} from './actions'

export default (
  state = {
    topicFrequencies: {},
    currentTime: null,
    isFetching: false,
    error: null
  }, action) => {
  switch (action.type) {
    case FETCH_FREQUENCIES_REQUEST:
      return { ...state, error: null, isFetching: true };
    case FETCH_FREQUENCIES_SUCCESS:
      return {
        ...state,
        currentTime: action.payload.time,
        topicFrequencies: {
          ...state.topicFrequencies,
          [action.payload.id]:  action.payload.topicFrequencies
        },
        isFetching: false
      };
    case FETCH_FREQUENCIES_ERROR:
      return { ...state, error: action.payload, isFetching: false };
    default:
      return state;
  }
};
