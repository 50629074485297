import {
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_ERROR
} from "./actions";

export default (
  state = {
    soapNotes: {},
    isFetching: false,
    error: null
  },
  action
) => {
  switch (action.type) {
    case FETCH_REQUEST:
      return { ...state, error: null, isFetching: true };
    case FETCH_SUCCESS:
      return {
        ...state,
        soapNotes: {
          ...state.soapNotes,
          ...action.payload
        },
        isFetching: false
      };
    case FETCH_ERROR:
      return { ...state, error: action.payload, isFetching: false };
    default:
      return state;
  }
};
