import getDoctors from "./util";

export const FETCH_DOCTORS_REQUEST = "fetchDoctorsRequest";
export const FETCH_DOCTORS_SUCCESS = "fetchDoctorsSucess";
export const FETCH_DOCTORS_ERROR = "fetchDoctorsError";

const fetchDoctorsRequest = () => {
  return {
    type: FETCH_DOCTORS_REQUEST
  };
};

const fetchDoctorsSucess = ({doctorOptions, doctorInfoOnUpdate}) => {
  return {
    type: FETCH_DOCTORS_SUCCESS,
    payload: { doctorOptions, doctorInfoOnUpdate }
  };
};

const fetchDoctorsError = error => {
  return {
    type: FETCH_DOCTORS_ERROR,
    payload: error
  };
};

export const fetchDoctors = () => async dispatch => {
  dispatch(fetchDoctorsRequest());
  try {
    const response = await getDoctors();
    let doctors = response.data;
    let doctorOptions = [];
    let doctorInfoOnUpdate = {};
    for (let doctor of doctors) {
      let text = doctor.doctorName + ", " + doctor.clinicName;
      let doctorId = doctor.doctorId;
      doctorOptions.push({ text, value: doctorId, key: doctorId })
      doctorInfoOnUpdate[doctorId] = {
        doctorName: doctor.doctorName,
        clinic: doctor.clinicName,
        phone: doctor.phone
      };
    }
    dispatch(fetchDoctorsSucess({ doctorOptions, doctorInfoOnUpdate}));
  } catch (error) {
    console.log(error);
    dispatch(fetchDoctorsError(error));
  }
};
