import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import prettyMilliseconds from "pretty-ms";
import axios from "axios";
import { baseURL } from "../../config";

import { Row, Col, Popover, PopoverBody } from "reactstrap";
import "./AlertsRow.css";
import { fetchPatientData } from "../../redux/patient_bios/actions";
import { fetchAlertsHistory } from "../../redux/alerts_history/actions";
import { fetchChartData } from "../../redux/highchart/actions";
import { fetchPatientStats } from "../../redux/patient_recent_stats/actions";
import { setCustomStatsSelected } from "../../redux/patient_custom_stats/actions";
import { setNoteText, setSoapNoteText } from "../../redux/tracker_note_input/actions";
import { updateClaimedFlag } from "../../redux/alerts/actions";
import { setShowNotification, setNotificationMessage } from "../../redux/notifications/actions";
import { ReactComponent as TaskIconBlue } from "../../images/task_icon_blue.svg";
import { ReactComponent as TaskIconFaded } from "../../images/task_icon_faded.svg";
import { ReactComponent as TaskIconCleared } from "../../images/task_icon_cleared.svg";
import DelegationModal from './DelegationModal';
import ContextMenuDropdown from './ContextMenuDropdown';
import { setCurrentPatientAlertsPage, addPatientAlertsPage, setCurrentMetricAlertsPage } from "../../redux/patients_metadata/actions";
import { fetchTopicFrequencies } from "../../redux/topic_frequencies/actions";

export const getColor = severity => {
  switch (severity) {
    case 0:
      return "rgba(249, 70, 78, 0.7)";
    case 1:
      return "rgba(252, 188, 78, 0.7)";
    case 2:
      return "rgba(246, 225, 36, 0.7)";
    case 3:
      return "rgba(126, 232, 122, 0.7)";
    default:
      return "rgba(126, 232, 122, 0.7)";
  }
};

export const getIds = openTabsArr => {
  let result = [];
  openTabsArr.forEach(obj => {
    result.push(obj.id);
  });
  return result;
};

const AlertsRow = React.memo(function AlertsRow(props) {
  const {
    alert,
    openTabs,
    setEncounterNoteText,
    setSoapNoteText,
    updateClaimedFlag,
    setShowNotification,
    setNotificationMessage,
    trackerNoteInput,
    refToPass
  } = props;
  let patientId = alert.patient_id;
  const [contextMenuOpen, setContextMenuOpen] = useState({ [patientId]: false});
  const toggleContextMenu = () => setContextMenuOpen({ ...contextMenuOpen, [patientId]: !contextMenuOpen.patientId})
  const [delegationModalOpen, setDelegationModalOpen] = useState(false);
  const toggleDelegationModal = () => setDelegationModalOpen(!delegationModalOpen);
  const [escalationModalOpen, setEscalationModalOpen] = useState(false);
  const toggleEscalationModal = () => setEscalationModalOpen(!escalationModalOpen);
  const [ claimedByPopoverOpen, setClaimedByPopoverOpen ] = useState(false);

  const fullName = alert.first_name + " " + alert.last_name;
  const patientIds = getIds(openTabs);

  useEffect(() => refToPass && refToPass.current && refToPass.current.focus());

  const handleAlertClick = (id, name, type) => {
    if (patientIds.includes(id)) {
      props.setCurrentPatientAlertsPage(id);
    } else {
      if (type === "BP") {
        props.fetchChartData(id, "blood_pressure");
        props.fetchAlertsHistory(id, "blood_pressure");
        props.setCurrentMetricAlertsPage("blood_pressure");
      } else if (type === "BG") {
        props.fetchChartData(id, "glucose");
        props.fetchAlertsHistory(id, "glucose");
        props.setCurrentMetricAlertsPage("glucose");
      }
      props.setCurrentPatientAlertsPage(id);
      props.addPatientAlertsPage(id, name);
      props.fetchPatientData(id);
      props.fetchPatientStats(id);
      props.setCustomStatsSelected(false);
      if (!trackerNoteInput.hasOwnProperty(id)) {
        setEncounterNoteText({ patientId: id, text: "" });
        setSoapNoteText({ patientId: id, text: "" });
      }
      props.fetchTopicFrequencies(id)
    }
  };

  const handleClaimButtonClick = async alert => {
    let response;
    if (alert.claimed_flag){
      try {
        response = await axios({
          method: "POST",
          url: `/unclaim_alert?alert_id=${alert._id}&patient_id=${alert.patient_id}`,
          baseURL,
          withCredentials: true
        });
        if (response.status === 200) {
          updateClaimedFlag(alert._id, 0);
          setShowNotification(true);
          setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
          setNotificationMessage({ text: `Member has been removed from your queue`, type: 'success', isFor: 'alerts' });
        }
      } catch (err) {
        console.log(err.response.status);
        if (err.response.status == 405) {
          setShowNotification(true);
          setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
          setNotificationMessage({text: `You can only unclaim alerts claimed by you`, type: 'danger', isFor: 'alerts'});
        } else {
          setShowNotification(true);
          setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
          setNotificationMessage({ text: "Alert can't be unclaimed", type: 'danger', isFor: 'alerts'});
        }
      }
    } else {
      try {
        response = await axios({
          method: "POST",
          url: `/claim_alert?alert_id=${alert._id}&patient_id=${alert.patient_id}`,
          baseURL,
          withCredentials: true
        });
        if (response.status === 200) {
          updateClaimedFlag(alert._id, 1);
          setShowNotification(true);
          setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
          setNotificationMessage({ text: `Member has been added to your queue`, type: 'success', isFor: 'alerts' });
        }
      } catch (err) {
        console.log(err.response.status);
        if (err.response.status == 405) {
          setShowNotification(true);
          setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
          setNotificationMessage({ text: `This member is not associated with you. Claim the member first.`, type: 'danger', isFor: 'alerts'} );
        } else  if (err.response.status == 401) {
          setShowNotification(true);
          setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
          setNotificationMessage({ text: 'You are not authorized to claim alerts', type: 'danger', isFor: 'alerts' });
        } else {
          console.log("Alert can't be claimed");
          setShowNotification(true);
          setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
          setNotificationMessage({ text: "Alert can't be claimed", type: 'danger', isFor: 'alerts'});
        }
      }
    }
  };

  const claimedByPopover = ({ isOpen, message, target }) => {
    return (
      <Popover
        isOpen={isOpen}
        placement="left"
        target={target}
        hideArrow={true}
        popperClassName="icon-name"
      >
        <PopoverBody className="tab-name-popover">{message}</PopoverBody>
      </Popover>
    );
  };

  const taskIcon = (claimedFlag)=> {
    switch (claimedFlag) {
      case 0:
      return <TaskIconFaded />
      case 1:
        return <TaskIconBlue />
      case 2:
        return <TaskIconCleared />
      default:
       return <TaskIconCleared />
    }
  }
  return (
    <div ref={refToPass}>
      <Row className="alert-row">
        <Col
          xs="2"
          className="alert-col mid"
          onClick={() =>
            handleAlertClick(alert.patient_id, fullName, alert.alert_type)
          }
        >
          <div className="mid-top">MID</div>
          <div className="mid-bottom">{alert.patient_id}</div>
        </Col>
        <Col
          xs="4"
          className="alert-name-col"
          onClick={() =>
            handleAlertClick(alert.patient_id, fullName, alert.alert_type)
          }
        >
          {alert.first_name} {alert.last_name}
        </Col>
        <Col xs="2" className="alert-col alert-days">
          {prettyMilliseconds(Date.now() - moment(alert.alert_time), {
            compact: true
          })}
        </Col>
        <Col
          xs="2"
          className="values"
          style={{ backgroundColor: getColor(alert.alert_severity) }}
        >
          <Col xs="12" className="alert-col alert-type">
            {alert.alert_type}
          </Col>
          <Col xs="12" className="alert-col alert-value">
            {alert.alert_value}
          </Col>
        </Col>
        <Col
          xs="2"
          id={`claim-button-${alert.patient_id}`}
          className="alert-col claim"
          onClick={() => {
            handleClaimButtonClick(alert);
          }}
          onMouseEnter={() => {
            if (alert.primary_nurse !== "") {
              setClaimedByPopoverOpen(true);
            }
          }}
          onMouseLeave={() => {
            setClaimedByPopoverOpen(false);
          }}
        >
          {taskIcon(alert.claimed_flag)}
        </Col>
        {claimedByPopover({
          isOpen: claimedByPopoverOpen,
          message: `Primary WC: ${alert.primary_nurse_full_name}`,
          target: `claim-button-${alert.patient_id}`
        })}
        <Col
          xs="1"
          className="alert-col dots"
          id={`more-icon-${alert.patient_id}`}
        >
          <ContextMenuDropdown
            toggleDelegationModal={toggleDelegationModal}
            toggleEscalationModal={toggleEscalationModal}
            toggleContextMenu={toggleContextMenu}
            isDropdownOpen={contextMenuOpen.patientId}
            alert={alert}
          />
        </Col>

        {/* Not a part of the row */}
        <DelegationModal
          alertId={alert._id}
          fullName={fullName}
          patientId={patientId}
          isFor="alerts"
          delegationModalOpen={delegationModalOpen}
          toggleDelegationModal={toggleDelegationModal}
          escalationModalOpen={escalationModalOpen}
          toggleEscalationModal={toggleEscalationModal}
        />
      </Row>
    </div>
  );
});

const mapStateToProps = state => {
  const {
    patientMetadata: {
      alertsPage: { openTabs }
    },
    trackerNoteInput
  } = state;
  return {
    openTabs,
    trackerNoteInput
  };
};

const mapActionToProps = {
  setCurrentPatientAlertsPage,
  fetchPatientData,
  fetchChartData,
  fetchAlertsHistory,
  addPatientAlertsPage,
  fetchPatientStats,
  setCustomStatsSelected,
  setEncounterNoteText: setNoteText,
  setSoapNoteText,
  updateClaimedFlag,
  setShowNotification,
  setNotificationMessage,
  fetchTopicFrequencies,
  setCurrentMetricAlertsPage
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(AlertsRow);
