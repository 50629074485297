import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import alertsData from '../redux/alerts/reducer';
import patientMetadata from '../redux/patients_metadata/reducer';
import patientBioData from '../redux/patient_bios/reducer';
import chartData from '../redux/highchart/reducer';
import trackerNoteData from '../redux/tracker_note/reducer';
import alertsHistoryData from '../redux/alerts_history/reducer';
import recentStatsData from '../redux/patient_recent_stats/reducer';
import customStatsData from '../redux/patient_custom_stats/reducer';
import notesTextData from '../redux/notepad/reducer';
import encountersData from '../redux/encounter_notes/reducer';
import documentsData from "../redux/documents/reducer";
import soapNotesData from "../redux/soap_notes/reducer";
import pdfOptions from '../redux/pdf_options/reducer';
import membersData from '../redux/members/reducer';
import trackerNoteInput from '../redux/tracker_note_input/reducer';
import encountersFiltered from '../redux/encounters_for_pdf/reducer';
import tasksData from '../redux/tasks/reducer';
import userData from '../redux/user/reducer';
import usersData from "../redux/users/reducer";
import notificationsData from '../redux/notifications/reducer';
import topicFrequenciesData from "../redux/topic_frequencies/reducer";
import doctorsData from "../redux/doctors/reducer";
import clinicsData from "../redux/clinics/reducer";

const allReducers = combineReducers({
  alertsData,
  patientMetadata,
  patientBioData,
  chartData,
  trackerNoteData,
  alertsHistoryData,
  recentStatsData,
  customStatsData,
  notesTextData,
  encountersData,
  documentsData,
  soapNotesData,
  pdfOptions,
  membersData,
  trackerNoteInput,
  encountersFiltered,
  tasksData,
  userData,
  usersData,
  notificationsData,
  topicFrequenciesData,
  doctorsData,
  clinicsData
});

const composeEnhancers =
  typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    }) : compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunk)
);

export default function configureStore(initialState) {
  return createStore(
    allReducers,
    initialState,
    enhancer
  );
}
