import React, { useState } from 'react';
import {connect} from 'react-redux';
import axios from "axios";
import { baseURL } from "../../config";
import applyCaseMiddleware from "axios-case-converter";
import { updatePrimaryConditions as updatePrimaryConditionsRedux } from "../../redux/patient_bios/actions";
var qs = require("qs");

const PrimaryConditionsEdit = React.memo(function PrimaryConditionsEdit(props) {
  const { pcArr, setPcEditMode, patientId, updatePrimaryConditionsRedux } = props

  const updatePrimaryConditions = async () => {
    const client = applyCaseMiddleware(axios.create());
    return client({
      method: "POST",
      url: `/update_primary_conditions`,
      baseURL,
      params: {
        primaryConditions: conditions,
        patientId
      },
      withCredentials: true,

      paramsSerializer: conditions => {
        return qs.stringify(conditions, { arrayFormat: "repeat" });
      }
    });
  }

  const convertArrToObj = (arr) => {
    if (!arr) {
      return {
        "copd": false,
        "diabetes": false,
        "hl": false,
        "ht": false,
        "hf": false
      }
    }
    let result = {}
    for (let el of arr) {
      if (el === "COPD") {
        result["copd"] = true;
      } else if (el === "Diabetes Mellitus") {
        result["diabetes"] = true;
      } else if (el === "Hyperlipidemia") {
        result["hl"] = true;
      } else if (el === "Hypertension") {
        result["ht"] = true;
      } else if (el === "Heart Failure") {
        result["hf"] = true;
      }
    }
    return result;
  }

  const shortenLables = arr => {
    let result = [];
    for (let el of arr) {
      if (el === "COPD") {
        result.push("copd")
      } else if (el === "Diabetes Mellitus") {
        result.push("diabetes");
      } else if (el === "Hyperlipidemia") {
        result.push("hl");
      } else if (el === "Hypertension") {
        result.push("ht");
      } else if (el === "Heart Failure") {
        result.push("hf");
      }
    }
    return result;
  };

  const extendLables = arr => {
    let result = [];
    for (let el of arr) {
      if (el === "copd") {
        result.push("COPD")
      } else if (el === "diabetes") {
        result.push("Diabetes Mellitus");
      } else if (el === "hl") {
        result.push("Hyperlipidemia");
      } else if (el === "ht") {
        result.push("Hypertension");
      } else if (el === "hf") {
        result.push("Heart Failure");
      }
    }
    return result;
  };

  const originalConditions = convertArrToObj(pcArr);
  const [checkedBoxes, setCheckedboxes] = useState(originalConditions);
  const [conditions, setConditions] = useState(shortenLables(pcArr));

  const handleChange = (state, setState, e) => {
    const item = e.target.value;
    const isChecked = e.target.checked
    setCheckedboxes({ ...checkedBoxes, [item]: isChecked })
    handleAddDeleteFromState(state, setState, isChecked, item)
  }

  const handleAddDeleteFromState = (state, setState, isActive, stateValToAddRemove) => {
    if (isActive) {
      setState([...state, stateValToAddRemove])
    } else {
      let tempState = [...state];
      let index = tempState.indexOf(stateValToAddRemove);
      if (index !== -1) {
        tempState.splice(index, 1);
        setState(tempState)
      }
    }
  }

  const conditionsCheckBoxes = [
    {
      value: "copd",
      key: "copd",
      label: "COPD"
    },
    {
      value: "diabetes",
      key: "diabetes",
      label: "Diabetes Mellitus"
    },
    {
      value: "hf",
      key: "hf",
      label: "Heart Failure"
    },
    {
      value: "hl",
      key: "hl",
      label: "Hyperlipidemia"
    },
    {
      value: "ht",
      key: "ht",
      label: "Hypertension"
    }
  ];

  const conditionsCheckBoxesToRender = () => {
    return conditionsCheckBoxes.map(item => {
      return (
        <div className="checkbox" key={item.key}>
          <label>
            <input
              type="checkbox"
              value={item.value}
              checked={checkedBoxes[item.value]}
              onChange={e => handleChange(conditions, setConditions, e)}
            />
            <span className="checkbox-label">{item.label}</span>
          </label>
        </div>
      );
    });
  }

  return (
    <React.Fragment>
      <div className="card card-body">
        <div className="all-options-container">
          <div className="criteria-container">
            {conditionsCheckBoxesToRender()}
          </div>
        </div>
        <button
          onClick={()=> {
          updatePrimaryConditions();
          updatePrimaryConditionsRedux({patientId, conditions: extendLables(conditions)});
          setPcEditMode(false)}}
        >Save</button>
      </div>
    </React.Fragment>
  );
});


const mapActionToProps = {
  updatePrimaryConditionsRedux
};

export default connect(
  null,
  mapActionToProps
)(PrimaryConditionsEdit);
