import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { Dropdown } from "semantic-ui-react";
import axios from "axios";
import { baseURL } from "../../config";
import applyCaseMiddleware from "axios-case-converter";
import { setShowNotification, setNotificationMessage } from "../../redux/notifications/actions";
import Notification from "../shared_components/Notification";

const sexOptions = [
  {
    key: "M",
    value: "M",
    text: "Male"
},
  {
    key: "F",
    value: "F",
    text: "Female"
  }
]

const insuranceTypeOptions = [
  {
    key: "Medicare",
    value: "Medicare",
    text: "Medicare"
  },
  {
    key: "Blue Cross",
    value: "Blue Cross",
    text: "Blue Cross"
  },
  {
    key: "Blue Shield",
    value: "Blue Shield",
    text: "Blue Shield"
  }
];

const OnboardingStep1 = () => {
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [birthday, setBirthday] = useState("");
  const [primaryInsuranceType, setPrimaryInsuranceType] = useState("Medicare");
  const [primaryInsuranceId, setPrimaryInsuranceId] = useState("");

  const [secondaryInsuranceType, setSecondaryInsuranceType] = useState("");
  const [secondaryInsuranceId, setSecondaryInsuranceId] = useState("");

  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [phoneM, setPhoneM] = useState("");
  const [phoneH, setPhoneH] = useState("");
  const [weight, setWeight] = useState("");
  const [sex, setSex] = useState("");

  const notificationMessage = useSelector(
    state => state.notificationsData.notificationMessage
  );
  const dispatch = useDispatch();

  const getFormGroup = (label, id, placeholder, value, setState) => {
    const getType = (id) => {
      if (id == "emal-onb") {
        return "email"
      }
      if (id == "birthday-onb") {
        return "date";
      }
      return "text"
    }
    return (
      <FormGroup>
        <Label for="first-onb">{label}</Label>
        <Input
          type={getType(id)}
          name="onb-input"
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={ (e) => {
            let newVal = e.target.value;
            // limit phone number length
            if ((id == "phone-h-onb" || id == "phone-m-onb") && newVal.length > 12) {
              newVal = newVal.substring(0, 12)
            }
            // format phone format
            if (id == "phone-h-onb") {
              if ((newVal.length == 3 || newVal.length == 7) && phoneH.length < newVal.length) {
                newVal += "-";
              }
            }
            if (id == "phone-m-onb") {
              if ((newVal.length == 3 || newVal.length == 7) && phoneM.length < newVal.length) {
                newVal += "-";
              }
            }
            setState(newVal)
          }
          }
        />
      </FormGroup>
    )
  }

  const getSexDropdown = () => {
    return (
      <div className="s-ui-dropdown-wrapper">
        <Dropdown
                placeholder='Sex'
                selection
                options={sexOptions}
                onChange={(e, params) => {
                sexOptions.forEach(option => {
                  if (option.value === params.value) {
                    setSex(option.value)
                  }
                });
              }}/>
        </div>
    )
  }

  const getInsuranceTypeDropdown = (type) => {
    return (
      <div className="s-ui-dropdown-wrapper">
        <Dropdown
          placeholder={type == 1 ?'Primary Insurance': "Secondary Insurance"}
          selection
          options={insuranceTypeOptions}
          onChange={(e, params) => {
            insuranceTypeOptions.forEach(option => {
              if (option.value === params.value) {
                type == 1
                  ? setPrimaryInsuranceType(option.value)
                  : setSecondaryInsuranceType(option.value);
              }
            });
          }} />
      </div>
    )
  }

  const saveAndVerifyMember = async () => {
    const client = applyCaseMiddleware(axios.create());
    let response;
    let url = "/insert/patient"
    try {
      response = await client({
        method: "POST",
        url,
        baseURL,
        params: {
          first,
          last,
          birthday,
          primaryInsuranceType,
          primaryInsuranceId,
          secondaryInsuranceType,
          secondaryInsuranceId,
          email,
          address,
          city,
          state,
          zip,
          phoneM,
          phoneH,
          weight,
          gender: sex
        },
        withCredentials: true
      });
      console.log(response);
      if (response.status == 200) {
        dispatch(setShowNotification(true));
        setTimeout(() => {
          dispatch(setShowNotification(false));
          dispatch(setNotificationMessage({}));
        }, 2000);
        dispatch(setNotificationMessage({
          text: `Patient has been saved and verified`,
          type: "success",
          isFor: "onboarding"
        }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="onb-step">
      <div className="notification-wrapper">
        {notificationMessage.isFor == "onboarding" && <Notification />}
      </div>
      <div className="step-header"> Step 1</div>
      <div className="forms-wrapper">
        <Form id="onb-col-1">
          {getFormGroup(
            "First Name (as shown on insurance card)",
            "first-onb",
            "",
            first,
            setFirst
          )}
          {getFormGroup(
            "Last Name (as shown on insurance card)",
            "last-onb",
            "",
            last,
            setLast
          )}
          {getFormGroup(
            "Date of Birth",
            "birthday-onb",
            "",
            birthday,
            setBirthday
          )}
          {getInsuranceTypeDropdown(1)}
          {getFormGroup(
            "Primary Insurance ID",
            "1-ins-id-onb",
            "",
            primaryInsuranceId,
            setPrimaryInsuranceId
          )}
          {getInsuranceTypeDropdown(2)}
          {getFormGroup(
            "Secondary Insurance ID",
            "2-ins-id-onb",
            "",
            secondaryInsuranceId,
            setSecondaryInsuranceId
          )}
        </Form>
        <Form id="onb-col-2">
          {getFormGroup("Email", "email-onb", "", email, setEmail)}
          {getFormGroup("Address", "address-onb", "", address, setAddress)}
          {getFormGroup("City", "city-onb", "", city, setCity)}
          {getFormGroup("State", "state-onb", "", state, setState)}
          {getFormGroup("Zip Code", "zip-onb", "", zip, setZip)}
          {getFormGroup("Phone (m)", "phone-m-onb", "", phoneM, setPhoneM)}
          {getFormGroup("Phone (h)", "phone-h-onb", "", phoneH, setPhoneH)}
        </Form>
        <Form id="onb-col-3">
          {getFormGroup("Weight", "weight-onb", "", weight, setWeight)}
          {getSexDropdown()}
        </Form>
      </div>
      <Button
        style={{ backgroundColor: "rgba(37, 149, 242, 0.8)" }}
        onClick={() => {
          saveAndVerifyMember();
        }}
      >
        Verify
      </Button>
    </div>
  );
}

export default OnboardingStep1;