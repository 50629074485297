import axios from "axios";
import { baseURL } from "../../config";
import applyCaseMiddleware from "axios-case-converter";

var qs = require("qs");

export const getMembers = async (params) => {
  const client = applyCaseMiddleware(axios.create());
  return client({
    method: "GET",
    url: `/members`,
    baseURL,
    params: {
      offset: params["offset"] ? params.offset : 0,
      searchTerm: params.searchTerm,
      memberFilter: params.currentFilter,
      clinicId: params.clinicId,
      userType: params.userType,
      showAllPanels: params['showAllPanels'] ? params.showAllPanels : 0, // for filter by clinic
      minDays: params['rpmDaysFilter'] ?  params.rpmDaysFilter.minDays : null,
      maxDays: params['rpmDaysFilter'] ? params.rpmDaysFilter.maxDays : null,
      minDaysPct: params['rpmDaysFilter'] ? params.rpmDaysFilter.minDaysPct : null,
      maxDaysPct: params['rpmDaysFilter'] ? params.rpmDaysFilter.maxDaysPct : null,
      weightScale: params['rpmDaysFilter'] ? params.rpmDaysFilter.filterByWeightscale : null,
      allPanelsRpm: params['rpmDaysFilter'] && params.rpmDaysFilter.allPanels ? 1 : 0,
      sortByMinutes: params.sortByMinutes ? 1 : 0
    },
    withCredentials: true,

    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    }
  });
}
