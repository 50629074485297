import React from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { white } from "color-name";

const ContextMenuProfileSettings = React.memo(function ContextMenuProfileSettings(props) {
  const { toggleContextMenu, isDropdownOpen } = props;

  return (
    <Dropdown
      isOpen={isDropdownOpen}
      toggle={() => toggleContextMenu()}
      className="see-more"
    >
      <DropdownToggle
        tag="span"
        data-toggle="profile"
        aria-expanded={isDropdownOpen}
      >
        {
          <FontAwesomeIcon
            icon={faCog}
            style={{ color: white, fontSize: "18px", marginRight: "10px" }}
          />
        }
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          onClick={() => {
            window.open("https://dev.salusive.com/auth/logout/","_self")
            // toggleContextMenu();
          }}
        >
            Log Out
        </DropdownItem>
        {/* add more profile settings options */}
      </DropdownMenu>
    </Dropdown>
  );
});

export default ContextMenuProfileSettings;
