import { getEncounters } from "./util";

export const FETCH_REQUEST = "fetctRequest";
export const FETCH_SUCCESS = "fetctSuccess";
export const FETCH_ERROR = "fetctError";

const fetchRequest = () => {
  return {
    type: FETCH_REQUEST
  };
};

const fetchSuccess = encounters => {
  return {
    type: FETCH_SUCCESS,
    payload: encounters
  };
};

const fetchError = error => {
  return {
    type: FETCH_ERROR,
    payload: error
  };
};

export const fetchEncounters = params => async dispatch => {
  dispatch(fetchRequest());
  try {
    const encounters = await getEncounters(params);
    let data = encounters.data;
    if (typeof data === "string") {
      data = JSON.parse(data);
    }
    dispatch(fetchSuccess({ [params.id]: data }));
  } catch (error) {
    console.log(error);
    dispatch(fetchError(error));
  }
};
