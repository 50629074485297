import {
  FETCH_ENCOUNTERS_REQUEST, FETCH_ENCOUNTERS_SUCCESS, FETCH_ENCOUNTERS_ERROR, UPDATE_ENCOUNTER_NOTES
} from "./actions";

export default (
  state = {
    encounters: {},
    isFetching: false,
    error: null
  },
  action
) => {
  switch (action.type) {
    case FETCH_ENCOUNTERS_REQUEST:
      return { ...state, error: null, isFetching: true };
    case FETCH_ENCOUNTERS_SUCCESS:
      return {
        ...state,
        encounters: {
          ...state.encounters,
          ...action.payload
        },
        isFetching: false
      };
    case UPDATE_ENCOUNTER_NOTES:
      let updatedEncounters = state.encounters;
      updatedEncounters[action.payload.patientId][action.payload.time]['text'] = action.payload.text
      updatedEncounters[action.payload.patientId][action.payload.time]['callDuration'] = action.payload.callDuration
      return {
        ...state,
        encounters: { ...updatedEncounters },
        isFetching: false
      };
    case FETCH_ENCOUNTERS_ERROR:
      return { ...state, error: action.payload, isFetching: false };
    default:
      return state;
  }
};
