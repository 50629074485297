import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleDoubleRight,
  faAngleRight
} from "@fortawesome/free-solid-svg-icons";
import { alertsListSpinner } from '../spinners'


import TasksRow from "./TasksRow";
import TasksFilter from './TasksFilter';
import Notification from '../shared_components/Notification';
import { fetchTasksData } from "../../redux/tasks/actions";

const TasksContainer = React.memo(function TasksContainer(props) {
  const TASKS_DISPLAYED = 13;
  const { tasks, totalTasksCount, isFetching, fetchTasksData, showNotification, notificationMessage } = props;
  const [currentPage, setCurrentPage] = useState(1)
  const [filterParams, setFilterParams] = useState({});

  const totalPagesCount = Math.ceil(totalTasksCount / TASKS_DISPLAYED);

  const getFilterParams = (params) => {
    setFilterParams(params)
  }
  useEffect(() => {
    let offset = currentPage === 1 ? 0 : (currentPage - 1) * TASKS_DISPLAYED;
    fetchTasksData({ ...filterParams, offset });
  }, [filterParams, currentPage]);

  if (!tasks) {
    return (<>An Error has occured</>)
  } else {
    return (
      <React.Fragment>
        <div className="alerts-and-footer">
          <div className="alerts-container">
            <TasksFilter getParams={getFilterParams} />
            {isFetching ? (
              <div className="alerlts-list-spinner-wrapper">
                {alertsListSpinner()}
              </div>
            ) :
              <>
                {tasks.map((task, i) => (<TasksRow key={i} onClick={() => { }} task={task} />))}
              </>
            }
          </div>
          <div className="card-footer-custom">
            {tasks && (
              <ul className="pagination">
                <li
                  className={`page-item first-item ${
                    currentPage === 1 ? "disabled" : ""
                    }`}
                >
                  <div onClick={() => setCurrentPage(1)} className="page-link">
                    <div className="icon-styled-pages-navigation">
                      <FontAwesomeIcon icon={faAngleDoubleLeft} />
                    </div>
                  </div>
                </li>
                <li
                  className={`page-item previous-item ${
                    currentPage === 1 ? "disabled" : ""
                    }`}
                >
                  <div
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className="page-link"
                  >
                    <div className="icon-styled-pages-navigation">
                      <FontAwesomeIcon icon={faAngleLeft} />
                    </div>
                  </div>
                </li>
                <li
                  className={`page-item next-item ${
                    tasks.length < TASKS_DISPLAYED ? "disabled" : ""
                    }`}
                >
                  <div
                    onClick={() => setCurrentPage(currentPage + 1)}
                    className="page-link"
                  >
                    <div className="icon-styled-pages-navigation">
                      <FontAwesomeIcon icon={faAngleRight} />
                    </div>
                  </div>
                </li>
                <li
                  className={`page-item next-item ${
                    currentPage === totalPagesCount ? "disabled" : ""
                    }`}
                >
                  <div
                    onClick={() => setCurrentPage(totalPagesCount)}
                    className="page-link"
                  >
                    <div className="icon-styled-pages-navigation">
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </div>
                  </div>
                </li>
              </ul>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
});

const mapActionsToProps = { fetchTasksData }

const mapStateToProps = state => {
  const { tasksData: { tasks, totalCount, isFetching }, notificationsData: { showNotification, notificationMessage } } = state;
  return {
    tasks,
    totalTasksCount: totalCount,
    isFetching,
    showNotification,
    notificationMessage
  };
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(TasksContainer);

