import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import BarLoader from "react-spinners/BarLoader";
import { Table } from 'reactstrap'

import './PatientRecentStats.css'


const PatientRecentStats = React.memo(function PatientRecentStats(props) {
  const {currentPatient, currentMetric, recentStats, chartsFetching} = props;
  const recentStatsToShow = recentStats[currentPatient];
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  useEffect(() => {
    if (recentStats && currentPatient) {
      if (currentMetric && currentMetric == 'pulse') {
        setNoDataAvailable(true);
      } else  {
        setNoDataAvailable(false);
      }
    }
  }, [currentMetric, recentStats, currentPatient]);

const spinner = () => {
  return (
    <div className="loader">
      <BarLoader
        height={4}
        width={100}
        color={"gray"}
        loading={true}
      />
    </div>
  )
}

  if (chartsFetching || !recentStatsToShow) {
    return <div className="stats-container">{spinner()}</div>;
  } else if (noDataAvailable || !currentMetric) {
    return (
      <div className="stats-container no-stats-data">
          <div>No data available</div>
      </div>
    );
  } else {
    return (
      <Table className="stats borderless">
        <tbody>
          <tr>
            <td className="headers">Vieving</td>
            <td className="headers">Baseline</td>
          </tr>
          <tr className="b-bottom">
            <td className="values b-left">
              {recentStatsToShow.membership_days || ""} days
            </td>
            <td className="values">
              {currentMetric == "blood_pressure"
                ? recentStatsToShow.baseline_bp || "-"
                : recentStatsToShow.baseline_bg || "-"}
            </td>
          </tr>
          <tr>
            <td className="headers b-left">7 Day Avg</td>
            <td className="headers">30 Day Avg</td>
          </tr>
          <tr className="b-bottom">
            <td className="values b-left">
              {currentMetric == "blood_pressure"
                ? recentStatsToShow["7_day_average_bp"] || "-"
                : recentStatsToShow["7_day_average_bg"] || "-"}
            </td>
            <td className="values">
              {currentMetric == "blood_pressure"
                ? recentStatsToShow["30_day_average_bp"] || "-"
                : recentStatsToShow["30_day_average_bg"] || "-"}
            </td>
          </tr>
          <tr>
            <td className="headers b-left">Priority Alerts</td>
            <td className="headers">Critical Alerts</td>
          </tr>
          <tr>
            <td className="values">
              {currentMetric == "blood_pressure"
                ? recentStatsToShow.priority_alerts_bp || "-"
                : recentStatsToShow.priority_alerts_bg || "-"}
            </td>
            <td className="values">
              {currentMetric == "blood_pressure"
                ? recentStatsToShow.critical_alerts_bp || "-"
                : recentStatsToShow.critical_alerts_bg || "-"}
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }
})

const mapStateToProps = state => {
  const { recentStatsData: { stats }, chartData: {  isFetching, currentMetric } } = state;
  return {
    recentStats: stats,
    currentMetric,
    chartsFetching: isFetching
  };
};

export default connect(
  mapStateToProps,
  null
)(PatientRecentStats);
