import React, { useState } from "react";
import { connect } from "react-redux";

import PersistentInfoTop from "../patient_screen/PersistentInfoTop";
import Sidebar from "../Sidebar";
import Notepad from "../patient_screen/Notepad";
import OverviewLeft from '../document_screen/OverviewLeft';
import { setStartDate, setEndDate } from '../../redux/pdf_options/actions';
import PDFtoSend from './PDFtoSend';
import Dropdown from "react-multilevel-dropdown";

import "./SignScreen.css";

export const valueTextMapCall = {
  call_in_1: "Call Connected Inbound",
  call_out_1: "Call Connected Outbound",
  call_0: "Call Attempt Unable to Contact",
  voicemail_out: "Call Attempt Voicemail",
  voicemail_in: "Voicemail Received",
};

export const valueTextMapText = {
  text_out: "Text Sent",
  text_in: "Text Received",
};

export const valueTextMapEmail = {
  email_out: "Email Sent",
  email_in: "Email Received",
  fax: "Fax Sent / Received"
};

export const valueTextMapAdmin = {
  escalation: "Escalation/Consult with Clinical Staff",
  peer_consult: "Consult with Peer",
  chart_review: "Chart Review/Audit",
  task_assignment: "Task Assignment"
};

const SignScreen = React.memo(function SignScreen(props) {
  const { currentPatient, patientObj, showPDF} = props;
  const [ minutes, setMinutes ] = useState("");
  const [callStatus, setCallStatus] = useState({value: '', text: ''});



  return (
    <div className="container-wrapper-pt-screen container-fluid">
      {/* <div className="container-patient-screen "> */}
      <Sidebar />
      {!currentPatient ? null : (
        <div className="persistent-info-top-and-overview">
          <div className="persistent-info-top">
            <PersistentInfoTop nextScreen="complete" minutes={minutes ? minutes : 0 } callStatus={callStatus.value} />
          </div>
          <div className="row-main overview-and-document">
            <div className="overview-fullscreen-doc">
              {!showPDF ? (
                <OverviewLeft patientObj={patientObj} id="overview-left" />
              ) : (
                <PDFtoSend />
              )}
            </div>
            <div className="fixed">
              <div className="sign-col" style={{ flex: 7 }}>
                <div className="alerts-header sign-screen">Final Steps</div>
                <div className="alert-row row final-steps">
                  <form className="form-inline">
                    <div className="minutes-and-label">
                      <label
                        htmlFor="inputMinutes"
                        className="t-notes-input-label"
                      >
                        Enter minutes logged
                      </label>
                      <div className="t-notes-input-wrapper">
                        <input
                          type="text"
                          id="inputMinutes"
                          value={minutes}
                          onChange={e => setMinutes(e.target.value)}
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="alert-row row final-steps">
                  <div id="call-log-text">Call Log</div>
                  <Dropdown title={callStatus.text ? callStatus.text : "Select status"} position="right" buttonClassName="nested-dd-button">
                    <Dropdown.Item>
                      Call{" "}
                      <Dropdown.Submenu position="right" className="dd-menu">
                        {Object.keys(valueTextMapCall).map((status, i) => {
                          return <Dropdown.Item className="sub-menu-item" key={i} onClick={() => {console.log(status);  setCallStatus({ value: status, text: valueTextMapCall[status] })}}> {valueTextMapCall[status]}</Dropdown.Item>
                        })}
                      </Dropdown.Submenu>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      Text
                      <Dropdown.Submenu position="right" className="dd-menu">
                        {Object.keys(valueTextMapText).map((status, i) => {
                          return <Dropdown.Item className="sub-menu-item" key={i} onClick={() => { setCallStatus({ value: status, text: valueTextMapText[status] })}}> {valueTextMapText[status]}</Dropdown.Item>
                        })}
                      </Dropdown.Submenu>
                    </Dropdown.Item>
                    <Dropdown.Item>Email/Fax
                      <Dropdown.Submenu position="right" className="dd-menu">
                        {Object.keys(valueTextMapEmail).map((status, i) => {
                          return <Dropdown.Item className="sub-menu-item" key={i} onClick={() => { setCallStatus({ value: status, text: valueTextMapEmail[status] })}}> {valueTextMapEmail[status]}</Dropdown.Item>
                        })}
                      </Dropdown.Submenu>
                    </Dropdown.Item>
                    <Dropdown.Item>Admin
                      <Dropdown.Submenu position="right" className="dd-menu">
                        {Object.keys(valueTextMapAdmin).map((status, i) => {
                          return <Dropdown.Item className="sub-menu-item" key={i} onClick={() => { setCallStatus({ value: status, text: valueTextMapAdmin[status] }) }}> {valueTextMapAdmin[status]}</Dropdown.Item>
                        })}
                      </Dropdown.Submenu>
                    </Dropdown.Item>
                  </Dropdown>
                </div>
              </div>
              <div className="notepad-column" style={{ flex: 5 }}>
                <div className="notepad-wrapper doc-screen">
                  <Notepad />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* </div> */}
    </div>
  );
});

const mapStateToProps = state => {
  const {
    patientBioData: { bios },
    patientMetadata: { patientPage: { currentPatient } },
    pdfOptions: { showPDF }
  } = state;
  const patientObj = bios[currentPatient];
  return {
    patientObj,
    currentPatient,
    showPDF,
  };
};

const mapActionsToProps = { setStartDate, setEndDate }

export default connect(
  mapStateToProps,
  mapActionsToProps
)(SignScreen);

