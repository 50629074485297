import {
  FETCH_MEMBERS_REQUEST,
  FETCH_MEMBERS_SUCCESS,
  FETCH_MEMBERS_ERROR,
  REMOVE_FROM_PANEL_REDUX,
  ADD_PT_TO_CALL_Q_REDUX,
  UPDATE_MEMBER_STATUS,
  SET_SORT_BY_MINUTES,
  SET_CURRENT_FILTER,
  SET_RPM_FILTER
} from "./actions";

export default (
  state = {
    members: [],
    totalCount: 0,
    currentFilter: 'my_panel',
    rpmFilter: {
      minDays: null,
      maxDays: null,
      minDaysPct: null,
      maxDaysPct: null,
      filterByWeightscale: false,
      allPanels: false
    },
    offset: 0,
    showNotification: false,
    notificationMessage: null,
    sortByMinutes: true, // if set to false - sort by rpm
    isFetching: false,
    error: null
  }, action) => {
  let updatedMember = {};
  switch (action.type) {
    case FETCH_MEMBERS_REQUEST:
      return { ...state, error: null, isFetching: true };
    case FETCH_MEMBERS_SUCCESS:
      const {
        members: { members, totalCount },
        currentFilter,
        offset,
        searchTerm,
        clinicId
      } = action.payload;

      return {
        ...state,
        members,
        totalCount,
        currentFilter: currentFilter,
        offset,
        isFetching: false
      };
    case FETCH_MEMBERS_ERROR:
      return { ...state, error: action.payload, isFetching: false };
    case REMOVE_FROM_PANEL_REDUX:
      let updatedMembers = [];
      for (let i = 0; i < state.members.length; i++) {
        if (state.members[i]["patientId"] !== action.payload.patientId) {
          updatedMembers.push(state.members[i]);
        }
      }
      return { ...state, members: updatedMembers };
    case SET_SORT_BY_MINUTES:
      return { ...state, sortByMinutes: action.payload };
    case SET_CURRENT_FILTER:
      return { ...state, currentFilter: action.payload };
    case SET_RPM_FILTER:
      return { ...state, rpmFilter: action.payload };
    case ADD_PT_TO_CALL_Q_REDUX:
      let tempMembers = state.members;
      for (let i = 0; i < state.members.length; i++) {
        if (state.members[i]["patientId"] === action.payload.patientId) {
          updatedMember = {
            ...state.members[i],
            isInCallQueue: action.payload.isInCallQueue
          };
          tempMembers = [
            ...state.members.slice(0, i),
            updatedMember,
            ...state.members.slice(i + 1)
          ];
        }
      }
      return { ...state, members: tempMembers };
    case UPDATE_MEMBER_STATUS:
      let tempMembers2 = state.members;
      for (let i = 0; i < state.members.length; i++) {
        if (state.members[i]["patientId"] === action.payload.patientId) {
          updatedMember = {
            ...state.members[i],
            status: action.payload.status
          };
          tempMembers2 = [
            ...state.members.slice(0, i),
            updatedMember,
            ...state.members.slice(i + 1)
          ];
        }
      }
      return { ...state, members: tempMembers2 };
    default:
      return state;
  }
};
