import {
  FETCH_ALERTS_REQUEST,
  FETCH_ALERTS_SUCCESS,
  FETCH_ALERTS_ERROR,
  UPDATE_CLAIMED_FLAG_ALERT,
} from './actions';

export default (
  state = {
    alerts: [],
    totalCount: 0,
    offset: 0,
    filterParams: {
      pod: undefined,
      priority: [],
      selectedUserId: "",
      showAllPanels: false,
      status: [],
      time: 1,
      type: []
    },
    isFetching: false,
    error: null
  }, action) => {

  switch (action.type) {
    case FETCH_ALERTS_REQUEST:
      return { ...state, error: null, isFetching: true };
    case FETCH_ALERTS_SUCCESS:
      const { alerts, total_count } = action.payload.alerts;
      const { filterParams, offset, searchTerm } = action.payload;
      if (searchTerm) {
        return {
          ...state,
          alerts,
          totalCount: total_count,
          filterParams: searchTerm,
          isFetching: false
        };
      }
    // replace the old alerts list with a new one if the filters changed
    if (offset === 0) {
      return {
        ...state,
        offset: 0,
        alerts,
        filterParams,
        totalCount: total_count,
        isFetching: false
      };
      // append to the existing alerts list if the filter is the same
    } else {
      return {
        ...state,
        alerts: [...state.alerts,  ...alerts],
        totalCount: total_count,
        filterParams,
        offset,
        isFetching: false
      };
    }
    case FETCH_ALERTS_ERROR:
      return { ...state, error: action.payload, isFetching: false };
    case UPDATE_CLAIMED_FLAG_ALERT:
      let updatedAlert = {};
      for (let i = 0; i < state.alerts.length; i++) {
        if (state.alerts[i]["_id"] === action.payload._id) {
          updatedAlert = {
            ...state.alerts[i],
            claimed_flag: action.payload.claimed_flag
          };
          state.alerts = [
            ...state.alerts.slice(0, i),
            updatedAlert,
            ...state.alerts.slice(i + 1)
          ];
        }
      }
      return { ...state, alerts: state.alerts };
    default:
      return state;
  }
};
