import {
  SET_CURRENT_PATIENT_ALERTS_PAGE,
  ADD_PATIENT_ALERTS_PAGE,
  UPDATE_PATIENTS_ALERTS_PAGE,
  SET_CURRENT_PATIENT_PATIENTS_PAGE,
  SET_IS_LOGGEDIN,
  SET_CURRENT_METRIC_ALERTS_PAGE,
  SET_CURRENT_METRIC_PATIENT_PAGE
} from './actions'

export default (
  state = {
    alertsPage: {
      currentPatient: null,
      openTabs: [],
      currentMetric: "blood_pressure"
    },
    patientPage: {
      currentPatient: null,
      currentMetric: "blood_pressure"
    },
    isLoggedIn: true
  }, action) => {
  switch (action.type) {
    case SET_IS_LOGGEDIN:
      return { ...state, isLoggedIn: action.payload };
    //alerts page
    case SET_CURRENT_PATIENT_ALERTS_PAGE:
      return { ...state, alertsPage: {...state.alertsPage, currentPatient: action.payload}};
    case SET_CURRENT_METRIC_ALERTS_PAGE:
      return { ...state, alertsPage: { ...state.alertsPage, currentMetric: action.payload } };
    case ADD_PATIENT_ALERTS_PAGE:
      return { ...state, alertsPage: {...state.alertsPage, openTabs: [action.payload, ...state.alertsPage.openTabs]}}
    case UPDATE_PATIENTS_ALERTS_PAGE:
      return { ...state, alertsPage: {...state.alertsPage, openTabs: action.payload}}
      //patients page
    case SET_CURRENT_PATIENT_PATIENTS_PAGE:
      return { ...state, patientPage: { ...state.patientPage, currentPatient: action.payload } };
    case SET_CURRENT_METRIC_PATIENT_PAGE:
      return { ...state, patientPage: { ...state.patientPage, currentMetric: action.payload } };
    default:
    return state;
  }
};
