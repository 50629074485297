import { getPatientStats } from "./util";

export const FETCH_STATS_REQUEST = "fetchStatsRequest";
export const FETCH_STATS_SUCCESS = "fetchStatsSucess";
export const FETCH_STATS_ERROR = "fetchStatsError";

const fetchRequest = () => {
  return {
    type: FETCH_STATS_REQUEST
  };
};

const fetchSuccess = statsData => {
  return {
    type: FETCH_STATS_SUCCESS,
    payload: statsData 
  };
};

const fetchError = error => {
  return {
    type: FETCH_STATS_ERROR,
    payload: error
  };
};

export const fetchPatientStats = (id) => async dispatch => {
  dispatch(fetchRequest());
  try {
    const statsData = await getPatientStats(id);
    let stats = statsData.data;
    // if (typeof stats === "string") {
    //   stats = JSON.parse(stats);
    // }
    dispatch(fetchSuccess({[id]: stats}));
  } catch (e) {
    console.log(e);
    dispatch(fetchError(e));
  }
};
