import { getPatientCustomStats } from "./util";

export const FETCH_CUSTOM_STATS_REQUEST = "fetchCustomStatsRequest";
export const FETCH_CUSTOM_STATS_SUCCESS = "fetchCustomStatsSucess";
export const FETCH_CUSTOM_STATS_ERROR = "fetchCustomStatsError";
export const SET_CUSTOM_STATS_SELECTED = "setCustomStatsSelected";

const fetchRequest = () => {
  return {
    type: FETCH_CUSTOM_STATS_REQUEST
  };
};

const fetchSuccess = statsData => {
  return {
    type: FETCH_CUSTOM_STATS_SUCCESS,
    payload: statsData
  };
};


const fetchError = error => {
  return {
    type: FETCH_CUSTOM_STATS_ERROR,
    payload: error
  };
};

export const setCustomStatsSelected = bool => dispatch => {
  dispatch(
    {
      type: SET_CUSTOM_STATS_SELECTED,
      payload: bool
    }
  )
};

export const fetchPatientCustomStats = (id, metric, start, end) => async dispatch => {
  dispatch(fetchRequest());
  try {
    const statsData = await getPatientCustomStats(id, metric, start, end);
    let stats = statsData.data;
    if (typeof stats === "string") {
      stats = JSON.parse(stats);
    }
    dispatch(fetchSuccess({ id, metric, stats }));
  } catch (e) {
    console.log(e);
    dispatch(fetchError(e));
  }
};