import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import classnames from "classnames";
import AlertsHistoryRow from "./AlertsHistoryRow";

import "./AlertsHistory.css";

const AlertsHistory = React.memo(function AlertsHistory(props) {
  const {
    alertsHistory,
    currentPatient,
    currentMetricPreview,
    currentMetricPtPage,
    metricForPdf,
    listForPdf,
    view,
    startDate,
    endDate,
    refsArr
  } = props;

  const [alertsList, setAlertsList] = useState([]);

  let currentMetric =
    view == "preview" ? currentMetricPreview : currentMetricPtPage;
  let theMetric = metricForPdf ? metricForPdf : currentMetric;
  const monthsSelected = Math.ceil((endDate - startDate) / 2678400000); // divide by 31 days in ms

  useEffect(() => {
    let res = [];
    if (listForPdf && listForPdf.length > 0) {
      for (let i = 0; i < listForPdf.length - 1; i++) {
        if (
          listForPdf[i]["alert_time"] == listForPdf[i + 1]["alert_time"] &&
          listForPdf[i]["alert_value"] == listForPdf[i + 1]["alert_value"]
        ) {
          i++;
        }
        res.push(listForPdf[i]);
      }
      setAlertsList(res);
    } else {
      if (currentPatient && alertsHistory[currentPatient] && theMetric) {
        setAlertsList(alertsHistory[currentPatient][theMetric]);
      }
    }
  }, [currentPatient, theMetric, alertsHistory]);

  const getMonthsOfData = () => {
    let prevMonth = Number(
      moment(alertsList[0].alert_time)
        .format("MM/DD/YYYY")
        .split("/")[0]
    );
    let monthCount = 1;
    for (let i = alertsList.length - 1; i >= 0; i--) {
      let currentMonth = Number(
        moment(alertsList[i].alert_time)
          .format("MM/DD/YYYY")
          .split("/")[0]
      );
      if (currentMonth !== prevMonth) {
        prevMonth = currentMonth;
        monthCount++;
      }
    }
    return monthCount;
  };

  let monthsOfData =
    alertsList && alertsList.length > 0 ? getMonthsOfData() : 0;

  const getAlertsDataByPages = () => {
    if (alertsList.length > 90 && monthsSelected == 1) {
      return splitOneMonthDataIntoBlocks();
    }
    let result = [[]];
    let prevMonth = Number(
      moment(alertsList[alertsList.length - 1].alert_time)
        .format("MM/DD/YYYY")
        .split("/")[0]
    );
    let monthCount = 1;
    for (let i = alertsList.length - 1; i >= 0; i--) {
      let currentMonth = Number(
        moment(alertsList[i].alert_time)
          .format("MM/DD/YYYY")
          .split("/")[0]
      );
      if (currentMonth !== prevMonth) {
        prevMonth = currentMonth;
        monthCount++;
        result.push([]);
      }
      result[monthCount - 1].push(alertsList[i]);
    }

    // this is a temporaly fix, which will add empty 1/2 page if there's no data for the selected month
    while (monthCount < monthsSelected) {
      result.push([]);
      monthCount++;
    }
    return result;
  };

  const splitOneMonthDataIntoBlocks = () => {
    let result = [[]];
    let currentBlock = 0;
    let breakPoint = 90;
    for (let i = alertsList.length - 1, j = 0; i >= 0; i--, j++) {
      if (j > breakPoint) {
        currentBlock++;
        breakPoint += 90;
        result.push([]);
      }
      result[currentBlock].push(alertsList[i]);
    }
    return result;
  };

  let alertsDataByPages =
    metricForPdf && alertsList.length > 0 ? getAlertsDataByPages() : [];

  const getPages = () => {
    if (monthsOfData == 1 || listForPdf.length < 90) {
      return getOneMonthPerPage();
    } else if (listForPdf.length > 90) {
      return getOneMonthPerPageFull();
    } else {
      getTwoMonthsPerPage();
    }
  };

  const getOneMonthPerPage = () => {
    return alertsDataByPages.map((page, i) => {
      return (
        <>
          <div className="alerts-history-month">
            {page &&
              page.length > 0 &&
              moment(page[0].alert_time)
                .format("LL")
                .split(" ")[0]}
          </div>
          <div
            className="alerts-history-page"
            key={i}
            style={{ marginBottom: "10px" }}
          >
            {threeColumns(page)}
          </div>
        </>
      );
    });
  };

  const getOneMonthPerPageFull = () => {
    return alertsDataByPages.map((page, i) => {
      return (
        <div key={i}
          ref={refsArr[i]}
          className="al-history-long">
          <div className="alerts-history-month">
            {page &&
              page.length > 0 &&
              moment(page[0].alert_time)
                .format("LL")
                .split(" ")[0]}
          </div>
          <div
            className="alerts-history-page"
            key={i}
            style={{ marginBottom: "10px" }}
          >
            {threeColumns(page)}
          </div>
        </div>
      );
    });
  };

  const getPointers = num => {
    for (let i = 0, k = 0, j = 1; i < 10000; i++, k += 2, j += 2) {
      if (num === i) {
        return { k, j };
      }
    }
  };

  const getTwoMonthsPerPage = () => {
    let k = 0;
    let j = 1;
    return alertsDataByPages.map((page, i) => {
      k = getPointers(i).k;
      j = getPointers(i).j;
      if (i > 1 && i * 2 > alertsDataByPages.length - 1) {
        return null;
      }
      return (
        <div
          key={i}
          ref={refsArr[i]}
          className="alerts-history-2-mo-per-page main-page"
        >
          <div className="alerts-history-page" style={{ marginBottom: "20px" }}>
            <div className="alerts-history-month">
              {alertsDataByPages[k] &&
                alertsDataByPages[k].length > 0 &&
                moment(alertsDataByPages[k][0].alert_time)
                  .format("LL")
                  .split(" ")[0]}
            </div>
            {alertsDataByPages[k] && threeColumns(alertsDataByPages[k])}
          </div>
          <div
            className="alerts-history-page"
            style={{ marginBottom: "20px", marginTop: "10px" }}
          >
            <div className="alerts-history-month">
              {alertsDataByPages[j] &&
                alertsDataByPages[j].length > 0 &&
                moment(alertsDataByPages[j][0].alert_time)
                  .format("LL")
                  .split(" ")[0]}
            </div>
            {alertsDataByPages[j] && threeColumns(alertsDataByPages[j])}
          </div>
        </div>
      );
    });
  };

  const threeColumns = list => {
    if (!list) {
      return null;
    }
    return (
      <div className={classnames({ "one-col": list.length === 1, "two-col": list.length === 2,  "three-col": list.length >=3  }, "al-history-col")}>
        <div className="alerts-history-col">
          {list.slice(0, Math.ceil(list.length / 3)).map((alert, i) => {
            return (
              <AlertsHistoryRow
                alert={alert}
                key={i}
                hideMetric={true}
                metricForPdf={metricForPdf}
              />
            );
          })}
        </div>
        <div className="alerts-history-col">
          {list
            .slice(Math.ceil(list.length / 3), 2 * Math.ceil(list.length / 3))
            .map((alert, i) => {
              return (
                <AlertsHistoryRow
                  alert={alert}
                  key={i}
                  hideMetric={true}
                  metricForPdf={metricForPdf}
                />
              );
            })}
        </div>
        <div className="alerts-history-col">
          {list.slice(2 * Math.ceil(list.length / 3)).map((alert, i) => {
            return (
              <AlertsHistoryRow
                alert={alert}
                key={i}
                hideMetric={true}
                metricForPdf={metricForPdf}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const getBpHeader = () => {
    return (
      <div className="alert-history-row top-row">
        <div className="alert-history-col">
          <div className="alert-data">Date</div>
        </div>
        <div className="alert-history-col">
          <div className="alert-data" style={{ paddingLeft: "10px" }}>
            Time
          </div>
        </div>
        <div className="alert-history-col alert-val-history">
          <div className="alert-data alert-val-type">HR</div>
          <div className="alert-data alert-val">
            <div>BP</div>
          </div>
        </div>
      </div>
    );
  }

  const getHeader3Col = (metric) => {
    return (
      <div className="alert-history-row top-row">
        <div className="alert-history-col">
          <div className="alert-data">Date</div>
        </div>
        <div className="alert-history-col">
          <div className="alert-data" style={{ paddingLeft: "10px" }}>Time</div>
        </div>
        <div className="alert-history-col">
          <div className="alert-data">{metric}</div>
        </div>
      </div>
    );
  };

  const oneColumn = () => {
    return (
      <>
        {!metricForPdf && theMetric == "blood_pressure" && getBpHeader()}
        {!metricForPdf && theMetric == "glucose" && getHeader3Col("BG")}
        {!metricForPdf && theMetric == "weight" && getHeader3Col("W")}
        {alertsList.map((alert, i) => {
          return <AlertsHistoryRow alert={alert} key={i} />;
        })}
      </>
    );
  };

  if (!alertsList || alertsList.length === 0)
    return <div className="message-centered"> No data available</div>;
  return <>{metricForPdf ? getPages() : oneColumn()}</>;
});

const mapStateToProps = state => {
  const {
    alertsHistoryData: { alertsHistory },
    patientMetadata: {
      alertsPage: { currentMetric },
      patientPage
    },
    pdfOptions: { startDate, endDate }
  } = state;
  return {
    alertsHistory,
    currentMetricPreview: currentMetric,
    currentMetricPtPage: patientPage.currentMetric,
    startDate,
    endDate
  };
};


export default connect(
  mapStateToProps,
  null
)(AlertsHistory);
