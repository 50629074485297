import { getPatientAlertHistory } from "./util";

export const FETCH_ALERTS_HISTORY_REQUEST = "fetchAlertsHistoryRequest";
export const FETCH_ALERTS_HISTORY_SUCCESS = "fetchAlertsHistorySucess";
export const FETCH_ALERTS_HISTORY_ERROR = "fetchAlertsHistoryError";


const fetchRequest = () => {
  return {
    type: FETCH_ALERTS_HISTORY_REQUEST
  }
}

const fetchSuccess = (alertsHistoryData) => {
  return {
    type: FETCH_ALERTS_HISTORY_SUCCESS,
    payload: alertsHistoryData
  }
}

const fetchError = (error) => {
  return {
    type: FETCH_ALERTS_HISTORY_ERROR,
    payload: error
  }
}

export const fetchAlertsHistory = (id, metric, start, end) => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    const alertsHistoryData = await getPatientAlertHistory(id, metric, start, end)
    let history = alertsHistoryData.data
    if (typeof history === 'string') {
      history = JSON.parse(history)
    }
    dispatch(fetchSuccess({ id, metric, history }));
  } catch (e) {
    console.log(e)
    dispatch(fetchError(e))
  }
}




