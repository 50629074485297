import { FETCH_USER_REQUEST, FETCH_USER_SUCCESS, FETCH_USER_ERROR } from "./actions";

export default (
  state = {
    userId: null,
    fullName: null,
    isFetching: false,
    hasPanel: false,
    userType: null,
    clinicId: null,
    error: null
  },
  action
) => {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return { ...state, error: null, isFetching: true };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        userId: action.payload.userId,
        fullName: action.payload.fullName,
        userType: action.payload.userType,
        clinicId: action.payload.clinicId,
        hasPanel: action.payload.hasPanel ? true : false,
        isFetching: false
      };
    case FETCH_USER_ERROR:
      return { ...state, error: action.payload, isFetching: false };
    default:
      return state;
  }
};
