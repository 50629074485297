import React from 'react';
import { connect } from 'react-redux'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { baseURL } from "../../config";
import { setShowNotification, setNotificationMessage } from "../../redux/notifications/actions";
import { removeFromPanelRedux, addPtToCallQRedux } from "../../redux/members/actions";
import { updateClaimedFlag as updateClaimedFlagAlerts} from "../../redux/alerts/actions"

const ContextMenuDropdown = React.memo(function ContextMenuDropdown(props) {
  const { toggleDelegationModal, toggleEscalationModal, toggleContextMenu, isDropdownOpen, membersPage, member, alert, userId, setShowNotification, setNotificationMessage, removeFromPanelRedux, addPtToCallQRedux, updateClaimedFlagAlerts } = props;
  const addToPanel = async (id, name) => {
    let response;
    try {
      response = await axios({
        method: "POST",
        url: `/claim_patient?patient_id=${id}&patient_name=${name}`,
        baseURL,
        withCredentials: true
      });
      if (response.status === 200) {
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: `${name} has been added to your panel`, type: 'success', isFor: 'members' })
      }
    } catch (err) {
      console.log(err)
      if (err.response.status == 401) {
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: 'You are not authorized to claim members', type: 'danger', isFor: 'members' });
      } else {
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: "Member can't be claimed", type: 'danger', isFor: 'members' });
      }
    }
  }

  const deleteFromPanel = async () => {
    let response;
    try {
      response = await axios({
        method: "POST",
        url: `/unclaim_patient?patient_id=${member.patientId}`,
        baseURL,
        withCredentials: true
      });
      if (response.status === 200) {
        removeFromPanelRedux(member.patientId)
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: `${member.name} has been unclaimed`, type: 'success', isFor: 'members' })
      }
    } catch (err) {
      if (err.response.status == 405) {
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: `Only the claimed by you members can be unclaimed`, type: 'danger', isFor: 'members' }
        );
      } else {
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: "Can't be unclaimed at this time", type: 'danger', isFor: 'members' });
      }
    }
  };

  const addToTasksSecondaryWc = async () => {
    let url;
    let alertId;
    let patientId;
    if (alert) {
      const { patient_id: patientId, _id: alertId } = alert;
      url = `/add_to_tasks_secondary_wc?alert_id=${alertId}&patient_id=${patientId}`;
    } else {
      const { patientId } = member;
      url = `/add_to_tasks_secondary_wc?patient_id=${patientId}`
    }
    let response;
    try {
      response = await axios({
        method: "POST",
        url,
        baseURL,
        withCredentials: true
      });
      if (response.status === 200) {
        if (alert) {
          updateClaimedFlagAlerts (alertId, 1)
        } else {
          addPtToCallQRedux({ patientId, isInCallQueue: 1 });
        }
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: `Member has been added to your tasks`, type: 'success', isFor: 'members' })
      }
    } catch (err) {
      if (err.response.status == 405) {
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: `This member doesn't have a primary WC, consider adding them to your panel`, type: 'danger', isFor: 'members' });
      } else {
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: "Member can't be added to your tasks", type: 'danger', isFor: 'members' });
      }
    }
  }

  const removeFromTasksSecondaryWc = async () => {
    let response;
    try {
      response = await axios({
        method: "POST",
        url: `/remove_from_tasks_secondary_wc?patient_id=${member.patientId}`,
        baseURL,
        withCredentials: true
      });
      if (response.status === 200) {
        addPtToCallQRedux({ patientId: member.patientId, isInCallQueue: 0 });
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: `${member.name} has been removed your tasks`, type: 'success', isFor: 'members' })
      }
    } catch (err) {
      if (err.response.status == 404) {
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: `Could not find the task`, type: 'danger', isFor: 'members' });
      } else {
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: "Member can't be removed from your tasks", type: 'danger', isFor: 'members' });
      }
    }
  }

  const getDisabledStatus = () => {
    if (membersPage && (member.claimedBy == userId || member.claimedBy == '' || member.assignedBy == userId)) {
      return false;
    } else if (!membersPage && (alert.primary_nurse == userId || alert.primary_nurse == "")) {
      return false
    }
    return true;
  }

  const showAddToTaskSecondaryWc = () => {
    // disable if this member is already in this WC's panel
    // or doesn't belong to any panel -- in these cases WCs can add members to their panels
    // also, hide if this WC is already secondary WC
    if (membersPage && (member.claimedBy == userId || member.claimedBy == '' || member.secondaryWc == userId )) {
      return false;
    } else if (!membersPage && (alert.primary_nurse == userId || alert.primary_nurse == "")) {
      return false
    }
    return true;
  }

  const showRemoveFromTaskSecondaryWc = () => {
    if (membersPage && member.secondaryWc == userId) {
      return true;
    } else if (!membersPage) {
      return false
    }
    return false;
  }

  return (
    <Dropdown
      isOpen={isDropdownOpen}
      toggle={toggleContextMenu}
      className="see-more"
    >
      <DropdownToggle
        tag="span"
        data-toggle="dropdown"
        aria-expanded={isDropdownOpen}
        className="more-icon-wrapper"
      >
        {
          <FontAwesomeIcon
            icon={faEllipsisV}
            style={{ color: "rgb(122, 142, 171)", fontSize: "20px" }}
          />
        }
      </DropdownToggle>
      <DropdownMenu>
        {membersPage && <DropdownItem
          onClick={() => {
            addToPanel(member.patientId, member.name);
          }}
        >
          Add To My Panel
        </DropdownItem>}
        {!membersPage && <DropdownItem
          onClick={() => {
            let name = alert.first_name +" "+ alert.last_name;
            console.log(alert.patient_id, name)
            addToPanel(alert.patient_id, name);
          }}
        >
          Add To My Panel
        </DropdownItem>}
        {membersPage && member.claimedBy == userId && (
          <DropdownItem
            onClick={() => {
              deleteFromPanel();
            }}
          >
            Remove From My Panel
          </DropdownItem>
        )}
        <DropdownItem
          disabled={getDisabledStatus()}
          onClick={() => {
            toggleDelegationModal();
            toggleContextMenu();
          }}
        >
          Add to other WC panel
        </DropdownItem>
        {/* Make patient id in claims collection unique first */}
        {/* <DropdownItem
          onClick={() => {
            toggleContextMenu();
            toggleEscalationModal();
          }}
        >
          Escalate
        </DropdownItem> */}
        {showAddToTaskSecondaryWc() &&
         <DropdownItem
          onClick={() => {
            toggleContextMenu();
            addToTasksSecondaryWc();
          }}
        >
          Add to call Q (not my panel)
        </DropdownItem>}
        {showRemoveFromTaskSecondaryWc() &&
          <DropdownItem
            onClick={() => {
              toggleContextMenu();
              removeFromTasksSecondaryWc();
            }}
          >
            Remove from call Q (not my panel)
        </DropdownItem>}
      </DropdownMenu>
    </Dropdown>
  );
})

const mapStateToProps = state => {
  const { userData: { userId }} = state;
  return {userId};
};

const mapActionsToProps = { setShowNotification, setNotificationMessage, removeFromPanelRedux, addPtToCallQRedux, updateClaimedFlagAlerts };

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ContextMenuDropdown);
