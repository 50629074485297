import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../config";

import { Row, Col, Popover, PopoverBody } from "reactstrap";
import "./MembersRow.css"
import { fetchPatientData } from "../../redux/patient_bios/actions";
import { fetchAlertsHistory } from '../../redux/alerts_history/actions';
import { fetchChartData } from '../../redux/highchart/actions';
import { fetchPatientStats } from '../../redux/patient_recent_stats/actions'
import { setCustomStatsSelected } from "../../redux/patient_custom_stats/actions";
import { setNoteText, setSoapNoteText } from "../../redux/tracker_note_input/actions";

import { setCurrentPatientAlertsPage, addPatientAlertsPage, setCurrentMetricAlertsPage } from '../../redux/patients_metadata/actions'
import { setShowNotification, setNotificationMessage } from "../../redux/notifications/actions";
import { ReactComponent as TaskIconBlue } from "../../images/task_icon_blue.svg";
import { ReactComponent as TaskIconFaded } from "../../images/task_icon_faded.svg";
import ContextMenuDropdown from '../new_alerts/ContextMenuDropdown';
import DelegationModal from '../new_alerts/DelegationModal';
import { fetchTopicFrequencies } from "../../redux/topic_frequencies/actions";
import { addPtToCallQRedux } from "../../redux/members/actions";


export const getColor = (minutes, supportLvl) => {
  if (supportLvl === "CCM+RPM" || supportLvl === "CCM/RPM") {
    if (minutes >= 20 && minutes < 40) {
      return "rgba(246, 225, 36, 0.7)"; //yellow
    } else if (minutes >= 40) {
      return "rgba(126, 232, 122, 0.7)"; // green
    } else {
      return "rgba(249, 70, 78, 0.7)"; // red
    }
  } else  { // all the types that aren't CCM+RPM have the same goals. It can be CCM only or RPM only
    if (minutes >= 10 && minutes < 20) {
      return "rgba(246, 225, 36, 0.7)"; // yellow
    } else if (minutes >= 20) {
      return "rgba(126, 232, 122, 0.7)"; // green
    } else {
      return "rgba(249, 70, 78, 0.7)"; // red
    }
  }
}

export const getIds = (openTabsArr) => {
  let result = [];
  openTabsArr.forEach(obj => {
    result.push(obj.id)
  })
  return result
}

const getRpmBackground = (n) => {
  let rpmPct = (n / 16) * 100;
  if (rpmPct < 0) {
    return "rgb(236, 236, 236)";
  } else if (rpmPct < 40) {
    return "rgba(249, 70, 78, 0.7)";
  } else if (rpmPct > 40 && rpmPct < 60) {
    return "rgba(252, 188, 78, 0.7)"; // orange
  } else if (rpmPct > 60 && rpmPct < 80) {
    return "rgba(246, 225, 36, 0.7)"; // yellow
  } else {
    return "rgba(126, 232, 122, 0.7)"; // green
  }
}

const MembersRow = React.memo(function MembersRow(props) {
  const { member, openTabs, setShowNotification, setNotificationMessage, setEncounterNoteText, setSoapNoteText, trackerNoteInput, addPtToCallQRedux } = props;
  let patientId = member.patientId;
  const [contextMenuOpen, setContextMenuOpen] = useState({ [patientId]: false });
  const toggleContextMenu = () => setContextMenuOpen({ ...contextMenuOpen, [patientId]: !contextMenuOpen.patientId})
  const [delegationModalOpen, setDelegationModalOpen] = useState(false);
  const toggleDelegationModal = () => setDelegationModalOpen(!delegationModalOpen);
  const [escalationModalOpen, setEscalationModalOpen] = useState(false);
  const toggleEscalationModal = () => setEscalationModalOpen(!escalationModalOpen);
  const [claimedByPopoverOpen, setClaimedByPopoverOpen] = useState(false);

  const sortByMinutes = useSelector(state => state.membersData.sortByMinutes);

  const patientIds = getIds(openTabs);

  const handleMemberClick = (id, name) => {
    if (patientIds.includes(id)) {
      props.setCurrentPatientAlertsPage(id);
    } else {
      props.fetchChartData(id, 'blood_pressure');
      props.setCurrentPatientAlertsPage(id);
      props.addPatientAlertsPage(id, name);
      props.fetchPatientData(id);
      props.fetchAlertsHistory(id, "blood_pressure");
      props.setCurrentMetricAlertsPage("blood_pressure");
      props.fetchPatientStats(id);
      props.setCustomStatsSelected(false);
      if (!trackerNoteInput.hasOwnProperty(id)) {
        setEncounterNoteText({patientId: id, text: ''});
        setSoapNoteText({ patientId: id, text: '' });
      }
      props.fetchTopicFrequencies(id)
    }
  }

  const addPatientToCallQueue = async () => {
    let response;
    try {
      response = await axios({
        method: "POST",
        url: `/add_patient_to_call_queue?patient_id=${member.patientId}`,
        baseURL,
        withCredentials: true
      });
      if (response.status === 200) {
        addPtToCallQRedux({ patientId: member.patientId, isInCallQueue: 1})
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: `${member.name} has been added to your call queue`, type: 'success', isFor: 'members' })
      }
    } catch (err) {
      if (err.response.status === 405) {
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: `Member has to be added to your panel first`, type: 'danger', isFor: 'members' });
      } else if (err.response.status === 401) {
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: 'You are not authorized', type: 'danger', isFor: 'members' });
      } else {
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: "Member can't be added to your call queue", type: 'danger', isFor: 'members' });
      }
    }
  }

  const removePatientFromCallQueue = async () => {
    let response;
    try {
      response = await axios({
        method: "POST",
        url: `/remove_task?patient_id=${member.patientId}`,
        baseURL,
        withCredentials: true
      });
      if (response.status === 200) {
        addPtToCallQRedux({ patientId: member.patientId, isInCallQueue: 0 })
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: `${member.name} has been removed from your call queue`, type: 'success', isFor: 'members' })
      }
    } catch (err) {
      if (err.response.status === 405) {
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: `Member has to be added to your panel first`, type: 'danger', isFor: 'members' });
      } else if (err.response.status === 401) {
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: 'You are not authorized', type: 'danger', isFor: 'members' });
      } else {
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: "Member can't be removed from your call queue", type: 'danger', isFor: 'members' });
      }
    }
  }

  const claimedByPopover = ({ isOpen, message, target }) => {
    return (
      <Popover
        isOpen={isOpen}
        placement="left"
        target={target}
        hideArrow={true}
        popperClassName="icon-name"
      >
        <PopoverBody className="tab-name-popover">{message}</PopoverBody>
      </Popover>
    );
  };

  const getStatus = (status) => {
    switch(status) {
      case "pause":
      return "Paused";
      case "churn":
      return "Churned";
      default:
      return "Churned";
    }
  }

  const getMinutesColor = (member) => {
    if (member.status !== "active") {
      return "rgb(236, 236, 236)";
    } else if (sortByMinutes) {
      return getColor(member.minutes, member.supportLvl);
    } else {
      return getRpmBackground(member.rpmDays);
    }
  }

  const getMinutesCol = (member) => {
    if (member.status !== "active") {
      return getStatus(member.status);
    } else if (sortByMinutes) {
        return (
          <div className="mins-members-col">
            <div className="mins-members-label">Min:</div>
            <div className="mins-members-val">{member.minutes}</div>
          </div>
        )
    } else {
      return (
        <div className="rpm-members-col">
          <div className="rpm-members-label">RPM:</div>
          <div className="rpm-members-val">{member.rpmDays == -1 ? '-' : member.rpmDays}</div>
        </div>
      )
    }
  }

  return (
    <Row className="alert-row">
      <Col
        xs="2"
        className="alert-col mid"
        onClick={() => handleMemberClick(member.patientId, member.name)}
      >
        <div className="mid-top">MID</div>
        <div className="mid-bottom">{member.patientId}</div>
      </Col>
      <Col
        xs="6"
        className="alert-name-col members"
        onClick={() => handleMemberClick(member.patientId, member.name)}
      >
        {member.name}
      </Col>
      <Col
        xs="2"
        className="minutes"
        style={{
          backgroundColor: getMinutesColor(member)
        }}
        onClick={() => handleMemberClick(member.patientId, member.name)}
      >
        {getMinutesCol(member)}
      </Col>
      <Col
        id={`claim-member-${member.patientId}`}
        xs="2"
        className="alert-col claim"
        onClick={() => {
          if (!member.isInCallQueue) {
            addPatientToCallQueue();
          } else {
            removePatientFromCallQueue();
          }
        }}
        onMouseEnter={() => {
          if (member.claimedBy !== "") {
            setClaimedByPopoverOpen(true);
          }
        }}
        onMouseLeave={() => {
          setClaimedByPopoverOpen(false);
        }}
      >
        {member.isInCallQueue === 1 ? <TaskIconBlue /> : <TaskIconFaded />}
      </Col>
      {claimedByPopover({
        isOpen: claimedByPopoverOpen,
        message: `Primary WC: ${member.claimedByFullName}`,
        target: `claim-member-${member.patientId}`
      })}
      <Col xs="1" className="alert-col dots" id={`more-icon-${patientId}`}>
        <ContextMenuDropdown
          toggleDelegationModal={toggleDelegationModal}
          toggleEscalationModal={toggleEscalationModal}
          toggleContextMenu={toggleContextMenu}
          isDropdownOpen={contextMenuOpen.patientId}
          membersPage={true}
          member={member}
        />
      </Col>
      <DelegationModal
        alertId={""}
        fullName={member.name}
        patientId={patientId}
        isFor="members"
        delegationModalOpen={delegationModalOpen}
        toggleDelegationModal={toggleDelegationModal}
        escalationModalOpen={escalationModalOpen}
        toggleEscalationModal={toggleEscalationModal}
      />
    </Row>
  );
});

const mapStateToProps = state => {
  const {
    patientMetadata: {
      alertsPage: { openTabs },
    },
    trackerNoteInput,
    userData: { fullName }
  } = state;
  return {
    openTabs,
    trackerNoteInput,
    userName: fullName
  };
};


const mapActionToProps = {
  setCurrentPatientAlertsPage,
  fetchPatientData,
  fetchChartData,
  fetchAlertsHistory,
  addPatientAlertsPage,
  fetchPatientStats,
  setCustomStatsSelected,
  setShowNotification,
  setNotificationMessage,
  setEncounterNoteText: setNoteText,
  setSoapNoteText,
  fetchTopicFrequencies,
  setCurrentMetricAlertsPage,
  addPtToCallQRedux
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(MembersRow);


