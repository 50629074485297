export const SET_NOTES_TEXT = 'setNotesText';
export const SET_NOTES_TEXT_BRAIN = 'setNotesTextBrain';

export const setNotesText = text => dispatch => {
  dispatch({
    type: SET_NOTES_TEXT,
    payload: text
  })
}

export const setNotesTextBrain = text => dispatch => {
  dispatch({
    type: SET_NOTES_TEXT_BRAIN,
    payload: text
  })
}

