import {
  FETCH_PATIENT_REQUEST,
  FETCH_PATIENT_DATA_SUCCESS,
  FETCH_PATIENT_ERROR,
  UPDATE_PATIENT_DATA,
  UPDATE_PRIMARY_CONDITIONS,
  UPDATE_DOCTOR_INFO,
  UPDATE_MEMBER_STATUS,
  UPDATE_MEMBER_STICKY_NOTES,
  UPDATE_MEMBER_OTHER_NOTES,
  UPDATE_MEDICATIONS
} from './actions';

export default (
  state = {
    bios: {},
    isFetching: false,
    error: null
  }, action) => {
  switch (action.type) {
    case FETCH_PATIENT_REQUEST:
      return { ...state, error: null, isFetching: true };
    case FETCH_PATIENT_DATA_SUCCESS:
      return {
        ...state,
        bios: {
          ...state.bios,
          [action.payload.patientId]: action.payload
        },
        isFetching: false,
        error: null
      };
    case FETCH_PATIENT_ERROR:
      return { ...state, error: action.payload, isFetching: false };
    case UPDATE_PATIENT_DATA:
      let updatedBio = state.bios[action.payload.patientId];
      for (let key in action.payload.data) {
        updatedBio[key] = action.payload.data[key];
      }
      return {
        ...state,
        bios: {
          ...state.bios,
          [action.payload.patientId]: updatedBio
        },
        isFetching: false,
        error: null
      };
    case UPDATE_PRIMARY_CONDITIONS:
      return {
        ...state,
        bios: {
          ...state.bios,
          [action.payload.patientId]: {
            ...state.bios[action.payload.patientId],
            primaryConditions: action.payload.conditions
          }
        },
        isFetching: false,
        error: null
      };
    case UPDATE_DOCTOR_INFO:
      return {
        ...state,
        bios: {
          ...state.bios,
          [action.payload.patientId]: {
            ...state.bios[action.payload.patientId],
            doctorInfo: action.payload.doctorInfo
          }
        },
        isFetching: false,
        error: null
      };
    case UPDATE_MEMBER_STATUS:
      return {
        ...state,
        bios: {
          ...state.bios,
          [action.payload.patientId]: {
            ...state.bios[action.payload.patientId],
            status: action.payload.status
          }
        },
        isFetching: false,
        error: null
      };
    case UPDATE_MEMBER_STICKY_NOTES:
      return {
        ...state,
        bios: {
          ...state.bios,
          [action.payload.patientId]: {
            ...state.bios[action.payload.patientId],
            stickyNotes: action.payload.notesText
          }
        },
        isFetching: false,
        error: null
      };
    case UPDATE_MEMBER_OTHER_NOTES:
      return {
        ...state,
        bios: {
          ...state.bios,
          [action.payload.patientId]: {
            ...state.bios[action.payload.patientId],
            otherNotes: action.payload.notesText
          }
        },
        isFetching: false,
        error: null
      };
    case UPDATE_MEDICATIONS:
      return {
        ...state,
        bios: {
          ...state.bios,
          [action.payload.patientId]: {
            ...state.bios[action.payload.patientId],
            medications: action.payload.medications
          }
        },
        isFetching: false,
        error: null
      };
    default:
      return state;
  }
};
