import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import BarLoader from "react-spinners/BarLoader";
import { Table } from 'reactstrap';

import './PatientCustomStats.css'

const PatientCustomStats = React.memo(function PatientCustomStats(props) {
  const { customStats, chartsFetching, statsFetching, currentMetric } = props;
  const [noData, setNoData] = useState(false);
  const [loadSpinner, setLoadSpinner] = useState(true)
  let metricAverage;

  useEffect(()=> {
    if (chartsFetching || statsFetching || customStats === {}) {
      setLoadSpinner(true);
    } else {
      setLoadSpinner(false);
    }
    if (customStats !== {}) {
       if (!currentMetric || currentMetric === 'pulse') {
        setNoData(true)
      } else {
        setNoData(false)
      }
    }
  }, [currentMetric, customStats, statsFetching, chartsFetching])

  const spinner = () => {
    return (
      <div className="loader">
        <BarLoader
          height={4}
          width={100}
          color={"gray"}
          loading={true}
        />
      </div>
    )
  }

  if (loadSpinner || customStats === {}) {
    return <div className="stats-container-custom">{spinner()}</div>;
  } else if (noData) {
    return (
      <div className="stats-container-custom no-stats-data">
          <div>No data available</div>
      </div>
    );
  } else {
    if (customStats !== {} && customStats['metric_average']) {
      metricAverage =
        currentMetric === "glucose"
          ? customStats.metric_average
          : `${customStats.metric_average[0]}/${customStats.metric_average[1]}`;
    }
    return (
      <Table className="stats borderless">
        <tbody>
          <tr>
            <td className="headers">Vieving</td>
            <td className="headers">Baseline</td>
          </tr>
          <tr className="b-bottom">
            <td className="values b-left">
              {customStats.n_days ? customStats.n_days : "-"} days
            </td>
            <td className="values">
              {currentMetric == "blood_pressure"
                ? customStats.baseline_bp || "-"
                : customStats.baseline_bg || "-"}
            </td>
          </tr>
          <tr>
            <td colSpan="2" className="headers ">Average</td>
          </tr>
          <tr className="b-bottom">
            <td colSpan="2" className="values">
              {metricAverage ? metricAverage : "-"}
            </td>
          </tr>
          <tr>
            <td className="headers b-left">Priority Alerts</td>
            <td className="headers">Critical Alerts</td>
          </tr>
          <tr>
            <td className="values">
              {customStats.high_alerts_count
                ? customStats.high_alerts_count
                : "-"}
            </td>
            <td className="values">
              {customStats.critical_alerts_count
                ? customStats.critical_alerts_count
                : "-"}
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }
})

const mapStateToProps = state => {
  const { customStatsData, chartData: { isFetching, currentMetric } } = state;
  return {
    customStats: customStatsData.customStats,
    currentMetric,
    chartsFetching: isFetching,
    statsFetching: customStatsData.isFetching,
    // currentMetric: customStatsData.currentMetric
  };
};

export default connect(
  mapStateToProps,
  null
)(PatientCustomStats);
