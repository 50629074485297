import React from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

const ContextMenuDropdownTasks = React.memo(function ContextMenuDropdownTasks(props) {
  const { toggleContextMenu, isDropdownOpen, removeTask, deescalate, isActive } = props
  return (
    <Dropdown
      isOpen={isDropdownOpen}
      toggle={toggleContextMenu}
      className="see-more"
    >
      <DropdownToggle
        tag="span"
        data-toggle="dropdown"
        aria-expanded={isDropdownOpen}
        className="more-icon-wrapper"
      >
        {
          <FontAwesomeIcon
            icon={faEllipsisV}
            style={{ color: "rgb(122, 142, 171)", fontSize: "20px" }}
          />
        }
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          onClick={() => {
            toggleContextMenu();
            removeTask();
          }}
        >
          Remove Task
          </DropdownItem>
        <DropdownItem
          onClick={() => {
            toggleContextMenu();
            deescalate();
          }}
          disabled={!isActive}
        >
          Deescalate
          </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
})

export default ContextMenuDropdownTasks;