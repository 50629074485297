import axios from "axios";
import { baseURL } from "../../config";
import applyCaseMiddleware from "axios-case-converter";

const getCurrentUser = async () => {
  const client = applyCaseMiddleware(axios.create());
  return client({
    method: "GET",
    url: `/user`,
    baseURL,
    withCredentials: true,
  });
}

export default getCurrentUser;
