import React, { useState } from 'react';
import { Nav, NavItem } from "reactstrap";
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faUsers, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as TaskIcon } from "../images/task_icon.svg";
import { ReactComponent as MynurseLogo } from "../images/mynurse_logo.svg";
import { ReactComponent as NLogo } from "../images/n_logo.svg";

import './Sidebar.css'

const Sidebar = React.memo(function Sidebar(props) {
  const [hovered, setHovered] = useState(false);

  const [delayHandler, setDelayHandler] = useState(null)

  const handleMouseEnter = () => {
    setDelayHandler(setTimeout(() => {
      setHovered(true)
    }, 500))
  }

  const handleMouseLeave = () => {
    clearTimeout(delayHandler)
    setHovered(false)
  }

  const arrowIcon = (icon) => {
    return (
      <FontAwesomeIcon
        icon={icon}
        style={{
          marginRight: "10px",
          color: "white",
          fontSize: "22px"
        }}
      />
    );
  };
  return (
    <div
      className="navbar-container"
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
      style={{ width: hovered ? "140px" : "56px" }}
    >
      <div className="title-container">
        {hovered ? <MynurseLogo height="140px" /> : <NLogo height="20px" />}
      </div>
      <Nav className="nav-container">
        <NavItem className="global-navigation">
          <NavLink to="/" className="menu-item">
            {arrowIcon(faExclamationTriangle)}{" "}
            <span className="sidebar-tab">{hovered ? "Alerts" : " "}</span>
          </NavLink>
        </NavItem>
        <NavItem className="global-navigation">
          <NavLink to="/members" className="menu-item">
            {arrowIcon(faUsers)}{" "}
            <span className="sidebar-tab">{hovered ? "Members" : " "}</span>
          </NavLink>
        </NavItem>
        <NavItem className="global-navigation">
          <NavLink to="/tasks" className="menu-item">
            <TaskIcon height="28px" style={{ margin: "0px 10px -4px 0px" }} />
            <span className="sidebar-tab"> {hovered ? "Tasks" : " "}</span>
          </NavLink>
        </NavItem>
        {/* <NavItem className="global-navigation">
          <NavLink to="/onboarding" className="menu-item">
            {arrowIcon(faUserPlus)}{" "}
            <span className="sidebar-tab">{hovered ? "Add Member" : " "}</span>
          </NavLink>
        </NavItem> */}
      </Nav>
    </div>
  );
});

export default Sidebar;
