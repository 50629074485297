import React, { useState, createRef } from 'react';
import { connect } from 'react-redux';

import PersistentInfoTop from '../patient_screen/PersistentInfoTop';
import './DocumentScreen.css';
import Sidebar from '../Sidebar';
import Notepad from '../patient_screen/Notepad';
import OverviewLeft from './OverviewLeft';
import TrackerNoteInput from './TrackerNoteInput';
import DownloadPDF from "../sign_screen/DownloadPDF";
import PDFtoSend from "../sign_screen/PDFtoSend";
import { setShowPDF } from "../../redux/pdf_options/actions";

const DocumentScreen = React.memo(function DocumentScreen(props) {
  const { currentPatient, patientObj, setShowPDF, showPDF, pagesCount } = props;
  const [ showPatientData, setShowPatientData ] = useState(true)

  let refsArr = [];
  for (let i = 0; i < pagesCount; i++) {
    refsArr.push(createRef());
  }

  const pdfPreview = () => {
    return (
      <div className="pdf-and-controls">
        <div className="pdf-controls-wrapper">
          <DownloadPDF
            id={"pdf-summary"}
            label={"Download"}
            patientObj={patientObj}
            refs={refsArr}
          />
          <button
            className="close-pdf-button"
            onClick={() => setShowPDF(false)}
          >
            Close
          </button>
        </div>
        <PDFtoSend refs={refsArr} />
      </div>
    );
  };
  return (
    <div className="container-wrapper-pt-screen container-fluid">
      {/* <div className="container-patient-screen "> */}
      <Sidebar />
      {!currentPatient ? null : (
        <div className="persistent-info-top-and-overview">
          <div className="persistent-info-top">
            <PersistentInfoTop nextScreen="sign" />
          </div>
          <div className="row-main overview-and-document">
           { showPDF ? pdfPreview() :
            <>
              {showPatientData ? (
                <div className="overview-fullscreen-doc">
                  <OverviewLeft patientObj={patientObj} />
                </div>
              ) : null}
              <div className="fixed">
                <div className="tn-wrapper-doc">
                  <TrackerNoteInput
                    view={showPatientData ? "halfScreen" : "fullScreen"}
                    setShowPatientData={val => setShowPatientData(val)}
                    showPatientData={showPatientData}
                  />
                </div>
                <div className="notepad-column">
                  <div className="notepad-wrapper doc-screen">
                    <Notepad />
                  </div>
                </div>
              </div>
              </>
            }
          </div>
        </div>
      )}
      {/* </div> */}
    </div>
  );
});

const mapStateToProps = state => {
  const {
    patientBioData: { bios },
    patientMetadata: { patientPage: { currentPatient } },
    pdfOptions: { showPDF, pagesCount }
  } = state;
  const patientObj = bios[currentPatient];
  return {
    patientObj,
    currentPatient,
    showPDF,
    pagesCount
  };
};

 const mapActionsToProps =  { setShowPDF }

export default connect(
  mapStateToProps,
  mapActionsToProps
)(DocumentScreen);