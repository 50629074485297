import {
  FETCH_STATS_REQUEST, 
  FETCH_STATS_SUCCESS, 
  FETCH_STATS_ERROR
} from "./actions";

export default (
  state = {
    stats: {},
    isFetching: false,
    error: null
  },
  action
) => {
  switch (action.type) {
    case FETCH_STATS_REQUEST:
      return { ...state, error: null, isFetching: true };
    case FETCH_STATS_SUCCESS:
      return {
        ...state,
        stats: {
          ...state.stats,
          ...action.payload
        },
        isFetching: false
      };
    case FETCH_STATS_ERROR:
      return { ...state, error: action.payload, isFetching: false };
    default:
      return state;
  }
};
