export const SET_START_DATE = "setStartDate";
export const SET_END_DATE = "setEndDate";
export const SET_SHOW_PDF = "setShowPDF";
export const SET_SELECT_DATA_FEED = "setSelectDataFeed";
export const SET_SOAP_DATE = "setSoapDate";
export const SET_SELECT_BP = "setSelectBP";
export const SET_SELECT_BG = "setSelectBG";
export const SET_SELECT_PULSE = "setSelectPulse";
export const SET_PAGES_COUNT = "setPagesCount";


export const setStartDate = date => dispatch => {
  dispatch({
    type: SET_START_DATE,
    payload: date.getTime() + 3600000 * 0 // Manually add [] hours. The alerts time is saved as local, the time of the datepicker is UTC
  });
};

export const setEndDate = date => dispatch => {
  dispatch({
    type: SET_END_DATE,
    payload: date.getTime() + 3600000 * 0
  });
};

export const setShowPDF = bool => dispatch => {
  dispatch({
    type: SET_SHOW_PDF,
    payload: bool
  });
};

export const setSelectBP = bool => dispatch => {
  dispatch({
    type: SET_SELECT_BP,
    payload: bool
  });
};

export const setSelectBG = bool => dispatch => {
  dispatch({
    type: SET_SELECT_BG,
    payload: bool
  });
};

export const setSelectPulse = bool => dispatch => {
  dispatch({
    type: SET_SELECT_PULSE,
    payload: bool
  });
};

export const setSelectDataFeed = bool => dispatch => {
  dispatch({
    type: SET_SELECT_DATA_FEED,
    payload: bool
  });
};

export const setSoapDate = date => dispatch => {
  dispatch({
    type: SET_SOAP_DATE,
    payload: date
  });
};

export const setPagesCount = num => dispatch => {
  dispatch({
    type: SET_PAGES_COUNT,
    payload: num
  });
};
