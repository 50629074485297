import {
  FETCH_CHART_REQUEST,
  FETCH_CHART_DATA_SUCCESS,
  FETCH_CHART_ERROR,
  SET_IS_CHART_HIDDEN
} from './actions'

export default (
  state = {
    charts: {},
    currentMetric: null,
    isFetching: false,
    isChartHidden: false,
    error: null
  }, action) => {
  switch (action.type) {
    case FETCH_CHART_REQUEST:
      return { ...state, error: null, isFetching: true };
    case FETCH_CHART_DATA_SUCCESS:
      return {
        ...state,
        charts: {
          ...state.charts,
          [action.payload.id]: { ...state.charts[action.payload.id], [action.payload.metric]: action.payload.series}
        },
        currentMetric: action.payload.metric,
        isFetching: false
      };
    case SET_IS_CHART_HIDDEN:
      return { ...state, error: null, isChartHidden: action.payload };
    case FETCH_CHART_ERROR:
      return { ...state, error: action.payload, isFetching: false };
    default:
      return state;
  }
};
