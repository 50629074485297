import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { connect } from 'react-redux'

import { setNotesText, setNotesTextBrain} from '../../redux/notepad/actions'
import "./Notepad.css";

const Notepad = React.memo(function Notepad(props) {
  const { notesText, notesTextBrain, setNotesText, setNotesTextBrain } = props;
  const [activeTab, setActiveTab] = useState('notesText');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  return (
    <div className="notepad-container">
      <Nav tabs className="notes-tabs">
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "notesText" })}
            onClick={() => {
              toggle("notesText");
            }}
          >
            Scratchpad
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "brain" })}
            onClick={() => {
              toggle("brain");
            }}
          >
            Brain
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="notesText">
          <form>
             <div className="form-group">
               <textarea
                className="form-control notepad-input"
                id="notepad"
                placeholder="Enter your notes"
                value={notesText}
                onChange={(e) => setNotesText(e.target.value)} />
            </div>
          </form>
        </TabPane>
        <TabPane tabId="brain">
          <form>
            <div className="form-group">
               <textarea
                className="form-control notepad-input"
                id="notepadBrain"
                placeholder="Enter your notes - Brain"
                value={notesTextBrain}
                onChange={(e) => setNotesTextBrain(e.target.value)} />
            </div>
          </form>
        </TabPane>
      </TabContent>
    </div>
  );
});

const mapActionToProps = { setNotesText, setNotesTextBrain };

const mapStateToProps = state => {
  const { notesTextData: {notesText, notesTextBrain} } = state;
  return {
   notesText,
   notesTextBrain
  };
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(Notepad);

