import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faArrowAltCircleUp } from "@fortawesome/free-solid-svg-icons";
import { PopoverBody, Popover } from "reactstrap";
import { fetchAlertsData } from '../../redux/alerts/actions';
import { Dropdown as DropdownSearch } from "semantic-ui-react";

import './AlertsFilter.css'

const AlertsFilter = React.memo(function AlertsFilter(props) {
  const { getParams, executeScroll } = props

  const dispatch = useDispatch()
  const users = useSelector(state => state.usersData.users);
  const userType = useSelector(state => state.userData.userType);
  const clinicId = useSelector(state => state.userData.clinicId);
  const totalAlertsCount = useSelector(state => state.alertsData.totalCount);
  const filterParams = useSelector(state => state.alertsData.filterParams);
  const offset = useSelector(state => state.alertsData.offset);

  const [time, setTime] = useState(filterParams.time);
  const [status, setStatus] = useState(filterParams.status);
  const [type, setType] = useState(filterParams.type)
  const [priority, setPriority] = useState(filterParams.priority);
  const [pod, setPod] = useState(filterParams["pod"] ? filterParams.pod : null);
  const [showAllPanels, setShowAllPanels] = useState(filterParams.showAllPanels);

  const [isOpen, setIsOpen] = useState(false);

  const [checkedBoxes, setCheckedboxes] = useState({})
  const [searchTerm, setSearchTerm] = useState('')
  const filterIconColor = isOpen ? "#2195F3" : "#7A8EAB";

  const [selectedUserId, setSelectedUserId] = useState(filterParams.selectedUserId);

  useEffect(() => {
    getParams({ time, status, type, priority, pod, showAllPanels, selectedUserId, userType, clinicId })
  }, [time, status, type, priority, pod, showAllPanels, selectedUserId]);

  const handleChange = (state, setState, e) => {
      const item = e.target.value;
      const isChecked = e.target.checked
      setCheckedboxes({...checkedBoxes, [item]: isChecked})
      handleAddDeleteFromState(state, setState, isChecked, item)
  }

  const handleAddDeleteFromState = (state, setState, isActive, stateValToAddRemove) => {
    if (isActive ){
      setState([...state, stateValToAddRemove])
    } else {
      let tempState = [...state];
      let index = tempState.indexOf(stateValToAddRemove);
      if (index !== -1) {
        tempState.splice(index, 1);
        setState(tempState)
      }
    }
  }

  const clearAllCheckboxes = () => {
    setCheckedboxes({})
    setStatus([]);
    setType([]);
    setPriority([]);
    setPod();
    setShowAllPanels(false);
    setSelectedUserId('');
  }

  const timeCheckboxes = [
    {
      value: "1",
      key: "1",
      label: "1 day"
    },
    {
      value: "3",
      key: "3",
      label: "3 days"
    },
    {
      value: "7",
      key: "7",
      label: "1 week"
    },
    {
      value: "14",
      key: "14",
      label: "2 weeks"
    },
    {
      value: "30",
      key: "30",
      label: "1 month"
    },
  ];

  const statusCheckboxes = [
    {
      value: "new",
      key: "new",
      label: "New"
    },
    {
      value: "claimed",
      key: "claimed",
      label: "Claimed"
    },
    {
      value: "cleared",
      key: "cleared",
      label: "Cleared"
    }
  ];

  const typeCheckboxes = [
    {
      value: "BP",
      key: "BP",
      label: "Blood Pressure"
    },
    {
      value: "BG",
      key: "BG",
      label: "Blood Glucose"
    }
  ];

  const priorityCheckboxes = [
    {
      value: "0",
      key: "priority-0",
      label: "Critical"
    },
    {
      value: "1",
      key: "priority-1",
      label: "High"
    },
    {
      value: "2",
      key: "priority-2",
      label: "Medium"
    },
    {
      value: "3",
      key: "priority-3",
      label: "Low"
    }
  ];

  const podCheckboxes = [
    {
      value: "pod-1",
      key: "pod-1",
      label: "1"
    },
    {
      value: "pod-2",
      key: "pod-2",
      label: "2"
    },
    {
      value: "pod-3",
      key: "pod-3",
      label: "3"
    }
  ];

  const timeCheckboxesToRender = () => {
   return timeCheckboxes.map(item => {
      return (
        <div className="checkbox" key={item.key}>
          <label>
            <input
              type="radio"
              name="time"
              value={item.value}
              checked={time === item.value ? true : false}
              onChange={e => setTime(e.target.value)}
            />
            <span className="checkbox-label">{item.label}</span>
          </label>
        </div>
      );
    });
  }

  const statusCheckboxesToRender = () => {
    return statusCheckboxes.map(item => {
      return (
        <div className="checkbox" key={item.key}>
          <label>
            <input
              type="checkbox"
              name="status"
              value={item.value}
              checked={checkedBoxes[item.value]}
              onChange={e => handleChange(status, setStatus, e)}
            />
            <span className="checkbox-label">{item.label}</span>
          </label>
        </div>
      );
    });
  }

  const typeCheckboxesToRender = () => {
    return typeCheckboxes.map(item => {
      return (
        <div className="checkbox" key={item.key}>
          <label>
            <input
              type="checkbox"
              name="type"
              value={item.value}
              checked={checkedBoxes[item.value]}
              onChange={e => handleChange(type, setType, e)}
            />
            <span className="checkbox-label">{item.label}</span>
          </label>
        </div>
      );
    });
  };

  const priorityCheckboxesToRender = () => {
    return priorityCheckboxes.map(item => {
      return (
        <div className="checkbox" key={item.key}>
          <label>
            <input
              type="checkbox"
              name="priority"
              value={item.value}
              checked={checkedBoxes[item.value]}
              onChange={e => handleChange(priority, setPriority, e)}
            />
            <span className="checkbox-label">{item.label}</span>
          </label>
        </div>
      );
    });
  };

  const podCheckboxesToRender = () => {
    return podCheckboxes.map(item => {
      return (
        <div className="checkbox" key={item.key}>
          <label>
            <input
              type="radio"
              name="pod"
              value={item.value}
              checked={pod == item.value ? true : false}
              onChange={e => setPod(e.target.value)}
            />
            <span className="checkbox-label">{item.label}</span>
          </label>
        </div>
      );
    });
  };

  const showAllPanelsCheckboxToRender = () => {
      return (
        <div className="checkbox" key="show-all-panels">
          <label>
            <input
              type="checkbox"
              name="allPanels"
              value="show-all-panels"
              checked={showAllPanels}
              onChange={e => setShowAllPanels(!showAllPanels)}
            />
            <span className="checkbox-label">All Panels</span>
          </label>
        </div>
      );
  };

  const getDropdownSearch = () => {
    return (
      <DropdownSearch
        placeholder="Select a WC"
        search
        selection
        options={users}
        onChange={(e, params) => {
          setSelectedUserId(params.value);
        }}
        style={{height: "min-content"}}
      />
    )
  }

  return (
    <React.Fragment>
      <div className="alerts-header">
        <div className="header-left">
          <div className="header-title"> Alerts </div>
          <div className="alerts-count-wrapper">{totalAlertsCount}</div>
          {/* search bar */}
          <form onSubmit={(e) => { dispatch(fetchAlertsData({ time, status, type, priority, pod, search_term: searchTerm, userType, clinicId })); e.preventDefault(); setSearchTerm('') }}>
            <input
              className="form-control"
              type="text"
              placeholder="Search Member"
              aria-label="Search"
              style={{ marginLeft: '30px' }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            >
            </input>
          </form>
          {/* end search bar */}
        </div>
        {offset > 0 &&  <div className="to-top-button-wrapper" onClick={()=> executeScroll()}>
          <FontAwesomeIcon icon={faArrowAltCircleUp} style={{ fontSize: "20px", color: "7A8EAB" }}/>
        </div>}
          <div id="AlertsFilterPopover" className="filter-button-wrapper" >
            <FontAwesomeIcon icon={faFilter} style={{ fontSize: "16px", color: filterIconColor}} />
          </div>
          <Popover trigger="legacy" placement="bottom" target="AlertsFilterPopover" hideArrow={true} toggle={()=> setIsOpen(!isOpen)} isOpen={isOpen} popperClassName="wide-popover">
            <PopoverBody className="filter-popover-body">
              <div className="card card-body" >
                  <div className="criteria-header-container">
                    <div className="criteria-header-row">
                      <div className="criteria-header time-header">Time Period</div>
                      <div className="criteria-header status-header">Alert Status</div>
                      <div className="criteria-header type-header">Alert Type</div>
                      <div className="criteria-header">Alert Priority</div>
                      <div className="criteria-header">Pod</div>
                    </div>
                    <div className="criteria-header-row">

                    </div>
                  </div>
                  <div className="all-options-container">
                    <div className="criteria-container time-criteria">{timeCheckboxesToRender()}</div>
                    <div className="criteria-container">{statusCheckboxesToRender()}</div>
                    <div className="criteria-container">{typeCheckboxesToRender()}</div>
                    <div className="criteria-container">{priorityCheckboxesToRender()}</div>
                    <div className="criteria-container pod-container">{podCheckboxesToRender()}</div>
                  </div>
                  <div className="alerts-filter-footer">
                    {getDropdownSearch()}
                    <div className="af-footer-right">
                    {showAllPanelsCheckboxToRender()}
                      <div
                        className="criteria-header clear-filters-header"
                        onClick={() => {
                          clearAllCheckboxes();
                          setTime(1);
                        }}
                      >
                      Clear Filters
                    </div>
                    </div>
              </div>
                </div>
            </PopoverBody>
          </Popover>
      </div>
    </React.Fragment>
  );
});

export default AlertsFilter;
