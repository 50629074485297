import React from "react";
import { connect } from 'react-redux';

import HighChart from '../shared_components/HighChart';
import { trackerNoteSpinner } from '../spinners';
import TrackerNote from '../shared_components/TrackerNote';
import PatientRecentStats from '../shared_components/PatientRecentStats';
import PatientCustomStats from '../shared_components/PatientCustomStats';
import AlertsHistory from '../shared_components/alerts_history/AlertsHistory';

import "./BiometricsLeft.css";


const BiometricsLeft = React.memo(function BiometricsLeft(props) {
  const { currentPatient, trackerNoteData, customStatsSelected } = props;

  return (
    <>
        <div className="biomertics-wrapper left">
          <div className="highchart-component-wrapper">
            <HighChart currentPatient={currentPatient} view='docScreen' />
          </div>
          <div className="alerts-and-t-notes pt">
            <div className="alerts-history-container doc">
              <AlertsHistory currentPatient={currentPatient} view="left" />
            </div>
            <div className="t-notes-and-stats doc">
              <div className="tracker-note-container doc">
                {trackerNoteData.isFetching ? (
                  trackerNoteSpinner()
                ) : (
                    <TrackerNote currentPatient={currentPatient} view='ptScreen' />
                  )}
              </div>
              <div className="stats-component-container doc">
                {!customStatsSelected ? (
                  <PatientRecentStats currentPatient={currentPatient} view='ptScreen' />
                ) : (
                    <PatientCustomStats view='ptScreen' />
                  )}
              </div>
            </div>
          </div>
        </div>
    </>
  );
});

const mapStateToProps = state => {
  const { trackerNoteData, customStatsData: { customStatsSelected } } = state;
  return {
    trackerNoteData,
    customStatsSelected
  };
};

export default connect(
  mapStateToProps,
  null
)(BiometricsLeft);

