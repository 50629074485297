import React, { useState } from "react";
import { CardBody, Card } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

import EditContactInfo from './EditContactInfo';
import DisplayContactInfo from './DisplayContactInfo'; 

const ContactInfoCard = React.memo(function ContactInfoCard({ patientObj, setPopoverOpen }) {
  const [ editMode, setEditMode ] = useState(false)

  return (
    <Card>
      <CardBody>
        {!editMode ? <FontAwesomeIcon icon={faPencilAlt} onClick={() => setEditMode(true)} style={{ position: 'absolute', right: '18px' }} /> : null }
        {editMode ? <EditContactInfo patientObj={patientObj} setPopoverOpen={(val)=> setPopoverOpen(val)} /> : <DisplayContactInfo patientObj={patientObj} />}
      </CardBody>
    </Card>
  )

})

export default ContactInfoCard;