import { FETCH_USERS_REQUEST, FETCH_USERS_SUCCESS, FETCH_USERS_ERROR } from "./actions";

export default (
  state = {
    users: [],
    isFetching: false,
    error: null
  },
  action
) => {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
      return { ...state, error: null, isFetching: true };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        isFetching: false
      };
    case FETCH_USERS_ERROR:
      return { ...state, error: action.payload, isFetching: false };
    default:
      return state;
  }
};
