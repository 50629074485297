import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { valueTextMapCall, valueTextMapText, valueTextMapEmail, valueTextMapAdmin } from "../sign_screen/SignScreen"

export const getCommunicationType = (callLog) => {
  if (valueTextMapCall[callLog]) {
    return "call for";
  } else if (valueTextMapText[callLog]) {
    return "text for";
  } else if (valueTextMapEmail[callLog]) {
    return "email for";
  } else if (valueTextMapAdmin[callLog]) {
    return "admin work for";
  } else {
    return "call for";
  }
}

const TrackerNote = React.memo(function TrackerNote(props) {
  const { trackerNotes, currentPatient, currentTime } = props;
  const labelToColorMap = { "diet": "#03ebfc", "stressor": "#f6cef5", "subst_abuse": "#fcd703", "stress_mgmt": "#66ff33", "fall_risk": "#ffbb78", others: 'none' }

  const [ fullText, setFullText ] = useState('');
  const [ encounterObj, setEncounterObj ] = useState({encounter: []});

  useEffect(()=> {
    let currentTimePerPatient = currentTime[currentPatient];
    if (
      currentPatient &&
      currentTime &&
      currentTimePerPatient &&
      trackerNotes &&
      trackerNotes[currentPatient] &&
      trackerNotes[currentPatient][currentTimePerPatient]
    ) {
      setEncounterObj(trackerNotes[currentPatient][currentTimePerPatient]);
      setFullText(trackerNotes[currentPatient][currentTimePerPatient][fullText]);
    }
  }, [ trackerNotes, currentTime, currentPatient])

    const getParagraphs = (text) => {
      return text.split('\n').map((textPart, key) => {
        return <span key={key}><br />{textPart}</span>
      })
    }

    const hasOverlap = () => {
      if (encounterObj.encounter && encounterObj.encounter.length > 0) {
        for (let i = 1; i < encounterObj.encounter.length; i++) {
          if (encounterObj.encounter[i - 1].end >= encounterObj.encounter[i].start) {
            return true;
          }
        }
      }
      return false;
    }

    const combineTextParts = () => {
      let newLine = new RegExp(/\n/g);
      if (hasOverlap()) {
        return newLine.test(fullText) ? getParagraphs(fullText) : fullText;
      } else {
        return encounterObj.encounter.map((notePart, i) => {
          return <span key={i} style={{ backgroundColor: labelToColorMap[notePart.class] ? labelToColorMap[notePart.class] : 'none' }}>{newLine.test(notePart.text) ? getParagraphs(notePart.text) : notePart.text}{' '}</span>
        })
      }
    }

    return (
      <>
        <div className="bold" style={{ marginLeft: "5px" }}>
          {moment(currentTime[currentPatient]).format("MMM Do YYYY")}
        </div>
        <div className="in-line bold" style={{ marginLeft: "5px" }}>
          <span>
            {" "}
            {encounterObj.wcName && encounterObj.wcName}, {encounterObj.license && encounterObj.license}:{" "}
          </span>
          <span>
            {encounterObj.callLog && getCommunicationType(encounterObj.callLog)} {encounterObj.minutes && encounterObj.minutes}{" "}
            minutes
          </span>
        </div>
        <div className="tn-text" style={{ marginTop: "8px" }}>
          <div style={{ whiteSpace: "pre-line" }}>
            {encounterObj.encounter && combineTextParts()}
          </div>
        </div>
      </>
    );
})

const mapStateToProps = state => {
  const { trackerNoteData: { trackerNotes, currentTime } } = state;
  return {
    trackerNotes,
    currentTime
  };
};

export default connect(
  mapStateToProps,
  null
)(TrackerNote);
