import React from 'react';
import SidebarDashboard from "../SidebarDashboard";
import OnboardingStep1 from "./OnboardingStep1";

import "./OnboardingContainer.css"

const OnboardingContainer = () => {
  return (
    <div className="container-main">
      <SidebarDashboard />
      <div className="onb-step-wrapper">
       <OnboardingStep1 />
      </div>
    </div>
  );
}

export default OnboardingContainer;