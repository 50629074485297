import {
  FETCH_TRACKER_NOTE_REQUEST,
  FETCH_TRACKER_NOTE_SUCCESS,
  FETCH_TRACKER_NOTE_ERROR,
} from './actions'

export default (
  state = {
    trackerNotes: {},
    currentTime: {},
    isFetching: false,
    error: null
  }, action) => {
  switch (action.type) {
    case FETCH_TRACKER_NOTE_REQUEST:
      return { ...state, error: null, isFetching: true };
    case FETCH_TRACKER_NOTE_SUCCESS:
      return {
        ...state,
        currentTime: {...state.currentTime, [action.payload.id]: action.payload.time},
        trackerNotes: {
          ...state.trackerNotes,
          [action.payload.id]: { ...state.trackerNotes[action.payload.id], [action.payload.time]: action.payload.note}
        },
        isFetching: false
      };
    case FETCH_TRACKER_NOTE_ERROR:
      return { ...state, error: action.payload, isFetching: false };
    default:
      return state;
  }
};
