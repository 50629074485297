import { getTrackerNote } from "./util";

export const FETCH_TRACKER_NOTE_REQUEST = "fetchTrackerNoteRequest";
export const FETCH_TRACKER_NOTE_SUCCESS = "fetchTrackerNoteSucess";
export const FETCH_TRACKER_NOTE_ERROR = "fetchTrackerNoteError";

const fetchRequest = () => {
  return {
    type: FETCH_TRACKER_NOTE_REQUEST
  };
};

const fetchSuccess = (id, time, note) => {
  return {
    type: FETCH_TRACKER_NOTE_SUCCESS,
    payload: {id, time, note}
  };
};

const fetchError = error => {
  return {
    type: FETCH_TRACKER_NOTE_ERROR,
    payload: error
  };
};

export const fetchTrackerNote = (id, time) => async dispatch => {
  dispatch(fetchRequest());
  try {
    const trackerNoteData = await getTrackerNote(id, time);
    let note = trackerNoteData.data;
    if (typeof note === "string") {
      note = JSON.parse(note);
    }
    dispatch(fetchSuccess(id, time, note));
  } catch (e) {
    console.log(e);
    dispatch(fetchError(e));
  }
};
