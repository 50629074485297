import React from "react";
import { connect } from "react-redux";
import { Alert } from "reactstrap";

const Notification = props => {
  const { showNotification, notificationMessage } = props;

  return (
    <Alert color={notificationMessage.type} isOpen={showNotification}>
      {notificationMessage.text}
    </Alert>
  );
};

const mapStateToProps = state => {
  const {
    notificationsData: { notificationMessage, showNotification }
  } = state;
  return {
    notificationMessage,
    showNotification
  };
};

export default connect(
  mapStateToProps,
  null
)(Notification);
