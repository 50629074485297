import axios from "axios";
import { baseURL } from "../../config";
var qs = require("qs");

const getTasks = async (params) => {
  return axios({
    method: "GET",
    url: `/tasks`,
    baseURL,
    params: {
      task_type: params.task_type,
      offset: params.offset
    },
    withCredentials: true,

    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    }
  });
}

export default getTasks;
