import { getMembers } from './util'

export const FETCH_MEMBERS_REQUEST = "fetchMembersRequest";
export const FETCH_MEMBERS_SUCCESS = "fetchMembersSuccess";
export const FETCH_MEMBERS_ERROR = "fetchMembersError";
export const REMOVE_FROM_PANEL_REDUX = "removeFromPanelRedux";
export const ADD_PT_TO_CALL_Q_REDUX = "addPtToCallQRedux";
export const UPDATE_MEMBER_STATUS = "updateMemberStatus";
export const SET_SORT_BY_MINUTES = "setSortByMinutes";
export const SET_CURRENT_FILTER = "setCurrentFilter";
export const SET_RPM_FILTER = "setRpmFilter";


const fetchMembersRequest = () => {
  return {
    type: FETCH_MEMBERS_REQUEST
  };
};

const fetchMembersSuccess = membersData => {
  return {
    type: FETCH_MEMBERS_SUCCESS,
    payload: membersData
  };
};

const fetchMembersError = error => {
  return {
    type: FETCH_MEMBERS_ERROR,
    payload: error
  };
};

export const fetchMembersData = (params) => async dispatch => {
  dispatch(fetchMembersRequest());
  try {
    const membersData = await getMembers(params);
    let members = membersData.data;
    const {
      currentFilter,
      offset,
      searchTerm,
      clinicId
    } = params;
    dispatch(fetchMembersSuccess({members, currentFilter, offset, searchTerm, clinicId}));
  } catch (error) {
    console.log(error);
    dispatch(fetchMembersError(error));
  }
};

export const removeFromPanelRedux = patientId => dispatch => {
  dispatch({
    type: REMOVE_FROM_PANEL_REDUX,
    payload: { patientId }
  });
};

export const addPtToCallQRedux = ({patientId, isInCallQueue}) => dispatch => {
  dispatch({
    type: ADD_PT_TO_CALL_Q_REDUX,
    payload: { patientId, isInCallQueue }
  });
};

export const updateMemberStatus = (patientId, status) => dispatch => {
  dispatch({
    type: ADD_PT_TO_CALL_Q_REDUX,
    payload: { patientId, status }
  });
};

export const setSortByMinutes = bool => dispatch => {
  dispatch({
    type: SET_SORT_BY_MINUTES,
    payload: bool
  });
};

export const setCurrentFilter = filter => dispatch => {
  dispatch({
    type: SET_CURRENT_FILTER,
    payload: filter
  });
};

export const setRpmFilter = filterOptions => dispatch => {
  dispatch({
    type: SET_RPM_FILTER,
    payload: filterOptions
  });
};
