import getUsers from "./util";

export const FETCH_USERS_REQUEST = "fetchUsersRequest";
export const FETCH_USERS_SUCCESS = "fetchUsersSucess";
export const FETCH_USERS_ERROR = "fetchUsersError";

const fetchUsersRequest = () => {
  return {
    type: FETCH_USERS_REQUEST
  };
};

const fetchUsersSucess = wcOptions => {
  return {
    type: FETCH_USERS_SUCCESS,
    payload: wcOptions
  };
};

const fetchUsersError = error => {
  return {
    type: FETCH_USERS_ERROR,
    payload: error
  };
};

export const fetchUsers = () => async dispatch => {
  dispatch(fetchUsersRequest());
  try {
    const response = await getUsers();
    let users = response.data;
    let wcOptions = [];
    for (let user of users) {
      let text = user.firstName + " " + user.lastName;
      let userId = user.userId;
      wcOptions.push({ text, value: userId, key: userId })
    }
    dispatch(fetchUsersSucess(wcOptions));
  } catch (error) {
    console.log(error);
    dispatch(fetchUsersError(error));
  }
};
