import React, { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { baseURL } from "../../config";
import applyCaseMiddleware from 'axios-case-converter';
import { Dropdown as DropdownSearch } from "semantic-ui-react";

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { updateDoctorInfo } from '../../redux/patient_bios/actions';
import { setShowNotification, setNotificationMessage } from "../../redux/notifications/actions";


const EditDoctorModal = React.memo(function EditDoctorModal(props) {
  const {
    patientId,
    doctorModalOpen,
    toggleDoctorModal,
    doctors,
    setShowNotification,
    setNotificationMessage,
    doctorInfoOnUpdate,
    updateDoctorInfo
  } = props;
  const [selectedDoctorId, setSelectedDoctorId] = useState('');

  const handleDoctorSelect = async () => {
    const client = applyCaseMiddleware(axios.create());
    let response;
    let isFor = 'topRightPlan'
    let url = `/update_doctor?patient_id=${patientId}&doctor_id=${selectedDoctorId}`;
    try {
      response = await client({
        method: "POST",
        url,
        baseURL,
        withCredentials: true
      });
      if (response.status === 200) {
        let doctorObj = doctorInfoOnUpdate[selectedDoctorId]
        updateDoctorInfo(patientId, doctorObj)
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
          setNotificationMessage({});
        }, 2000);
        setNotificationMessage({
          text: `Doctor has been updated`,
          type: "success",
          isFor
        });
      }
    } catch (err) {
      console.log(err.response);
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
          setNotificationMessage({});
        }, 2000);
        setNotificationMessage({
          text: "Doctor could not be updated",
          type: "danger",
          isFor
        });
    }
  };

  return (
    <>
      <Modal isOpen={doctorModalOpen} toggle={toggleDoctorModal}>
        <ModalHeader toggle={toggleDoctorModal}>
         Select a doctor
        </ModalHeader>
        <ModalBody>
          <div className="delegation-dropdowns">
            <div className="delegation-dropdown">
              <div>Assign to:</div>
              <DropdownSearch
                placeholder="Select a doctor"
                search
                selection
                options={doctors}
                onChange={(e, params) => {
                  setSelectedDoctorId(params.value);
                }}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              toggleDoctorModal();
              handleDoctorSelect()
            }}
            style={{ lineHeight: "1" }}
          >
            Submit
            </Button>
          <Button
            color="secondary"
            onClick={toggleDoctorModal}
            style={{ lineHeight: "1" }}
          >
            Cancel
            </Button>
        </ModalFooter>
      </Modal>
    </>
  );
});

const mapStateToProps = state => {
  const { doctorsData: { doctors, doctorInfoOnUpdate } } = state;
  return { doctors, doctorInfoOnUpdate };
};

const mapActionToProps = {
  updateDoctorInfo,
  setShowNotification,
  setNotificationMessage
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(EditDoctorModal);
