import {
  FETCH_DOCUMENTS_REQUEST, FETCH_DOCUMENTS_SUCCESS, FETCH_DOCUMENTS_ERROR
} from "./actions";

export default (
  state = {
    documents: {},
    isFetching: false,
    error: null
  },
  action
) => {
  switch (action.type) {
    case FETCH_DOCUMENTS_REQUEST:
      return { ...state, error: null, isFetching: true };
    case FETCH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: {
          ...state.documents,
          ...action.payload
        },
        isFetching: false
      };
    case FETCH_DOCUMENTS_ERROR:
      return { ...state, error: action.payload, isFetching: false };
    default:
      return state;
  }
};
