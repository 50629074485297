import React, { useState } from "react";
import { connect } from "react-redux";
import prettyMilliseconds from 'pretty-ms';
import moment from 'moment';
import { Row, Col } from "reactstrap";
import axios from "axios";
import { baseURL } from "../../config";

import { fetchPatientData } from "../../redux/patient_bios/actions";
import { fetchAlertsHistory } from '../../redux/alerts_history/actions';
import { fetchChartData } from '../../redux/highchart/actions';
import { fetchPatientStats } from '../../redux/patient_recent_stats/actions'
import { setCustomStatsSelected } from "../../redux/patient_custom_stats/actions";
import { setNoteText, setSoapNoteText } from "../../redux/tracker_note_input/actions";
import { fetchTopicFrequencies } from "../../redux/topic_frequencies/actions";

import { setCurrentPatientAlertsPage, addPatientAlertsPage } from '../../redux/patients_metadata/actions'
import { setShowNotification, setNotificationMessage } from "../../redux/notifications/actions";
import { deleteTask, deescalateTask } from "../../redux/tasks/actions";

import './TasksRow.css';
import ContextMenuDropdownTasks from "./ContextMenuDropdownTasks";


export const getIds = (openTabsArr) => {
  let result = [];
  openTabsArr.forEach(obj => {
    result.push(obj.id)
  })
  return result
}

const TasksRow = React.memo(function TasksRow(props) {
  const { task, openTabs, setEncounterNoteText, trackerNoteInput, userId, users, fetchTopicFrequencies, setShowNotification, setNotificationMessage, deleteTask, deescalateTask } = props;
  let patientId = task.patient_id;
  const [contextMenuOpen, setContextMenuOpen] = useState({ [patientId]: false });
  const toggleContextMenu = () => setContextMenuOpen({ ...contextMenuOpen, [patientId]: !contextMenuOpen.patientId })

  const patientIds = getIds(openTabs);

  const handleTaskClick = (id, name) => {
    if (patientIds.includes(id)) {
      props.setCurrentPatientAlertsPage(id);
    } else {
      props.fetchChartData(id, 'blood_pressure');
      props.setCurrentPatientAlertsPage(id);
      props.addPatientAlertsPage(id, name);
      props.fetchPatientData(id);
      props.fetchAlertsHistory(id, "blood_pressure");
      props.fetchPatientStats(id);
      props.setCustomStatsSelected(false);
      if (!trackerNoteInput.hasOwnProperty(id)) {
        setEncounterNoteText({ patientId: id, text: "" });
        setSoapNoteText({ patientId: id, text: "" });
      }
      fetchTopicFrequencies(id)
    }
  }

  const taksTypes = {
    claimed_alert: "Call Q - Claimed Alert",
    claim_alert: "Call Q - Claimed Alert",
    claimed_patient: "Call Q - Claimed Patient",
    claim_patient: "Call Q - Claimed Patient",
    incomplete: "Incomplete Encounter"
  };

  const getUserName = (targetUser) => {
    for (let u of users) {
      if (targetUser === u.value) {
        return u.text
      }
    }
  }

// considder a better way of translation
  const translateEscalationReasons = ({reason, isEscalatedToMe}) =>  {
    reason = reason.split('');
    reason[0] = reason[0].toUpperCase();
   for (let i = 0; i < reason.length; i++) {
     if (reason[i] === '_') {
       reason[i] = ' '
       reason[i + 1] = reason[i + 1].toUpperCase();
     }
   }
   if (isEscalatedToMe) {
     return `Escalated to me by ${getUserName(task.user_id)} - ${reason.join('')}`;
   } else {
     return `Escalated to ${getUserName(task.escalated_to)} - ${reason.join('')}`;
   }
  }

// check if the issue was escalated or the user is primary claimer
// return time of escalation if the issue escalated to this user
const getDescriptionAndTime = () => {
  let taskDescription;
  let taskTime;
  // the user will see this task if it was escalated by or to them
  if (task['escalated_to'] && task['escalated_to'] == userId ) {
    taskDescription = translateEscalationReasons({ reason: task.escalation_reason, isEscalatedToMe: true })
    taskTime = prettyMilliseconds(
      Date.now() - moment(task.escalation_date_time),
          { compact: true }
        )
  } else if (task['escalated_to'] && task['user_id'] == userId) {
      taskDescription = translateEscalationReasons({ reason: task.escalation_reason, isEscalatedToMe: false})
      taskTime = prettyMilliseconds(
        Date.now() - moment(task.escalation_date_time),
        { compact: true }
      )
  } else {
    taskDescription = taksTypes[task.task_type]
    taskTime = task['task_date_time'] ? prettyMilliseconds(
      Date.now() - moment(task.task_date_time),
      { compact: true }
    ) : '-'
  }
  return { taskDescription, taskTime };
}

  const removeTask = async () => {
    let response;
    let url = `/remove_task?patient_id=${patientId}`
    if (task.task_type == "claimed_alert") {
      url += `&alert_id=${task.alert_id}`;
    }
    try {
      response = await axios({
        method: "POST",
        url,
        baseURL,
        withCredentials: true
      });
      if (response.status === 200) {
        deleteTask(patientId)
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: `${task.patient_name} has been removed from your call queue`, type: 'success', isFor: 'tasks' });
      }
    } catch (err) {
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
      setNotificationMessage({ text: "The task can't be removed at this time", type: 'danger', isFor: 'tasks' });
    }
  };


  const deescalate = async () => {
    let actor;
    if (task['escalated_to'] && task['escalated_to'] == userId) {
      actor = "assignee"
    } else if (task['escalated_to'] && task['user_id'] == userId) {
      actor = "assignor"
    }
    let response;
    try {
      response = await axios({
        method: "POST",
        url: `/deescalate?patient_id=${patientId}&actor=${actor}`,
        baseURL,
        withCredentials: true
      });
      if (response.status === 200) {
        deescalateTask(patientId)
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
        setNotificationMessage({ text: `${task.patient_name} has been deescalated`, type: 'success', isFor: 'tasks' });
      }
    } catch (err) {
        setShowNotification(true);
        setTimeout(() => { setShowNotification(false); setNotificationMessage({}) }, 2000)
      setNotificationMessage({ text: "Can't be deescalated at this time", type: 'danger', isFor: 'tasks' });
    }
  };

  return (
    <Row className="alert-row">
      <Col
        xs="2"
        className="alert-col mid"
        onClick={() => handleTaskClick(task.patient_id, task.patient_name)}
      >
        <div className="mid-top">MID</div>
        <div className="mid-bottom">{task.patient_id}</div>
      </Col>
      <Col
        xs="6"
        className="alert-name-col tasks"
        onClick={() => handleTaskClick(task.patient_id, task.patient_name)}
      >
        {task.patient_name}
      </Col>
      <Col
        xs="4"
        className="task-type-col"
        onClick={() => handleTaskClick(task.patient_id, task.patient_name)}
      >
        {getDescriptionAndTime().taskDescription}
      </Col>
      <Col
        xs="1"
        className="alert-col alert-days"
        onClick={() => handleTaskClick(task.patient_id, task.patient_name)}
      >
        {getDescriptionAndTime().taskTime}
      </Col>
      <Col xs="1" className="task-col dots" id={`task-icon-${task.patient_id}`}>
        <ContextMenuDropdownTasks
          toggleContextMenu={toggleContextMenu}
          isDropdownOpen={contextMenuOpen.patientId}
          removeTask={removeTask}
          deescalate={deescalate}
          isActive={task["escalated_to"] ? true : false}
        />
      </Col>
    </Row>
  );
});

const mapStateToProps = state => {
  const { patientMetadata: { alertsPage: { openTabs } }, trackerNoteInput, userData: { userId }, usersData:  { users } } = state;
  return {
    openTabs,
    trackerNoteInput,
    userId,
    users
  };
};


const mapActionToProps = {
  setCurrentPatientAlertsPage,
  fetchPatientData,
  fetchChartData,
  fetchAlertsHistory,
  addPatientAlertsPage,
  fetchPatientStats,
  setCustomStatsSelected,
  setEncounterNoteText: setNoteText,
  setSoapNoteText,
  fetchTopicFrequencies,
  setShowNotification,
  setNotificationMessage,
  deleteTask,
  deescalateTask
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(TasksRow);


