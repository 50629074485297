import { getPatientData } from './util'

export const FETCH_PATIENT_REQUEST = "fetchPatientRequest";
export const FETCH_PATIENT_DATA_SUCCESS = "fetchPatientDataSuccess";
export const FETCH_PATIENT_ERROR = "fetchPatientError";
export const UPDATE_PATIENT_DATA = "updatePatientData";
export const UPDATE_DOCTOR_INFO = "updateDoctorInfo";
export const UPDATE_PRIMARY_CONDITIONS = "updatePrimaryConditions";
export const UPDATE_MEMBER_STATUS = "updateMemberStatus";
export const UPDATE_MEMBER_STICKY_NOTES = "updateMemberStickyNotes";
export const UPDATE_MEMBER_OTHER_NOTES = "updateMemberOtherNotes";
export const UPDATE_MEDICATIONS = "updateMedications";

const fetchRequest = () => {
  return {
    type: FETCH_PATIENT_REQUEST
  };
};

const fetchSuccess = patientData => {
  return {
    type: FETCH_PATIENT_DATA_SUCCESS,
    payload: patientData
  };
};

const fetchError = error => {
  return {
    type: FETCH_PATIENT_ERROR,
    payload: error
  };
};

export const fetchPatientData = (id) => async dispatch => {
  dispatch(fetchRequest());
  try {
    const patientData = await getPatientData(id);
    let patient = patientData.data;
    if (typeof patient === "string") {
      patient = JSON.parse(patient);
    }
    dispatch(fetchSuccess(patient));
  } catch (error) {
    console.log(error);
    dispatch(fetchError(error));
  }
};

export const updatePatientData = (patientId, data) => dispatch => {
  dispatch({
    type: UPDATE_PATIENT_DATA,
    payload: { patientId, data }
  });
};

export const updatePrimaryConditions = ({patientId, conditions}) => dispatch => {
  dispatch({
    type: UPDATE_PRIMARY_CONDITIONS,
    payload: { patientId, conditions }
  });
};

export const updateDoctorInfo = (patientId, doctorInfo) => dispatch => {
  dispatch({
    type: UPDATE_DOCTOR_INFO,
    payload: { patientId, doctorInfo }
  });
};

export const updateMemberStatus = (patientId, status) => dispatch => {
  dispatch({
    type: UPDATE_MEMBER_STATUS,
    payload: { patientId, status }
  });
};

export const updateMemberStickyNotes = (patientId, notesText) => dispatch => {
  dispatch({
    type: UPDATE_MEMBER_STICKY_NOTES,
    payload: { patientId, notesText }
  });
};
export const updateMemberOtherNotes = (patientId, notesText) => dispatch => {
  dispatch({
    type: UPDATE_MEMBER_OTHER_NOTES,
    payload: { patientId, notesText }
  });
};

export const updateMedications = ({ patientId, medications }) => dispatch => {
  dispatch({
    type: UPDATE_MEDICATIONS,
    payload: { patientId, medications }
  });
};
