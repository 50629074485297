import axios from "axios";
import { baseURL } from "../../config";
import applyCaseMiddleware from "axios-case-converter";

export const getTrackerNote = async (id, time) => {
  const client = applyCaseMiddleware(axios.create())
  return client({
    method: "GET",
    url: `/encounter_point_details?id=${id}&time=${time}`,
    baseURL
  });
}
