import { getEncounters } from "./util";

export const FETCH_ENCOUNTERS_REQUEST = "fetchEncountersRequest";
export const FETCH_ENCOUNTERS_SUCCESS = "fetchEncountersSuccess";
export const FETCH_ENCOUNTERS_ERROR = "fetchEncountersError";
export const UPDATE_ENCOUNTER_NOTES = "updateEncounterNotes";

const fetchEncountersRequest = () => {
  return {
    type: FETCH_ENCOUNTERS_REQUEST
  };
};

const fetchEncountersSuccess = encounters => {
  return {
    type: FETCH_ENCOUNTERS_SUCCESS,
    payload: encounters
  };
};

const fetchEncountersError = error => {
  return {
    type: FETCH_ENCOUNTERS_ERROR,
    payload: error
  };
};

export const fetchEncounters = (id) => async dispatch => {
  dispatch(fetchEncountersRequest());
  try {
    const encounters = await getEncounters(id);
    let data = encounters.data;
    if (typeof data === "string") {
      data = JSON.parse(data);
    }
    dispatch(fetchEncountersSuccess({ [id]: data }));
  } catch (error) {
    console.log(error);
    dispatch(fetchEncountersError(error));
  }
};

export const updateEncounterNotes = params => dispatch => {
  dispatch({
    type: UPDATE_ENCOUNTER_NOTES,
    payload: params
  });
};

