import axios from "axios";
import { baseURL } from "../../config";
import applyCaseMiddleware from 'axios-case-converter'

const source = axios.CancelToken.source();

export  const postEncounterNote = async ({text, minutes, callStatus, patientId, isComplete, isTnUpdate, tnToUpdate, careType, completeTask}) => {
  const client = applyCaseMiddleware(axios.create());
  return client({
    method: "POST",
    url: `/encounter_note`,
    baseURL,
    params: {
      text,
      minutes,
      callLog: callStatus,
      patientId,
      isComplete,
      isTnUpdate,
      tnToUpdate,
      careType,
      completeTask
    },
    withCredentials: true,
    cancelToken: source.token
  });
}

export const postSoapNote = async ({ text, patientId, isComplete, isUpdate, soapId, completeTask }) => {
  console.log(text)
  const client = applyCaseMiddleware(axios.create());
  return client({
    method: "POST",
    url: `/soap_note`,
    baseURL,
    params: {
      text,
      patientId,
      isComplete,
      isUpdate,
      soapId,
      completeTask
    },
    withCredentials: true,
    cancelToken: source.token
  });
}


