import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import axios from "axios";
import applyCaseMiddleware from "axios-case-converter";
import { baseURL } from "../../config";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap";

import { setShowNotification, setNotificationMessage } from "../../redux/notifications/actions";
import { fetchDocuments }  from "../../redux/documents/actions";

const UploadFileModal = React.memo(function UploadFileModal({isOpen, toggleModal, patientId}) {
  const [fileToUpload, setFileToUpload] = useState(null);
  const [fileDescription, setFileDescription] = useState("");

  const dispatch = useDispatch();

  const onFileChange = e => {
    setFileToUpload(e.target.files[0]);
  };

  const onFileUpload = async () => {
    const formData = new FormData();

    formData.append(
      "memberFile",
      fileToUpload,
      fileToUpload.name
    );

    // const client = applyCaseMiddleware(axios.create());
    let response;
    try {
      response = await axios({
        method: "POST",
        url: `/upload_file?file_name=${fileToUpload.name}`,
        baseURL,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" }
      })
      if (response.status === 200) {
        try{
          response = await axios({
            method: "POST",
            url: `/upload_file_to_s3?`,
            baseURL,
            params: {
              file_name: fileToUpload.name,
              description: fileDescription,
              patientId
            }
          });
          if (response.status === 200) {
            console.log('success')
            dispatch(setShowNotification(true));
            dispatch(fetchDocuments(patientId))
            setTimeout(() => { dispatch(setShowNotification(false)); dispatch(setNotificationMessage({})) }, 2000)
            dispatch(setNotificationMessage({ text: 'Your record has been saved', type: 'success', isFor: 'topRightPlan' }));
          }
        } catch(err) {
          console.log(err)
          dispatch(setShowNotification(true));
          setTimeout(() => { dispatch(setShowNotification(false)); dispatch(setNotificationMessage({})) }, 2000)
          dispatch(setNotificationMessage({ text: 'Can\'t be uploaded at this time', type: 'danger', isFor: 'topRightPlan' }));
        }
      }
    } catch (err) {
      console.log(err)
      dispatch(setShowNotification(true));
      setTimeout(() => { dispatch(setShowNotification(false)); dispatch(setNotificationMessage({})) }, 2000)
      dispatch(setNotificationMessage({ text: 'Can\'t be uploaded at this time', type: 'danger', isFor: 'topRightPlan' }));
    }

  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} className="upload-file-modal">
      <ModalHeader toggle={toggleModal}>Upload File</ModalHeader>
      <ModalBody>
        <div>
          <div>
            <Input type="file" onChange={(e)=> onFileChange(e)} />
          </div>
          <div id="file-description-row">
            <span id="description-label">Description:</span>
            <Input type="text" onChange={(e) => setFileDescription(e.target.value)} value={fileDescription}/>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => { onFileUpload(); toggleModal();}}>Submit</Button>{' '}
        <Button color="secondary" onClick={toggleModal}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )

})

export default UploadFileModal;