import React, { useState } from 'react'
import { connect } from "react-redux";
import { alertsListSpinner } from '../spinners'
import InfiniteScroll from "react-infinite-scroller";
import { fetchMembersData, setSortByMinutes as setSortByMinutesRedux } from "../../redux/members/actions"

import MembersRow from './MembersRow';
import MembersFilter from './MembersFilter';

const MembersContainer = React.memo(function MembersContainer(props) {
  const MEMBERS_DISPLAYED = 30;
  const { members, totalMembersCount, isFetching, fetchMembersData, userType, clinicId, setSortByMinutesRedux } = props;
  const [ sortByMinutes, setSortByMinutes ] = useState(true)

  // const totalPagesCount = Math.ceil(totalMembersCount / MEMBERS_DISPLAYED);

  // const fetchSortedMembers = (sortByMinutes) => {
  //     fetchMembersData({
  //       clinicId,
  //       userType,
  //       showAllPanels: 1,
  //       currentFilter: "clinic_id",
  //       sortByMinutes
  //     });
  //   setSortByMinutesRedux(sortByMinutes)
  // }

  // const getSortOptions = () => {
  //   return (
  //     <div>
  //       <span>Sort By:</span>
  //       <div>
  //         <input
  //           type="radio"
  //           value={sortByMinutes}
  //           name="sortOptions"
  //           checked={sortByMinutes}
  //           onChange={() => fetchSortedMembers(true)}
  //         />{" "}
  //         Minutes
  //         <input
  //           type="radio"
  //           value={!sortByMinutes}
  //           name="sortOptions"
  //           checked={!sortByMinutes}
  //           onChange={() => fetchSortedMembers(false)}
  //         />{" "}
  //         Rpm
  //       </div>
  //     </div>
  //   );
  // }

  if (!members) {
    return (<>An Error has occured</>)
  } else {
    return (
      <React.Fragment>
        <div className="alerts-and-footer">
          <div className="alerts-container">
              <MembersFilter />
            {isFetching ? (
              <div className="alerlts-list-spinner-wrapper">
                {alertsListSpinner()}
              </div>
            ) : (
              <div style={{ height: "90vh", overflow: "auto" }}>
                {members.map((member, i) => (
                  <MembersRow key={i} onClick={() => { }} member={member} />
                ))}
                {/* <InfiniteScroll
                  pageStart={0}
                  initialLoad={false}
                  loadMore={() => {
                    if (currentFilter) {
                      setPage(page => ({
                        ...page,
                        [currentFilter]: page[currentFilter] + 1
                      }));
                    }
                  }}
                  hasMore={page[currentFilter] < totalPagesCount}
                  loader={
                    isFetching ? (
                      <div className="loader" key={0}>
                        Loading ...
                      </div>
                    ) : null
                  }
                  useWindow={false}
                >
                  {members.map((member, i) => (
                    <MembersRow key={i} onClick={() => {}} member={member} />
                  ))}
                </InfiniteScroll> */}
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
});

const mapActionsToProps = { fetchMembersData, setSortByMinutesRedux }

const mapStateToProps = state => {
  const {
    membersData: { members, totalCount, isFetching, error },
    notificationsData: { showNotification, notificationMessage },
    userData: {userType, clinicId}
  } = state;
  return {
    members,
    showNotification,
    notificationMessage,
    totalMembersCount: totalCount,
    userType,
    clinicId,
    isFetching,
    error
  };
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(MembersContainer);

