import React, { useEffect, useState, useRef, createRef } from 'react'
import { connect } from "react-redux";
import { alertsListSpinner } from "../spinners";
import InfiniteScroll from "react-infinite-scroller";

import AlertsRow from './AlertsRow';
import AlertsFilter from './AlertsFilter';
import { fetchAlertsData } from "../../redux/alerts/actions";
import './AlertsContainer.css'

const AlertsContainer = React.memo(function AlertsContainer(props) {
  const topAlertRef = useRef();

  const ALERTS_DISPLAYED = 30;
  const {alerts, totalAlertsCount, isFetching, filterParamsRedux, offsetRedux, userType, clinicId } = props;
  const [currentPage, setCurrentPage] = useState(1)
  const [filterParams, setFilterParams] = useState(()=> {return {}});

  const totalPagesCount = Math.ceil(totalAlertsCount/ALERTS_DISPLAYED)

  const executeScroll = () => topAlertRef && topAlertRef.current && topAlertRef.current.scrollIntoView();


  const getFilterParams = (params) => {
    let isEqual = compareTwoObjects(params, filterParamsRedux);
    if (!isEqual) {
      setFilterParams(params)
    }
  }

  let compareTwoObjects = (obj1, obj2) => {
    for (let key in obj1) {
      if (obj1[key] && obj1[key] != undefined) {
        if (!obj2[key] || obj1[key] != obj2[key]) {
          return false;
        }
      }
    }
    return true;
  }

  useEffect(() => {
    let isEqual = compareTwoObjects(filterParams, filterParamsRedux);
    const isNewPage = () => {
      if ((offsetRedux/ALERTS_DISPLAYED)+1 < currentPage) {
        return true
      }
      return false
    }

    if (filterParams['time'] && !isEqual) {
      setCurrentPage(1);
    }

    if (filterParams['time'] && !isEqual || isNewPage()) {
        // set offset to 0 if the page is just loaded or the new filters are applied
        let offset = (currentPage === 1 || !isEqual) ? 0 : (currentPage-1)*ALERTS_DISPLAYED
          props.fetchAlertsData({ ...filterParams, offset, userType, clinicId });
    }
  }, [filterParams, currentPage]);

  let refsArr = [];
  // created refs for each of the alert in the list for uniqueness
  // we only need the top alert ref to go to top
  if (alerts.length > 0) {
    for (let i = 1; i < alerts.length; i++) {
      refsArr.push(createRef())
    }
  }


  if (!alerts) {
    return (<>An Error has occured</>)
  } else {
    return (
      <React.Fragment>
        <div className="alerts-and-footer">
          <div className="alerts-container">
            <AlertsFilter getParams={getFilterParams} executeScroll={executeScroll} />
            {currentPage === 1 && isFetching ? (
              <div className="alerlts-list-spinner-wrapper">
                {alertsListSpinner()}
              </div>
            ) : (
              <div style={{ height: "90vh", overflow: "auto" }}>
                <InfiniteScroll
                  pageStart={0}
                  initialLoad={false}
                  loadMore={() => {
                    setCurrentPage(currentPage => currentPage + 1);
                  }}
                  hasMore={currentPage < totalPagesCount && !isFetching}
                  loader={
                    isFetching || currentPage < totalPagesCount ? (
                      <div className="loader" key={0}>
                        Loading ...
                      </div>
                    ) : null
                  }
                  useWindow={false}
                >
                  {alerts.map((alert, i) => <AlertsRow key={i} onClick={() => {}} alert={alert} refToPass={i === 0 ? topAlertRef : refsArr[i]}  />)}
                </InfiniteScroll>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
});

const mapActionsToProps = { fetchAlertsData }

const mapStateToProps = state => {
  const { alertsData: {  alerts, totalCount, isFetching, filterParams, offset }, notificationsData: { showNotification, notificationMessage }, userData: {userType, clinicId} } = state;
  return {
    alerts,
    filterParamsRedux: filterParams,
    offsetRedux: offset,
    showNotification,
    notificationMessage,
    totalAlertsCount: totalCount,
    userType,
    clinicId,
    isFetching
  }
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(AlertsContainer);

