import axios from "axios";
import { baseURL } from "../../config";
import applyCaseMiddleware from "axios-case-converter";

export const getSoapNotes = async id => {
  let client = applyCaseMiddleware(axios.create());
  return client({
    method: "GET",
    url: `/soap_notes?id=${id}`,
    baseURL
  });
}
