import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import MedicationsEditModal from "./MedicationsEditModal"



const MedicationsComponent = React.memo(function MedicationsComponent({medsEditMode, setMedsEditMode, patientObj}) {
  const { medications, patientId } = patientObj;
  const toggleMedsEditMode = () => setMedsEditMode(!medsEditMode);
  const convertListToStr = (arr) => {
    if (!arr) {
      return null;
    }
    let result = '';
    for (let i = 0; i < arr.length; i++) {
      result += (i < arr.length - 1) ? arr[i] + ", " : arr[i]
    }
    return result;
  }
  return (
    <div className="primary-cond-container no-margin-x">
      <div className="cond-header-and-icon">
        <div className="conditions-header">Medications</div>
        <div className="pencil-icon-wrapper">
          <FontAwesomeIcon
            icon={faPencilAlt}
            onClick={() => setMedsEditMode(true)}
          />
        </div>
      </div>
      <div className="conditions-list">
        {/* not a part of the default display */}
        <MedicationsEditModal
          isModalOpen={medsEditMode}
          medsArr={medications ? medications : []}
          patientId={patientId}
          toggleMedsEditMode={toggleMedsEditMode}
        />
        {/* end doctor modal */}
        {convertListToStr(patientObj.medications)}
      </div>
    </div>
  );
});

export default MedicationsComponent;
